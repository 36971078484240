.page-not-found {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .error-header {
    display: block;
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
  }
  .error-header .container {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
  }
  .error-header .container .logo {
    flex: 0 0 150px;
    max-width: 150px;
  }
  .error-header .container .nav {
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
  }
  .error-header .container .nav ul {
    display: flex;
  }
  .error-header .container .nav ul li {
    padding: 10px;
    display: inline-flex;
    align-items: center;
  }
  .btn.btn-gray {
    background-color: #6e6e6e;
  }
  .error-header .link {
    display: flex;
    align-items: center;
    color: #6e6e6e;
  }
  .error-header .link img {
    margin-left: 5px;
  }
  .error-block {
    display: flex;
    flex-direction: column;
    flex: 0 0 600px;
    max-width: 100%;
    align-items: center;
    padding: 0 40px 40px;
  }
  /* .error-block figure {
  } */
  .error-block figure img {
    width: 100%;
  }
  .error-block .btn-block {
    padding: 10px 20px;
    margin: 0;
  }
  