.search-row{
    display:flex;
    width:100%;
    background-color: #fff;
    border:1px solid #A7D4D8;
    border-radius: 8px;
}
.search-row .search-input{
    flex:1;
}
.search-row .search-select{
    flex-basis:auto;
    min-width: 18px;
}
.search-row .search-select img{
    margin-top: 20px;
}
.search-row .search-btn{
    flex-basis: 78px;    
}

.search-row .input-skill {
    border-right: 0px;
    border: none;
}


.search-box-block {
    background-color: #f15d3b;
    width: 72px;
    height: 42px;
    position: relative;  
    border-radius: 8px;
    font-size: 28px;
    color: #fff;
    text-align: center;
    line-height: 42px;
    display: block;
    transition: all 0.3s ease-in-out;
    margin:3px;
  }
  .search-box-block:hover {
    transition: all 0.3s ease-in-out;
    background-color: #3ba0aa;
  }
.search-section input,
.search-section select {
  box-sizing: border-box;
  height: 48px;
  /* font-family: "Poppins-Light"; */
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
  color: #9f9f9f;
  border:none;
  border-radius: 0;
  -webkit-box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 0.53);
  -moz-box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 0.53);
  box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 0.53);
}
.search-section select{
    border-left:1px solid #A7D4D8;
}
.search-section input{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

@media(max-width:768px){
    .search-row{
        flex-wrap: wrap;
        background-color: transparent;
        border:none;
    }
    .search-row .search-input{
        margin-bottom: 10px;
        flex-basis: 100%;
    }
    .search-row .search-select{
        flex:1;
        min-width: auto;
        margin-right:10px;
    }
    .search-section select{
        border:none;
    }
    .search-row .search-btn{
        flex-basis: 50px;
    }
    .search-row .search-btn .search-box-block{
        margin:0;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

