.client-tab-mb5 {
    margin-bottom: 5px !important   ;
}

.client-skill-pt0 {
    padding-top: 0px;
}

.pre-next-box {
    display:flex;
    flex-grow: 1;
    padding: 12px 10px 12px 10px; 
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}