.loading-div{
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #d0e1e2; */
    height: 100vh;
}

.loading-box{
    /* height: 500px; */
    margin-top: -20%;
    /* top: 50px; */
    width: 30%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unsub-noti{
    text-align: center;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.3);
    padding: 30px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.unsub-noti h1{
    font-size: 19px;
    color: #222222;
    margin-bottom: 10px;
}
.unsub-noti p{
    font-size: 15px;
    color: #222222;
    font-weight: 600;
}

@media (max-width: 768px){
    .unsub-noti h1{
        font-size: 18px;
        margin-bottom: 5px;
    }
    .unsub-noti p{
        font-size: 14px;
    }
    .loading-box{
        width: 90%;
    }
}