.accordion{
    background-color:#F0F5F6;
    border:1px solid #D3DCE5;    
    display:block;
    width:100%;
}
.accordion-item{
    border-bottom: 1px solid #D3DCE5;
    font-size:13px;
}
.accordion-item:last-child{
    border-bottom: none;
}
.accordion-title{
    padding:10px 20px;
    display:flex;
    justify-content: space-between;
    position: relative;
    /* font-family: 'Poppins-Medium',sans-serif; */
    font-weight: 500;
    font-size:16px;
}
.accordion-title .acc-icon{
    position: absolute;
    right:20px;
    top:50%;
    transform: translateY(-50%);
    height:24px;
    width:24px;
    font-size: 20px;
    color:var(--theme-color);
    cursor: pointer;
}
.accordion-content{
    padding:20px;
}

.acc-cursor {
    cursor: pointer;
}