.main-div-developer {
  padding: 20px;
}

.filter {
  background-color: white;
  outline: none;
  border: none;
  color: #3ba0aa;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  font-size: 16px;
  float: right;
}

.filter:hover {
  cursor: pointer;
}

.input-check {
  width: 15px !important;
  height: 15px !important;
  visibility: visible !important;
}

.editbtnView {
  background-color: #3ba0aa;
  border: none;
  outline: none;
  border-radius: 50px;
  padding: 5px 8px;
}

.editbtnView:hover {
  cursor: pointer;
}
.modal-header-dev-man {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-color: rgba(245, 250, 251, 0.8);
  position: relative;
}
.modal-title-dev-man {
  margin-bottom: 0;
  line-height: 1.5;
}

.pending-left{
  margin-bottom: 0;
  line-height: 1.5;
  padding-left:290px;
}

.prof-stat{
  text-transform: capitalize;
}

.pagination-span{
  margin-top: 30px;
}

.dev-page-table{
  height: 60vh;
  overflow-y: scroll; 
  overflow-x: hidden;
}

.tm15 {
  margin-top: 15px;
}

.flex-start-button {
  position: absolute !important;
  left: 14px;
  cursor: pointer !important;
  bottom: -5px;
}

.flex-right-box {
  position: absolute;
  right: 22px;
  display: flex;
}