/* .main-div{
    padding: 20px;
} */

.custom-select{
    min-height: 0px;
}
.select-group::selection{
    min-height: 100px;

}

.skills-management{
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 3px -2px, rgb(145 158 171 / 14%) 0px 3px 4px 0px, rgb(145 158 171 / 12%) 0px 1px 8px 0px;
    background-image: none;
    padding: 20px;
    /* margin-top: 20px; */
    border-radius: 5px;
    width: 100%;
    border-top-left-radius: 0 ;
    border-top-right-radius: 0 ;
}

.btnArea{
    text-align: right;
}



.editButton{
    outline: none;
    border: none;
    border-radius: 30px;
    background-color: #3BA0AA;
    padding: 5px 7px;
    text-align: center;
    transition: 0.2s background-color ease-out;
}

.editButton img{
    margin: auto;
    text-align: center;
}

.editButton:hover{
    cursor: pointer;
    background-color: #3c888f;
}


.skillname{
    background-color: #fff;
    border: none;
    border-radius: 34px;
    display: flex;
    /* font-family: Poppins-Regular; */
    font-weight: 400;
    font-size: 12px;
    outline: none;
    padding: 0px 10px 0px 0px;
    border: 1px solid #f1f1f1;
    line-height: 23px;
}

.skillname span{
    padding: 3px 0px;
}

.skillname img{
    margin-right: 8px;
    border: thin solid #D2E2F2;
    border-radius: 30px;
    padding: 3px;
}

.label{
    margin-top: -15px;
}



input[type="checkbox"] {
    width: 0;
    height: 0;
    visibility: hidden;
  }
  .label {
    width: 50px;
    height: 22px;
    display:block;
    background-color: #D2E2F2;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 0 0 20px #477a8550;
  }
  .label::after {
    content: "";
    width: 18px;
    height: 18px;
    background-color: #e8f5f7;
    position: absolute;
    border-radius: 70px;
    top: 2px;
    left: 2px;
    transition: 0.5s;
  }
  
  input:checked + .label:after {
    left: calc(100% - 6px);
    transform: translateX(-80%);
  }
  
  input:checked + .label {
    background-color: #3BA0AA;
  }
  
  /* .label:active:after {
    width: 160px;
  } */

  .row-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .file-icon {
    border: 1px solid #3BA0AA;
    border-radius: 2px;
    font-size: 14px;
    padding: 9px 40px;
    color: #3BA0AA;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    min-width: 250px;
    margin-top: 11px;
  }
  
  .icon-option {
    width:'200px';
    display:'flex';
  }
  .topic-list{
    display: flex;
    margin-top: 5px;
  }
  

  .topic-item{
    display: flex;
    border: thin solid #e2e2e2 ;
    font-size: 13px;
    padding: 2px 6px;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    border-radius: 14px;
    margin-right: 5px;
  }

  .cross{
    background-color: black;
  }
  

  .job-drop-capitalize {
      text-transform: uppercase;
  }

  .user-selection-box {
    width: 300px;
  }

  .shortlisted-block.applicants-modal:hover {
    box-shadow: none;
    background-color: pink;
  }