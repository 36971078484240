.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 1.75rem auto;
  pointer-events: none;
  max-width: 1200px;
  width: 95%;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
.modal.show {
  /* padding-right: 17px; */
  display: block;
}
.modal.show .modal-dialog {
  transform: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  outline: 0;
  margin: auto;
  margin-top: 8%;
  /* max-width: 600px; */
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-color: rgba(245, 250, 251, 0.8);
  position: relative;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.btn-close {
  color: #000;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
.btn-close-box {
  box-sizing: content-box;
  padding: 0.25em 0.25em;
  position: absolute;
  right: 15px;
  top: 58%;
  transform: translateY(-50%);
  cursor: pointer;
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
button.btn-close svg {
  width: 13px;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0px 30px;
  padding-top: 20px;
  padding-bottom: 30px;
}
.modal-body h3 {
  /* font-size: 18px; */
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: black;
  font-weight: 500;
  text-transform: capitalize;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 30px;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  padding-bottom: 20px;
}
.modal-footer > * {
  margin: 0.25rem;
}

.bottom-button-modal {
  text-align: center;
}

.skill-set {
  background: #fafafb;
  border: 1px dashed rgba(66, 75, 84, 0.2);
  box-shadow: 0px 4px 4px rgba(218, 232, 245, 0.1);
  border-radius: 34px;
  display: block;
  padding: 20px;
}
.skill-set .action {
  background-color: #f15d3b;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
}
.skill-set .action + .action {
  margin-left: 10px;
}
.skill-set .action img {
  width: 51%;
}

#editPortfolio .modal-dialog {
  max-width: 800px;
}

.tags.tags-fill li a,
.tags.tags-fill div {
  display: flex;
  background: #edf5fd;
  border: 1px solid #e0eaf5;
  color: #000;
  font-size: 10px;
  padding: 5px 8px;
}
.tags.tags-fill li a:hover {
  background-color: var(--theme-color);
  color: #fff;
  transition: all 0.5s ease-in-out;
}
.tags.tags-fill li a:hover svg path {
  fill: #fff;
  fill-opacity: 1;
  transition: all 0.5s ease-in-out;
}
.tags.tags-fill li a i {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.tags.tags-fill li a svg {
  width: 10px;
}
.portfolio-images {
  /* display:flex; */
  /* flex-wrap: wrap; */
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
  overflow: hidden;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  margin: 0px 5px;
}
.portfolio-images img {
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: relative;
  display: inline-block;
}
/* .portfolio-images .portfolio-img figure{
    border-radius:5px;
    display:flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
} */
/* .portfolio-images .portfolio-img figure img{
    width:100%;
    object-fit: cover;
} */

#AddSkills {
  display: block;
}
#editPortfolio {
  display: none;
}
#editPoject {
  display: none;
}

.pre-next-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 94%;
}

.pre-next-btn span {
     color: #3ba0aa;
     cursor: pointer;
}

.modal-content h5 {
  font-size: 1rem;
}
.modal-content .job-title::before {
  display: inline-block;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3A9EA8;
  margin: 0 10px 0 -20px;
  position: relative;
  top: -4px;
}

.modal-radio {
  width: 15px;
  height: 15px;
}

.modal-radio:checked {
  accent-color: #3BA0AA;
}


@media(max-width:768px){
  .modal-content{
    width: 100% !important;
  }
  .modal-body{
    padding:15px;
  }
  .modal-body .bottom-button{
    margin:0 -15px;
    padding-top:10px;
  }
  .modal-body .bottom-button .cancelBtn{
    margin:0 0 10px;
    width:100%;
  }
  .modal-body .bottom-button .submitBtn {
    width:100%;
  }
}