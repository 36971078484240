.stack-skillname img{
    margin-left: 10px;
    margin-right: 0;
    border: thin solid #D2E2F2;
    border-radius: 30px;
    padding: 3px;
}

.stack-skillname img:hover{
    cursor: pointer;
}


.skillsInStack{
    display: flex;
    border: 1px solid #E5E5E5;
    padding: 3px 10px;
    background-color: white;
    border-radius: 30px;
    margin-right: 10px;
}
.skillsInStack img{
    margin-right:5px;
    width: 20px;
}
.skillsInStack span{
    line-height: 20px;
    font-size: 14px;
    /* font-family: 'Poppins-Regular'; */
    font-weight: 400;
    color: #242C33;
}

.stack-skill-select{
    display: flex;
}


