.lightboxpreview {
  transition: all 0.3s linear;
  padding-top: 60%;
  cursor: pointer;
  background-size: cover;
}

.lightbox-content {
  max-height: 75vh;
  height: 75vh;
  width: 100%;
  max-width: 1000px;
}

.lightbox-close {
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  right: -30px;
  top: -30px;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
}
.modal_inner_image {
  min-height: 400px;
  z-index: 1000;
}
.modal-content {
  width: 100%;
}

.modalscale {
  transform: scale(0);
  opacity: 0;
}

.lightbox-container,
.lightbox-btn,
.lightbox-image-wrapper,
.lightbox-image {
  transition: all 0.4s ease-in-out;
}
.lightbox-enabled {
  padding-top: 65%;
  cursor: pointer;
  background-size: cover;
  background-position: center;
}

.lightbox-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
}

.lightbox-container.active {
  opacity: 1;
  pointer-events: all;
}
.lightbox-image-wrapper {
  display: flex;
  transform: scale(0);
  align-items: center;
  justify-content: center;
  max-width: 90vw;
  max-height: 90vh;
  position: relative;
}
.lightbox-container.active .lightbox-image-wrapper {
  transform: scale(1);
}
.lightbox-btn,
#close {
  color: white;
  z-index: 9999999;
  cursor: pointer;
  position: absolute;

  font-size: 50px;
}

.lightbox-btn:focus {
  outline: none;
}

.left {
  left: 50px;
}
.right {
  right: 50px;
}
#close {
  top: 50px;
  right: 50px;
}

.lightbox-image {
  width: 100%;
  -webkit-box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.19);
  max-height: 95vh;
  object-fit: cover;
}

@keyframes slideleft {
  33% {
    transform: translateX(-300px);
    opacity: 0;
  }
  66% {
    transform: translateX(300px);
    opacity: 0;
  }
}

.slideleft {
  animation-name: slideleft;
  animation-duration: 0.5s;
  animation-timing-function: ease;
}
@keyframes slideright {
  33% {
    transform: translateX(300px);
    opacity: 0;
  }
  66% {
    transform: translateX(-300px);
    opacity: 0;
  }
}

.slideright {
  animation-name: slideright;
  animation-duration: 0.5s;
  animation-timing-function: ease;
}

.carousel {
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
}

.slides {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.slides::-webkit-scrollbar {
  display: none;
}

.slides-item {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-shrink: 0;
  font-size: 100px;
  height: 600px;
  justify-content: center;
  margin: 0 1rem;
  position: relative;
  scroll-snap-align: start;
  transform: scale(1);
  transform-origin: center center;
  transition: transform 0.5s;
  width: 100%;
}

.carousel__nav {
  padding: 1.25rem 0.5rem;
}

.slider-nav {
  align-items: center;
  background-color: #ddd;
  border-radius: 50%;
  color: #000;
  display: inline-flex;
  height: 1.5rem;
  justify-content: center;
  padding: 0.5rem;
  position: relative;
  text-decoration: none;
  width: 1.5rem;
}

.slider-nav:hover,
.slider-nav:active {
  background-color: #000;
  color: #fff;
}

.slide-2 {
  background-color: #bfd;
}

.slide-3 {
  background-color: #cdf;
}

.slide-4 {
  background-color: #dca;
}

.slide-5 {
  background-color: #ffc;
}

.carousel__skip-link {
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.carousel__skip-link:focus {
  align-items: center;
  background-color: #ddd;
  color: #fff;
  display: flex;
  height: 600px;
  justify-content: center;
  opacity: 0.8;
  width: 100%;
  z-index: 10;
}

.carousel__skip-message {
  background-color: #000;
  color: #fff;
  padding: 0.5rem;
  width: 100%;
}
