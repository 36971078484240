.pagination-btn{
    padding: 4px 8px;
    height: 25px;
    width: 25px;
    margin-left: 5px;
    outline: none;
    border: none;
    border-radius: 4px;
    font-family: 'Poppins-medium';
    
}

.pgn-svg{
    padding: 4px 5px;
    height: 25px;
    width: 25px;
    margin-left: 5px;
    outline: none;
    border: none;
    border-radius: 4px;
    
}

.pgn-inactive{
    background-color: #D2E2F2;
    color: #3d4c5a;
}

.pgn-active{
    background-color: #3BA0AA;
    color: #fff;
}

.pgn-svg span .leftSVG, .rightSVG{
    
    padding-top: 5px;
}

