/* ====================== SIGNUP PAGE ===================== */
/* .btn{
    padding: 6px 10px!important;
} */
.ck-editor__editable_inline {
    min-height: 110px;
  }
.heighlight{
    box-shadow: rgb(219 227 235) 0px 4px 14px 6px;
    background: #1ea6ba !important;
    color: #fff !important;
}
.signup{
    display:flex;
    flex-direction: column;
    font-size:14px;
    /* font-family: "Poppins-Regular"; */
    font-weight: normal;
    color:#242C33;
}
.signup-block{
    display:flex;
    margin-bottom: 25px;
}
.signup-block .signup-left{
    flex:0 0 50%;
    padding:15px;
    /* background: url('../../assets/img/lo-si-left.webp') no-repeat center bottom; */
    background: url('https://ik.imagekit.io/olibr/assets/tr:w-712,h-295/lo-si-left.webp') no-repeat center bottom;
    background-size: 100%;
}
.signup-block .signup-left .text-logo{
    display:flex;
    margin: 0 auto 34px;
    justify-content: flex-start;
    max-width: 400px;
    padding:0 15px;
}
.signup-block .signup-left .text-logo img{
    height:57px;
}
.signup-block .signup-left .helptohire {
    position: relative;
    padding:24px 36px;
    margin:0 auto;
    display: block;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #F5F8FF;
    box-shadow: 1px 4px 10px 7px rgba(210, 226, 242, 0.30);
    border-radius: 8px;
    max-width: 400px;
}
.signup-block .signup-left .helptohire h4{
    font-size:20px;
    /* font-family: "Poppins-Medium"; */
    /* font-weight: normal; */
    font-weight: 500;
    text-transform: uppercase;
    margin:0 0 23px;
}
.signup-block .signup-left .helptohire h1{
    font-size:20px;
    /* font-family: "Poppins-Medium"; */
    /* font-weight: normal; */
    font-weight: 500;
    text-transform: uppercase;
    margin:0 0 23px;
}
.signup-block .signup-left .helptohire ul{
    display:flex;
    flex-direction: column;
}
.signup-block .signup-left .helptohire ul li{
    font-size:14px;
    /* font-family: "Poppins-Regular"; */
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signup-block .signup-left .helptohire ul .circle-no{
    display:flex;
    justify-content: center;
    align-items: center;
    width:40px;
    height:40px;
    border-radius: 50%;
    margin-bottom: 12px;
    /* font-family: "Poppins-SemiBold"; */
    font-weight: 600;
    font-size: 20px;
}
.signup-block .signup-left .helptohire ul li p, .client-dash .toggle-block .help-to-hire p{
    text-transform: uppercase;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
}
.justify-content-end{
    display: flex;
    justify-content: flex-end;
}
.signup-block .signup-right{
    flex:1;
    padding:38px;
    background-color: white;
}
.signup-block form{
    margin:0;
}
.signup-block .signup-right .brand-logo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    font-size: 14px;
    /* font-family: "Poppins-Regular"; */
    font-weight: normal;
    color: #242C33;
}
.signup-block .signup-right .brand-logo img{
    width:60px;
    margin-bottom: 12px;
    margin-right: 15px;
}
.signup-block .signup-right .brand-logo h5{
    font-size: 28px;
    /* font-family: 'Poppins-SemiBold'; */
    /* font-weight: normal; */
    font-weight: 600;
    color: #041213;
    line-height: 32px;
    margin-bottom: 10px;
    
}
/* .helptohire{

} */
.light-green{
    background-color: #DCF7E9;
    border:1px solid #CEEADC;
}
.light-yellow{
    background-color: #FFEDA7;
    border:1px solid #F2D667;
}
.light-blue{
    background-color: #EBEBFF;
    border:1px solid #E4E4FD;
}
.clients{
    display:block;
    margin-bottom: 40px;
    padding:0 15px;
}
.clients .brands{
    display:flex;
    flex-wrap: wrap;
}
.clients .brands li{
    flex:auto;
    padding:10px;
    /*display: flex;*/
    justify-content: center;
}
.clients .brands li img{
    max-height:30px;
}
.copyrights{
    display:flex;
    justify-content: center;
    color:#424B54;
    font-size:16px;
    margin-bottom: 40px;
}

/* dotted dropdown */
td .dots-dropdown {
    color: gray;
    font-size: 18px;
}
td .three-dots {
    text-align: center;
    padding-right: 10px;
    position: relative;
}
.three-dots i {
    font-size: 5px;
    color: #6E7880;
}
.three-dots {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2px;
}
.dots-menu {
    position: absolute;
    margin-top: 4px;
    right: 40px;
    width: 168px;
    background-color: white;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.19), 0px 6px 6px 0px rgba(0, 0, 0, 0.26);
    z-index: 1;
}
.dots-menu li {
    padding: 9px 20px;
    color: #242C33;
    display: flex;
    align-items: center;
    gap: 10px;
}
.dots-menu li:hover{
    background-color: #EFF4FC;
}
.dots-menu li svg {
    width: 15px;
}

.table-wrapper {
    margin-bottom: 40px;
}
.table-wrapper p {
    margin-bottom: 0;
}
.shortlisted-wrap .header-right a,
.shortlisted-wrap .header-right a:hover {
    background-color: transparent;
}

.dash-block .padding-bottom.card {
  padding-bottom: 20px;
}

.interview-cal td .hidden-schedule {
    display: none;
}
/* use below code if you want to add arrow to box */
/* .dots-menu::after {
    content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom:7px solid white;
    right: 13px;
    top: -5px;
} */

@media(max-width:768px){
    .signup-block{
        flex-direction: column;
    }
    /* .brands{

    } */
}

/* ========================== SIGNUP PAGE ENDING ================================  */


/* ========================== CLIENT MAIN DASHBOARD ================================  */

.client-dash{
    display:block;
    /* font-family: 'Poppins-Regular'; */
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
    color:#000;
    padding:20px;
}
.client-dash-header{
    display:flex;
    justify-content: space-between;
    margin-bottom: 26px;
}
.client-dash-header h2{
    /* font-family: 'Poppins-Medium'; */
    font-style: normal;
    /* font-weight: normal; */
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
}
/* .client-dash-header .user{
    
} */
.client-dash .toggle-block{
    display:block;
    position: relative;
    margin-bottom: 17px;
    background: #F8FAFF;
    padding-top: 25px;
}
.client-dash .toggle-block h2{
    text-transform: uppercase;
    display:block;
    text-align: center;
    margin-bottom: 14px;
    line-height: 28px;
    font-size: 24px;
}
.client-dash .toggle-block .close-block{
    position: absolute;
    right:20px;
    top:20px;
    color:#747C75;
    font-size:14px;
}
.client-dash .light-green{
    background-color: #DCF7E9;
    border:1px solid #CEEADC;
    box-shadow: 0px 0px 12px 2px #E1F5EB;
}
.client-dash .light-yellow{
    background-color: #FFEDA7;
    border:1px solid #F2D667;
    box-shadow: 0px 0px 12px 2px #FCF3D2;
}
.client-dash .light-blue{
    background-color: #EBEBFF;
    border:1px solid #E4E4FD;
    box-shadow: 0px 0px 12px 2px #EBEBFF;
}
.client-dash .toggle-block .help-to-hire{
    display:flex;
}
.client-dash .toggle-block .help-to-hire li{
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding:20px;
    font-size:14px;
}
.client-dash .toggle-block .help-to-hire .circle-no{
    display:flex;
    height:40px;
    width:40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    font-size:24px;
    /* font-family: "Poppins-Medium"; */
    /* font-weight: normal; */
    font-weight: 500;
}
.dash-block{
    display:flex;
    flex-direction: column;    
}
.dash-block .dash-cards{
    display:flex;
    flex-wrap: wrap;
    grid-gap:15px;
    margin-bottom: 30px;
}
.dash-block .dash-cards .dash-card{
    flex:1;
    padding:30px;
    border-radius: 8px;
    display:flex;
}
.dash-block .dash-cards .dash-card figure{
    background-color: #bae5cf;
    border:1px solid #b6ccc1;
    width:60px;
    height:60px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding:8px;
    margin-right:15px;
}
.dash-block .dash-cards .dash-card.light-blue figure{
    background-color: #cfcff1;
    border:1px solid #c2c2eb;
}
.dash-block .dash-cards .dash-card.light-yellow figure{
    background-color: #fbe590;
    border:1px solid #dfc971;
}
.dash-block .dash-cards .dash-card .dash-card-detail{
    display:flex;
    flex-direction: column;
}
.dash-block .dash-cards .dash-card .dash-card-detail strong{
    font-size:36px;
    line-height: 36px;
    /* font-family: "Poppins-Medium"; */
    /* font-weight: normal; */
    font-weight: 500;
    margin-bottom: 5px;
}
.dash-block .card{
    padding:0 20px 5px;
    background: #F8FAFF;
    border-radius: 8px;
    border: 1px solid #F5F8FF;
}
.dash-block .card:hover {
    box-shadow: none;
    border: none;
}
.dash-block .card .card-header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:15px 0;
    border-bottom: 1px solid #E1E9FB;
    margin-bottom: 15px;
}
.dash-block .card .card-header h4{
    font-size:16px;
    /* font-weight:normal; */
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    text-transform: uppercase;
}
.dash-block .card .card-header .link{
    font-size:16px;
    font-weight:normal;
    /* font-family: "Poppins-Regular"; */
    text-transform: uppercase;
    color:#3BA0AA;
}
.dev-table{
    margin-bottom: 10px;
}
.dev-table thead tr th{
    color:#717E9E;
    font-size:14px;
    border:none;
    text-align: left;
    padding:12px;
}
.dev-table tbody tr td{
    font-size: 12px;
    padding:12px;
    text-align: left;
    background-color: #fff;
    border-bottom: 5px solid #F8FAFF;
}

.dev-table tbody tr td {
    background-color: #fff;
    border-bottom: 5px solid #f8faff;
    font-size: 12px;
    padding: 12px;
    text-align: left;
}

.dev-table tbody tr td .developer-details{
    /* display:flex;*/
    display: grid;
    grid-template-columns: 36px 1fr;
    column-gap: 10px;
    font-size:14px;
}
.dev-table tbody tr td .developer-details figure{
    width:36px;
    height:36px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    border: 1px solid #C1DAF2;
    -webkit-box-shadow: 4px 4px 5px 0px rgba(210,226,242,0.50);
-moz-box-shadow: 4px 4px 5px 0px rgba(210,226,242,0.50);
box-shadow: 4px 4px 5px 0px rgba(210,226,242,0.50);
}
.dev-table tbody tr td .developer-details h5{
    font-size:14px;
    /* font-weight:normal; */
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    line-height: 15px;
}
.dev-table tbody tr td .developer-details p{
    font-size: 12px;
    line-height: 13px;
    /* font-family: "Poppins-Regular"; */
    font-weight: 400;
    margin: 0;
}
.job-posted{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    background: #F8FAFF;
    padding: 25px;
    margin-top: 25px;
    border-radius: 8px;
    border: 1px solid #F5F8FF;
}
.job-posted p{
    font-size:14px;
    line-height: normal;
}
.job-posted .job-header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 19px;
    border-bottom: 1px solid #E1E9FB;
    margin-bottom: 20px;
}
.job-posted .job-header h3{
    font-size:16px;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    text-transform: uppercase;
}
.job-posted .block-title{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.job-posted .block-title h4{
    font-size:14px;
}
.job-posted .block-title h4 a{
    color: #000;
    text-transform: capitalize;
    font-size: 16px;
}
.job-posted .block-title h4 a:hover{
    color:#3BA0AA;
}
.job-posted .block-title .link{
    color:#6D6F6D;
    font-size: 12px;
    text-transform: uppercase;
}
.job-posted .icon-tags{
    margin-bottom: 0px;
}
.job-posted .job-category{
    padding:12px;
    border:1px solid #dee3ef;
    background: #fff;
    margin-bottom: 15px;
}
.job-posted .job-category:hover{
    border: 1px solid #D2E2F2;
    box-shadow: 0px 4px 4px rgba(210, 226, 242, 0.2);
    border-radius: 8px;
}

@media(max-width:768px){
    .dash-block .dash-cards{
        flex-direction: column;
    }
    .client-dash .toggle-block .help-to-hire{
        flex-direction: column;
    }
    .client-dash .toggle-block h2{
        line-height: normal;
    }
}

/* ========================== CLIENT MAIN DASHBOARD ENDING ================================  */


/* ========================== SEARCH SKILLS ================================  */
.skill-outer{
    padding: 25px;
    margin: 0px auto;
    max-width: 900px;
    width: 100%;
}
.client-searh-skill{
    display: block;
    margin-bottom: 40px;
}
.client-searh-skill ul{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
}
.client-searh-skill ul li {
    flex: auto;
    padding: 5px;
    justify-content: left;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #E4E9FF;
    font-size: 13px;
    color: #242C33;
    transition: all .3s ease-in-out;

}
.client-searh-skill ul li a{
    color: #242C33;
    display: block;
}
.client-searh-skill ul li:hover{
    -webkit-box-shadow: 4px 4px 5px 0px rgba(210,226,242,0.35);
    -moz-box-shadow: 4px 4px 5px 0px rgba(210,226,242,0.35);
    box-shadow: 4px 4px 5px 0px rgba(210,226,242,0.35);
    transition: all .3s ease-in-out;
    border: 1px solid #C8D0F0;
}
.client-searh-skill li img{
    padding: 5px;
    width: 32px;
    height: 32px;
    vertical-align: middle;
}

/* ========================== SEARCH SKILLS ENDING ================================  */


.my-multi-lines-item {
    font-size: 14px;
    white-space: initial;
    display: flex;
    height: 60px;
    line-height: normal;
}

.my-multi-lines-text {
    margin-top: auto;
    margin-bottom: auto;
}

/* ========================== SEARCH DEVELOPERS ================================  */

.search-wrap{
    display: block;
    padding: 20px;
}

.search-wrap .client-dash-header{
    display:flex;
}
.search-wrap .client-dash-header h2{
    font-size:24px;
    /* font-weight: normal; */
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
}
.search-wrap .search-filter-block{
    display:flex;
    flex-direction: column;
}
.search-wrap .search-filter-block .filter-header{
    display:flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #D2E2F2;
    position: relative;
    margin-bottom: 12px;
}

.search-wrap .search-filter-block .filter-header .filter-title{
    font-size:20px;
    /* font-weight: normal; */
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    position: relative;
    display: inline-block;
    padding:0 0 10px;
}
.search-wrap .search-filter-block .filter-header .filter-title:after{
    background-color: #3ba0aa;
    display: block;
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0px;
    width: 100%;
    height: 2px;
}
.search-wrap .search-filter-block .filter-header .filter-link{
    color:#3BA0AA;
    font-size:16px;
    /* font-weight: normal; */
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
}
.search-wrap .search-filter-block .search-box{
    display:block;
    position: relative;
    margin-bottom: 5px;
}
.search-wrap .search-filter-block .search-box .search-link{
    position: absolute;
    right:0;
    top:0px;
    width:35px;
    height:35px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.search-wrap .search-filter-block .search-box .search-link img{
    width:14px;
}
.search-wrap .search-filter-block .serched-skills{
    display: block;
    margin-bottom: 15px;
}
.search-wrap .search-filter-block .serched-skills .icon-tags{
    margin-bottom: 0px;
}
.search-wrap .search-filter-block .serched-skills .clear-filter-block{
    display: flex;
    justify-content: flex-end;
}
.search-wrap .search-filter-block .serched-skills .clear-fiter{
    color:#F15D3B;
    font-size:14px;
    font-weight: normal;
    /* font-family: "Poppins-Regular"; */
    display:flex;
    align-items: center;

}
.search-wrap .search-filter-block .serched-skills .clear-fiter img{
    margin-right:4px;
    height:12px;
}
.search-wrap .search-list{
    display:flex;
    flex-direction: column;
}
.search-wrap .developer-block{
    display:flex;
    padding:19px;
    border-bottom:2px solid #D2E2F2;
}
.search-wrap .developer-block:last-child{
    border-bottom: none;
}
.search-wrap .developer-block .developer-img{
    border: 1px solid rgba(211, 220, 229, 0.5);
    border-radius: 8px;
    display:flex;
    flex-direction: column;
    flex:0 0 160px;
    width:160px;
    height:160px;
    overflow: hidden;
    position: relative;
}
.search-wrap .developer-block .developer-img .user-img{
    object-fit: cover;
}
.search-wrap .developer-block .developer-img figcaption{
    background-color: rgba(59, 160, 170, .9);    
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:auto;
    padding:10px;
}
.search-wrap .developer-block .developer-img figcaption h6{
    display:block;
    text-align: center;
    color:#fff;
    font-size:14px;
    /* font-weight: normal; */
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
}
.search-wrap .developer-block .developer-details{
    flex:1;
    padding:0 17px;
}
.search-wrap .developer-block .developer-details .detail-header{
    display:flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.search-wrap .developer-block .developer-details .detail-header-left{
    flex:1;
    padding-right:10px;
}
.search-wrap .developer-block .developer-details .detail-header-right{
    display:flex;
    align-items: center;
    flex:0 0 60px;
}
/* .search-wrap .developer-block .developer-details .detail-header-right .sortlisted{

} */
.search-wrap .developer-block .developer-details .detail-header-right .sortlisted img{
    height: 20px;
    margin-right: 40px;
}
/* .search-wrap .developer-block .developer-details .developer-description{

} */
.search-wrap .developer-block .developer-details .developer-description p.avaiability{
    font-size: 12px;
    font-weight: normal;
    /* font-family:"Poppins-Regular"; */
}
.search-wrap .developer-block .developer-details .developer-description p.avaiability strong{
    /* font-family:"Poppins-Medium"; */
    font-weight: 500;
}
.search-wrap .developer-block .developer-details .developer-description .icon-tags{
    margin-bottom:0px;
}

.speak-with-dev{
    color: #222626;
    text-transform: uppercase;
}
.shortlisted-wrap label span{
    text-transform: uppercase;
}
a.assign-job {
    font-size: 11px;
    color: #636984;
    padding: 5px 10px;
    border: 1px solid #C4C7D4;
    border-radius: 8px;
    text-transform: uppercase;
    transition: all .5s ease-in-out;
}
a.assign-job:hover, a.assign-job.selected{
    background: #636984;
    color: #fff;
    transition: all .5s ease-in-out;
}
@media(max-width:768px){
    .search-wrap .developer-block{
        flex-direction: column;
        padding:19px 0;
    }
    .search-wrap .developer-block .developer-img{
        margin-bottom: 15px;
    }
    .search-wrap .developer-block .developer-details{
        padding:0;
    }
}

/* ========================== SEARCH DEVELOPERS ENDING ================================  */

/* ========================== SHORTLISTED DEVELOPERS ================================  */

.shortlisted-wrap  .developer-block{
    display:flex;
    padding:19px;
    border-bottom:1px solid #D2E2F2;
}
.shortlisted-wrap .developer-block:last-child{
    border-bottom: none;
}

.shortlisted-wrap .developer-block .developer-img figcaption{
    background-color: rgba(59, 160, 170, .9);    
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:auto;
    padding:10px;
}
.shortlisted-wrap .developer-block .developer-img figcaption h6{
    display:block;
    text-align: center;
    color:#fff;
    font-size:14px;
    /* font-weight: normal; */
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
}

.shortlisted-wrap .developer-block .developer-details .developer-description p.avaiability{
    font-size: 12px;
    font-weight: normal;
    /* font-family:"Poppins-Regular"; */
}
.shortlisted-wrap .developer-description .avaiability .checkbox{
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
.shortlisted-wrap .developer-description .avaiability label {
    position: relative;
    cursor: pointer;
}
.shortlisted-wrap .developer-description .avaiability label:before {
    content:'';
    -webkit-appearance: none;
    background-color: #3BA0AA;
    border: 1px solid #22757D;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius:3px;
}
  
.shortlisted-wrap .developer-description .avaiability .checkbox:checked + span:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 2px;
    height: 6px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.shortlisted-wrap .developer-block .developer-details .developer-description .icon-tags{
    margin-bottom:5px;
}
.shortlisted-wrap .btn-block .btn{
    min-width: 164px;
}
.justify-content-end{
    justify-content:flex-end !important;
}

.shortlisted-wrap h4, .search-wrap h4 {
    font-size: 16px;
    cursor: pointer;
}
.detail-header-left p{
    font-size: 12px;
}
@media(max-width:768px){
    .shortlisted-wrap .developer-block{
        flex-direction: column;
        padding:19px 0;
    }
    .shortlisted-wrap .developer-block .developer-img{
        margin-bottom: 15px;
    }
    .shortlisted-wrap .developer-block .developer-details{
        padding:0;
    }
}

/* ========================== SHORTLISTED DEVELOPERS ENDING ================================  */
/* ========================== SHORTLISTED PROFILE ================================  */
.shortlisted-wrap{
    display: block;
    padding: 20px;
}

.shortlisted-wrap .icon-tags li a, .search-wrap .icon-tags li a{
    padding: 4px 8px;
    font-size: 10px;
}
.search-wrap p, .shortlisted-wrap p{
    font-size:14px;
    margin-bottom: 5px;
    /* font-family: "Poppins-Light"; */
    font-weight: 300;
    line-height: 20px;
    color: #000;
}
.shortlisted-wrap .client-dash-header{
    display:flex;
}
.shortlisted-wrap .client-dash-header h2{
    font-size:24px;
    /* font-weight: normal; */
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
}
.client-dash-header .user .dropdown {
    position: relative;
    display: inline-block;
}
.client-dash-header .user a {
    border: 1px solid rgb(204, 204, 204);
    box-shadow: rgb(211 220 229 / 50%) 0px 4px 5px;
    background-color: #F6F8FA;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease-in-out;
}
.client-dash-header .user a img{
    max-width: 16px;
}
.client-dash-header .user ul.popupList li {
    cursor: pointer;
    padding: 10px 15px;
    transition: all .5s ease-in-out;
    border-radius: 0;
}
.client-dash-header .user ul.popupList li:hover{
  background:#e9e9e9;
  transition: all .5s ease-in-out;
}
.client-dash-header .user .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    padding: 0;
    z-index: 1;
    right: 0;
}
.client-dash-header .user .dropdown.active .dropdown-content,
.client-dash-header .user .dropdown:focus .dropdown-content,
.client-dash-header .user .dropdown:hover .dropdown-content{
    display:block;
}
.shortlisted-wrap .shortlisted-block{
    display:flex;
    flex-direction: column;
}
.shortlisted-wrap .shortlisted-block .shortlisted-block-title{
    font-size:16px;
    /* font-weight: normal; */
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
}
.shortlisted-wrap .developer-block:last-child{
    border-bottom: none;
}
.shortlisted-wrap .developer-block .developer-img{
    border: 1px solid rgba(211, 220, 229, 0.5);
    border-radius: 8px;
    display:flex;
    flex-direction: column;
    flex:0 0 160px;
    width:160px;
    height:160px;
    overflow: hidden;
    position: relative;
}
.shortlisted-wrap .developer-block .developer-img .user-img{
    object-fit: cover;
}
.shortlisted-wrap .developer-block .developer-details{
    flex:1;
    padding:0 17px;
}
.shortlisted-wrap .developer-block .developer-details .detail-header{
    display:flex;
    justify-content: space-between;
}
.shortlisted-wrap .developer-block .developer-details .detail-header-left{
    flex:1;
    padding-right:10px;
}
.shortlisted-wrap .developer-block .developer-details .detail-header-right{
    display:flex;
    align-items: center;
    flex:-1 0 336px;
    justify-content: flex-end;
}
.shortlisted-wrap .developer-block .developer-details .detail-header-right .close-icon{
    position: relative;
    margin-left:15px;
}
.shortlisted-wrap .developer-block .developer-details .detail-header-right .close-icon img{
    height:12px;
}
.shortlisted-wrap .btn-block .btn{
    min-width: 164px;
}
.justify-content-end{
    justify-content:flex-end !important;
}
.job-rquirement .job-rquirement-title{
    margin-bottom: 10px;
    font-size:16px;
    /* font-weight:normal; */
    /* font-family:"Poppins-Medium"; */
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E1E9FB;
} 
.job-rquirement label{
    display: block;
    font-size:14px;
    margin-bottom: 3px;
}
.job-rquirement textarea.form-control{
    min-height: 200px;
}

.speak-link{
    color: #222626
}

@media(max-width:768px){
    .shortlisted-wrap .developer-block{
        flex-direction: column;
        padding:19px 0;
    }
    .shortlisted-wrap .developer-block .developer-img{
        margin-bottom: 15px;
    }
    .shortlisted-wrap .developer-block .developer-details{
        padding:0;
    }
}
/* ========================== SHORTLISTED PROFILE ENDING ================================  */


/* ========================== THANKYOU PAGE ================================  */

.thankyou-block{
    text-align: center;
    align-items: center;
    padding: 20px 0px;
    height: 80vh;
}

.thankyou-block span{
    font-size: 22px;
}

.thankyou-block span a{
    font-size: 16px;
    padding: 5px 40px;
}

.css-1l0peo9-container{
    min-width: 30px!important;
}

/* 25-04-2023 */
.job-posted .link{
    margin-left:15px;
    text-transform: uppercase;
}
.job-posted .job-category{
    display:flex;
    padding-bottom: 30px;
    /* border-bottom:1px solid #22757D;
    margin-bottom: 30px; */
}
/* .job-posted .job-category:last-child{
    border-bottom:none;
    padding-bottom: 0;
}
.job-posted .job-category:first-child{
    border-bottom:none;
    padding-bottom: 30px;
} */
.job-posted .job-category .user-img{
    flex-basis: 150px;
    max-width:150px;
    height:150px;
    border-radius: 8px;
    border: 1px solid #D3DCE550;
    overflow: hidden;
}
.job-posted .job-category .user-img img{
    object-fit: cover;
    height:150px;
    border-radius: 8px;
}
.job-posted .job-category .user-details{
    flex:1;
    padding:0 20px 20px;
}
.job-posted .block-title{
    font-size:16px;
    /* font-family: "Poppins-Medium"; */
    /* font-weight: normal; */
    font-weight: 500;
    line-height: 36px;
}
.job-posted .block-title small{
    font-size:12px;
    /* font-family: "Poppins-regular"; */
    font-weight: normal;
    line-height: 18px;
}
.job-posted .block-title h4{
    display: flex;
    flex-direction: column;
    font-size: 16px;
}
.job-posted .icon-tags{
    margin-bottom: 5px;
}
.job-posted .icon-tags li a{
    font-size:12px;
    line-height: 21px;
    border-color:#E5E5E5;
}
.job-posted .availablity{
    position: relative;
    padding-left:20px;
    font-size:12px;
    /* font-family: "Poppins-light"; */
    /* font-weight: normal; */
    font-weight: 300;
    line-height: 18px;
}
.job-posted .availablity:before{
    position: absolute;
    left:5px;
    top:5px;
    content:'';
    display:block;
    width:8px;
    height:8px;
    border-radius: 50%;
    background-color: #3ba0aa;
}
.shortlists{
    flex-basis: 100px;
    position: relative;
    justify-content: center;
    padding:15px 30px 0;
    display:flex;
    grid-gap:15px;
}
.shortlists .user-icon{
    position: relative;
    right:0px;
    top:0px;
}
.shortlists .check-icon,
.shortlists .cross-icon{
    position: absolute;
    right:-5px;
    top:3px;
}
.shortlists .check-icon{
    right:-8px;
}
.shortlists .ico-group.pending .user-icon,
.shortlists .ico-group.pending .check-icon{
    filter: grayscale(1);
}
.shortlists .ico-group.rejected .check-icon{
    display:none;
}
.shortlists .ico-group.rejected .cross-icon{
    display:block;
}
.shortlists .ico-group.rejected .user-icon,
.shortlists .ico-group.rejected .cross-icon{
    filter: hue-rotate(156deg) contrast(2.7);
}
.shortlists [data-tooltip]:not([data-flow])::after{
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%) translateY(0px);
}
.shortlists .watch-icon,
.shortlists .calander-icon,
.shortlists .cross-icon,
.shortlists .circle-check{
    display:none;
}
.shortlists .ico.calander .calander-icon{
    display:block;
}
.shortlists .ico.watch .watch-icon{
    display:block;
}
.shortlists .ico.circle-check .circle-check{
    display:block;
    fill:#27959F;
}
/* ========================== THANKYOU ENDING ================================  */


.shortlisted-wrap .assign-job-lists a{
    background-color: #CEF5FC;
    border-color: #A1E8F4;
    border-radius: 4px;
    padding: 5px!important;
    margin-top: 10px;
    margin-right: 5px;
    font-size: 12px!important;
}

/* Client Settings (subscription) */
.only-text-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}
.setting-card .subscription-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.subscription-settings button {
    text-transform: uppercase;
    color: #3BA0AA;
}

/* Subscription Plan */
.subscription-plan h3 {
    text-transform: uppercase;
}
.subscription-plan hr {
    border: 1px solid #E1E9FB;
    margin: 15px 0;
}
.subscription-plan .detailed-info {
    background: white;
    padding: 0px 20px 20px;
}
.detailed-info #skill-explanation {
    padding: 40px 20px;
}
.detailed-info h1 {
    font-size: 20px;
}
.detailed-info .container {
    overflow: auto;
}
.detailed-info .only-text-btn {
    color: red;
}
.detailed-info h3 {
    text-transform: capitalize;
}
#account-setting .detailed-info .validity {
    font-size: 14px;
    color: #435057;
    margin-top: 3px;
}
.detailed-info .custom-test {
    margin: 25px 0 0;
}

@media(max-width:768px){
    .signup-block .signup-left .text-logo img{
        height: 40px;
    }
    .signup-block .signup-left .helptohire{
        padding: 20px;
    }
    .signup-block .signup-right{
        padding:30px 20px;
    }
    .signup-right .brand-logo p a{
        display: block;
    }
    .signup-right .brand-logo p{
        width:100%;
    }
    .signup-block .signup-right .brand-logo h5{
        margin-bottom: 10px;
    }
    .signup-block .signup-right .col-12.justify-content-end{
        padding-top:10px;
    }
    .signup-block .signup-right .col-12.justify-content-end .btn{
        display:block;
        width:100%;
    }
    .dev-table tbody tr td .developer-details figure{
        flex:0 0 36px;
    }
    .setting-card .subscription-settings {
        flex-direction: column;
        align-items: flex-start;
    }
}