.pricing-banner {
  background-color: #3ba0aa;
  height: 180px;
  text-align: center;
  line-height: 180px;
}

.pricing-banner h1 {
  color: #fff;
  font-size: 2.5rem;
}

.pricing-page .auto-container {
  margin: 0px auto;
  max-width: 1280px;
  width: 100%;
}
/* .pricing-page section {
    padding: 1rem 0;
} */
.pricing-sec .container {
  margin: 0;
  max-width: none;
}
.pricing-container.row {
  margin-left: 0;
  margin-right: 0;
  row-gap: 40px;
}

.pricing-container .card-wrapper {
  flex: 0 0 33.33333333%;
  padding: 0 15px;
}

.pricing-page .sec-title {
  margin: 4rem 0 1rem;
}

.pricing-page .sec-title p {
  padding: 0 15px;
}

.pricing-sec .bold {
  font-weight: 500;
  font-size: 1.3rem;
  padding: 0 15px;
}

.pricing-sec .flex-group small,
.two-plans-modal .col-6 small {
  color: #3ba0aa;
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
}
.pricing-page .flex-group {
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.pricing-page .buttonFor {
  text-align: center;
}

.one-plan-modal .buttonFor {
  text-align: left;
}

.pricing-page .buttonFor span,
.two-plans-modal .buttonFor span,
.one-plan-modal .buttonFor span {
  margin: 0;
}

.pricing-tab {
  font-size: 16px;
  font-weight: 500;
  color: white;
  padding: 8px 24px;
}
.pricing-tab:first-of-type {
  border-radius: 8px 0 0 8px;
}
.pricing-tab:last-of-type {
  border-radius: 0 8px 8px 0;
}
.pricing-tabs-active {
  background-color: #3ba0aa;
  border: 1px solid #3ba0aa;
}
.pricing-tabs-inactive {
  border: 1px solid #3ba0aa;
  color: #3ba0aa;
}

.pricing-container {
  margin-top: 3rem;
}
.pricing-page .pricing-card {
  border: 1px solid #e2e4ea;
  padding: 0;
  position: relative;
}
.pricing-header {
  border-bottom: 1px solid #e2e4ea;
  padding: 19px 20px 19px 30px;
  /* min-height: 143px; */
  min-height: 154px;
}
.pricing-header h3,
#account-setting .setting-card .pricing-header h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
}
.pricing-header h4,
#account-setting .setting-card .pricing-header h4 {
  font-size: 20px;
  color: #2c8790;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 0;
}
.pricing-header h4 .big {
  font-size: 30px;
  margin: 0 5px;
}
.pricing-header h4 span:first-of-type {
  text-decoration: line-through;
}
.pricing-header .tag {
  float: right;
  /* margin-top: -103px; */
  overflow: hidden;
  border-radius: 30px;
  font-size: 10px;
  line-height: 20px;
  color: white;
  background-color: #f15d3b;
  padding: 6px 19px;
  /* width: max-content; */
}
.pricing-body {
  padding: 19px 30px;
}
.pricing-body li {
  list-style: none;
  color: #242c33;
  margin-bottom: 14px;
  position: relative;
  padding-left: 20px;
}
.pricing-body li small {
  font-size: 12px;
  color: #7c8083;
  display: inline-block;
  margin-top: 3px;
  line-height: 16px;
}
.pricing-body li:not(.light-txt):before {
  content: "\2713";
  position: absolute;
  left: 0;
  /* right: 8px; */
  /* padding-left: 5px; */
}
.pricing-body .light-txt {
  color: rgba(36, 44, 51, 0.5);
}
.pricing-body .light-txt::before {
  content: "X";
  position: absolute;
  left: 0;
}
.pricing-body .btn {
  width: 100%;
  /* margin: 3.4rem 0 1rem; */
  margin: 1rem 0;
  border-radius: 8px;
  padding: 14px 22px;
}
.pricing-header small {
  font-size: 11px;
  line-height: normal;
  color: #3ba0aa;
  display: inline-block;
}
.pricing-sec .container {
  padding: 0;
}
.pricing-sec {
  padding: 0 15px;
}
.pricing-page .tagged,
.pricing-card:hover {
  border: 1px solid #3497a1;
  border-radius: 8px;
}
.pricing-page .tagged .btn {
  margin-top: 1rem;
}

/* flag */
.pricing-page .dropbtn-olibr {
  height: 36px;
  padding: 0 10px;
  justify-content: flex-start;
}
.pricing-page .dropbtn-olibr::after {
  content: "\f078";
  font-family: "FontAwesome";
  color: lightgray;
  margin-left: 40px;
  position: relative;
  font-size: 14px;
}
.pricing-page .dropbtn-olibr.up::after {
  content: "\f077";
  font-family: "FontAwesome";
}
.one-plan-modal .dropdown-content-olibr {
  left: auto;
  right: 0;
  min-width: 150px;
}

/* custom test */
.custom-test input[type="checkbox"] {
  visibility: visible;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
/* change color of checkbox bg */
/* .pricing-page input[type="checkbox"]:checked {
    background-color: #3BA0AA;
} */
.custom-test {
  border: 1px solid #c8e7eb;
  border-radius: 8px;
  background-color: #f1feff;
  padding: 33px 40px;
  margin: 33px 15px 0;
  position: relative;
}
.custom-test-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* margin-bottom: 10px; */
}
.custom-test-form .checkbox-input {
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom-test-form label {
  height: 24px;
  font-size: 20px;
  font-weight: 600;
}
.custom-test-form div:last-of-type {
  color: #3e454b;
  font-weight: 500;
}
.custom-test-form .total span {
  color: #4f565b;
  font-weight: 700;
}
.setting-card .colored-p,
.custom-test p {
  color: #197079;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  margin-left: 2rem;
}
.custom-test small {
  font-size: 12px;
  color: #242c33;
  margin-left: 2rem;
}
.custom-test .numTests button {
  border: 1px solid #ededed;
  background-color: white;
  text-align: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.custom-test .numTests span {
  display: inline-block;
  border: 1px solid #def4f6;
  background-color: white;
  text-align: center;
  width: 54px;
  height: 35px;
  border-radius: 4px;
  line-height: 35px;
  margin: 0 10px;
}

/* testimonial section */
/* .pricing-page .testimonial-section {
    
} */
.pricing-page h2 {
  padding: 0 15px;
}

.pricing-page .testimonial-section h2 {
  color: white;
  margin-bottom: 30px;
}

/* contact us */
.pricing-page .captcha {
  width: max-content;
  float: right;
  margin-top: 20px;
}
.pricing-page .block-header h4 {
  text-transform: none;
  font-weight: 600;
}
/*.pricing-page .contact-info {
    text-align: left;
}*/
.pricing-page .socials i {
  color: white;
  background-color: #3ba0aa;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  margin: 20px 10px 0 0;
}

/* bottom banner */
.pricing-page .bottom-banner {
  background-color: #d4f1f4;
  padding: 50px 0;
  text-align: center;
}
.pricing-page .bottom-banner p {
  color: #455059;
  font-weight: 500;
  font-size: 18px;
  max-width: 1080px;
  margin: 0px auto;
  line-height: 32px;
}

/* faq */
.pricing-page .faq-section {
  padding: 20px 0 100px 0;
  text-transform: capitalize;
}
.faq-section .acc-item {
  overflow: hidden;
}
.faq-section .acc-item hr {
  border: 1px solid rgba(211, 211, 211, 0.6);
}
.acc-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 10rem; */
  margin-top: 3rem;
  /* height: 13rem; */
}
.acc-item i {
  font-size: 0.7rem;
}
.acc-item .question {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
}
.acc-item .question p {
  margin-bottom: 0;
  font-weight: 500;
  color: #271a3e;
  font-size: 14px;
}
.acc-item .answer {
  margin-left: 1.2rem;
  color: #382d4b;
  margin-top: 0.2rem;
  font-size: 13px;
  line-height: 24px;
  text-align: justify;
  font-weight: 400;
}
.acc-item hr {
  margin: 1.5rem 0 1.5rem 1.2rem;
  border: 1px solid #ebecf3;
}

/* Payment Messages */
.payment-result {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.payment-result .payment-msg {
  text-align: center;
  max-width: 446px;
  border-radius: 16px;
  padding: 30px;
}

.payment-result h1 {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
}

.payment-result i {
  font-size: 40px;
}

.payment-result p {
  font-weight: 400;
}

.payment-success h1,
.payment-success i {
  color: #0f9e31;
}
.payment-success {
  border: 1.5px solid #21b444;
}

.payment-fail i {
  color: #f15d3b;
}
.payment-fail {
  border: 1.5px solid #f15d3b;
}

.custom-test .btn {
  position: absolute;
  right: 40px;
  bottom: 30px;
}

.one-plan-modal .custom-test .btn {
  right: 20px;
  bottom: 30px;
}

/* pricing summary */
.pricing-summary {
  border: 1px solid #c8e7eb;
  border-radius: 8px;
  position: relative;
  top: -12px;
  background-color: white;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 33px;
  font-weight: 600;
  font-size: 1rem;
}

.pricing-summary .bill-overview {
  display: flex;
  align-items: center;
  gap: 20px;
}

.pricing-summary .bill-overview span {
  font-size: 12px;
  font-weight: 400;
  display: block;
}

.detailed-info .pricing-summary {
  margin: 0;
}

.one-plan-modal .pricing-summary {
  top: -30px;
}

.admin-modal .pricing-container .card-wrapper {
  flex: 0 0 50%;
}

.detailed-info .upgrade-btn {
  float: right;
  margin-top: -35px;
}

/* Payment OVerview */
.payment-overview {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  padding: 20px;
}
.payment-overview .subscription-plan {
  height: 90vh;
  width: 80vw;
}
.payment-overview .payment-details {
  min-width: 400px;
}
.payment-overview .setting-card .detailed-info {
  padding: 20px;
}
.payment-overview thead {
  background-color: rgb(246, 247, 252);
}
.payment-overview thead th {
  padding: 20px;
  color: black;
  font-weight: 600;
}
.payment-overview table tbody td {
  padding: 15px 20px;
}
.payment-overview .bold {
  font-weight: 600;
}
.payment-overview table .bold td {
  border-bottom: none;
  padding-bottom: 0;
}
.payment-overview table .bold:first-of-type td {
  padding-bottom: 30px;
  background-color: pink;
}
.payment-overview .total-amount {
  background-color: #f1feff;
  color: #3ba0aa;
  font-weight: 700;
  text-transform: uppercase;
}
.payment-overview .total-amount td {
  color: #3ba0aa;
}
.payment-overview .spacer td {
  border-bottom: none;
}
.payment-overview ul {
  list-style: disc;
  padding: 10px 40px 20px;
}
.payment-overview #account-setting .skillmh {
  text-align: center;
  font-size: 13px;
}
.payment-overview .flex-group {
  margin-top: 20px;
  flex-wrap: wrap;
}

/* media queries */
@media (max-width: 1240px) {
  .pricing-container .card-wrapper {
    flex: 0 0 50%;
  }
}

@media (max-width: 769px) {
  .pricing-container .card-wrapper {
    flex: 0 0 100%;
  }
  .acc-wrapper {
    grid-template-columns: 1fr;
  }
  .custom-test-form .checkbox-input,
  .custom-test-form .numTests {
    flex: 0 0 50%;
  }
  .custom-test-form .total {
    flex: 0 0 100%;
    margin-top: 20px;
  }
  .custom-test-form .total,
  .custom-test-form .numTests {
    text-align: right;
  }
  .custom-test p {
    position: absolute;
    top: 70px;
  }
  .custom-test small {
    display: block;
    margin-top: 25px;
  }
  .pricing-page .partner-logo {
    display: none;
  }
}

@media (min-width: 769px) {
  .acc-wrapper {
    /* grid-template-rows: 10rem; */
    gap: 3rem;
  }
  .faq-section .col-left .acc-item:last-of-type hr {
    display: none;
    /* margin-bottom: 1.5rem; */
  }
}

@media (max-width: 645px) {
  .detailed-info .custom-test p {
    top: 95px;
  }
  .pricing-summary {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 560px) {
  .custom-test p {
    top: 95px;
  }
  .detailed-info .custom-test .checkbox-input {
    flex: 0 0 70%;
    margin-bottom: 20px;
  }
  .detailed-info .custom-test .numTests,
  .detailed-info .custom-test .total {
    flex: 0 0 100%;
    text-align: center;
  }
  .detailed-info .custom-test .colored-p {
    top: 32px;
    right: 20px;
  }
  .detailed-info small {
    margin-left: 0;
  }
}

@media (max-width: 520px) {
  .detailed-info .custom-test .numTests {
    margin-top: 25px;
  }
  .pricing-page .flex-group {
    margin-bottom: 40px;
  }
}

@media (max-width: 460px) {
  .custom-test .checkbox-input {
    flex: 0 0 70%;
    margin-bottom: 10px;
  }
  .custom-test .numTests {
    flex: 0 0 60%;
    text-align: center;
  }
  .custom-test .total {
    flex: 0 0 40%;
    margin-top: 0;
  }
  .custom-test p {
    top: 35px;
    right: 20px;
  }
  .detailed-info .custom-test .total {
    margin-top: 15px;
  }
  .detailed-info .custom-test .checkbox-input {
    flex: 0 0 100%;
  }
  .detailed-info .custom-test .colored-p {
    top: 65px;
  }
  .detailed-info .upgrade-btn {
    float: none;
    display: block;
    margin-top: 10px;
  }
  .payment-done .payment-msg {
    width: 70%;
  }
  .payment-overview .subscription-plan {
    width: 90vw;
  }
  .payment-result .payment-msg {
    width: 90vw;
  }
}

@media (max-width: 440px) {
  .custom-test .checkbox-input {
    margin-bottom: 30px;
  }
}

@media (max-width: 420px) {
  .pricing-header .tag {
    float: none;
    display: inline-block;
    margin-bottom: 10px;
  }
  .custom-test .numTests,
  .custom-test .total {
    flex: 0 0 100%;
    text-align: center;
    margin-top: 10px;
  }
  .custom-test small {
    margin-left: 0;
    margin-top: 15px;
  }
  .pricing-summary .bill-overview {
    justify-content: space-around;
    width: 100%;
    gap: 0;
  }
  .pricing-header, .pricing-body {
    padding: 14px 19px;
  }
  .detailed-info .custom-test {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pricing-header h4 .big {
    font-size: 28px;
  }
  #account-setting .setting-card .pricing-header h4 {
    font-size: 18px;
  }
  .pricing-container {
    margin-top: 2rem;
  }
}

@media (max-width: 380px) {
  .payment-overview .flex-group button {
    width: 100%;
    margin-left: 0;
  }
  .pricing-summary {
    padding: 15px;
  }
}

@media (max-width: 364px) {
  .custom-test .checkbox-input {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
  .custom-test p {
    top: 60px;
  }
  .payment-done .payment-msg {
    padding: 20px;
  }
}

.footer-addon {
  height: 86px;
  background-color: #fff;
}
