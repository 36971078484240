.add-tag-btn{
    border: 1px solid #424B5433;
    background-color: #E5E5E5;
    padding: 5px 10px;
    font-size: 13px;
    margin-top: 50px;
    border-radius: 5px;
}

.hover-row{
    cursor: pointer;
}

.addition-css{
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.main-div-addition{
    height: 78vh; 
}

.column{
    font-size: 12px;
}

.job-mange-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.step-two-form small {
    position: absolute;
    top: 0;
    right: 0px 
}

.step-two-form .skill-field p {
    background-color: #D7ECEE;
    padding: 12px;
    border-radius: 8px;
    color: #174044;
    font-style: italic; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 10px;
}

.step-two-form .skill-field i {
    font-size: 20px;
    color: #ccc;
}

.step-two-form .skill-group {
    margin-top: 10px;
}

.step-two-form .skill-field .skill-detail {
    background-color: #e2eff2;
    padding: 8px 10px 10px;
    border-radius: 0 0 8px 8px;
    margin: -12px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.step-two-form .skill-field .skill-detail label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
}

.step-two-form .skill-field .skill-detail input[type="checkbox"] {
    visibility: visible;
    width: 10px;
    height: 10px;  
}

.skill-input-client {
    max-width: 120px !important;
    background-color: #fff !important;
    padding: 10px !important;
    height: 40px !important;
}

.job-rquirement-form form {
    margin-bottom: 0;
}

.job-rquirement .radio-btn-md {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    color: rgba(44, 56, 74, 0.95);
    margin-top: 20px;
}

.job-rquirement .radio-btn-md input[type="radio"] {
    accent-color: var(--theme-color);
}

.job-rquirement .radio-btn-md.active {
    border-color: var(--theme-color);
    color: var(--theme-color);
}