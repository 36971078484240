.ql-container .ql-editor {
  height: 200px !important;
  overflow: scroll;
  background: #ffffff !important;
}

.ql-editor {
  height: 200px !important;
  overflow: scroll;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #ffffff !important;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
