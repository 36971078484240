.subscription-plan .setting-card {
    margin: 20px 0;
}

/* summary */
.summary-child {
    border: 1px solid #e1e9fb;
    border-radius: 7px;
    padding: 20px;
    background-color: white;
    margin-bottom: 15px;
}
/* .summary-child:nth-of-type(2) {
    margin-top: -10px;
    border-radius: 0 0 7px 7px;
} */
#account-setting .setting-card .summary-child h2,
#account-setting .setting-card .summary-child h3 {
    font-weight: 600;
}
#account-setting .setting-card .summary-child .validity {
    font-size: 14px;
}
.summary-child .validity {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20%;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}
.payment-details {
    width: 100%;
    min-width: 900px;
    border-collapse: collapse;
}
.payment-details th {
    text-align: left;
    color: #646D84;
    font-weight: 500;
}
.payment-details thead th {
    padding-bottom: 20px;
}
.payment-details tbody td {
    border-bottom: 1px solid #C7D3DE;
    padding: 10px 0;
    color: #242c33;
}
.payment-details .invoice {
    padding-left: 15px;
}
.detailed-info .toggle-list .toggle-item {
    border-bottom: none;
}
.detailed-info .toggle-list .tabs {
    text-transform: uppercase;
}

.subscription-plan .nav {
    height: 45px;
}

/* Modal boxes */
/* two plan modal */
.two-plans-modal {
    padding: 20px;
}
.two-plans-modal .pricing-modal-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 35px;
}
.two-plans-modal .col-6 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.two-plans-modal .col-6 small {
    margin-top: 10px;
}
.two-plans-modal .card-wrapper {
    flex: 0 0 50%;
}
.detailed-info .client-searh-skill ul li {
    border: none;
    padding: 0 0 0 20px;
}
.detailed-info .client-searh-skill ul:not(.one-plan-modal ul) {
    display: block;
}
.detailed-info .pricing-container .card-wrapper:first-of-type {
    padding-left: 0;
}
.detailed-info .pricing-container .card-wrapper:last-of-type {
    padding-right: 0;
}

/* one plan modal */
.one-plan-modal .pricing-header {
    display: flex;
    justify-content: space-between;
    min-height: 0;
    border-bottom: none;
    padding: 0;
    margin-bottom: 20px;
}
.one-plan-modal .right-col {
    text-align: right;
}
.one-plan-modal .pricing-modal-top {
    justify-content: space-between;
    align-items: center;
}
.one-plan-modal .pricing-modal-top .col-3 {
    text-align: right;
}
.one-plan-modal .pricing-body ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.one-plan-modal .pricing-body ul li {
    margin-bottom: 0;
}
.one-plan-modal .pricing-body ul li:hover,
.two-plans-modal .pricing-body ul li:hover {
    box-shadow: none;
    border: none;
}
.one-plan-modal .pricing-body {
    padding: 0;
    margin: 20px 0 30px;
}
.one-plan-modal .custom-test {
    padding: 20px 20px 40px;
    margin-bottom: 30px;
}
.one-plan-modal .btn {
    float: right;
}
.one-plan-modal .btn.upgrade-plan {
    margin-top: 30px;
}

/* media queries */
@media screen and (max-width: 1053px) {
    .two-plans-modal .pricing-container.row {
        flex-direction: column;
    }
    .detailed-info .pricing-container .card-wrapper {
        padding: 0;
    }
    .one-plan-modal .pricing-header {
        flex-direction: column;
    }
    .one-plan-modal .right-col {
        text-align: right;
        margin: 10px 0;
    }
    /* .one-plan-modal ul {
        grid-template-columns: 1fr;
    } */
    .one-plan-modal .custom-test small {
        margin-left: 0;
    }
}

@media screen and (max-width: 980px) {
    .one-plan-modal .pricing-modal-top {
        display: flex;
    }
}

@media screen and (max-width: 805px) {
    .one-plan-modal .custom-test .checkbox-input {
        margin-bottom: 10px;
    }
    .one-plan-modal .custom-test .colored-p {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .one-plan-modal .numTests,
    .one-plan-modal .total {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 769px) {
    .one-plan-modal .custom-test .colored-p {
        position: static;
        margin-top: -50px;
    }
    .one-plan-modal .numTests {
        margin-bottom: 0px;
    }
    .one-plan-modal .pricing-modal-top * {
        flex: 0 0 50%;
    }
}

@media screen and (max-width: 570px) {
    .one-plan-modal .checkbox-input,
    .one-plan-modal .numTests {
        flex: 0 0 50%;
    }
}

@media screen and (max-width: 560px) {
    .one-plan-modal .custom-test .checkbox-input,
    .one-plan-modal .custom-test .numTests {
        flex: 0 0 50%;
    }
    .one-plan-modal .custom-test .numTests,
    .one-plan-modal .custom-test .total {
        text-align: right;
    }
}

@media screen and (max-width: 526px) {
    .one-plan-modal .custom-test .colored-p {
        margin-top: -20px;
    }
}

@media screen and (max-width: 520px) {
    .one-plan-modal .custom-test .colored-p {
        position: static;
        margin-top: -30px;
    }
}

@media screen and (max-width: 450px) {
    .one-plan-modal ul {
        grid-template-columns: 1fr;
    }
    .one-plan-modal .custom-test .checkbox-input,
    .one-plan-modal .custom-test .numTests,
    .one-plan-modal .custom-test .total {
        flex: 0 0 100%;
    }
    .one-plan-modal .custom-test .numTests,
    .one-plan-modal .custom-test .total {
        text-align: center;
    }
    .one-plan-modal .custom-test .checkbox-input {
        /* margin-top: 15px; */
        margin-bottom: 25px;
    }
    .one-plan-modal .custom-test .colored-p {
        position: absolute;
        top: 80px;
        right: 20px;
    }
    .one-plan-modal .pricing-modal-top * {
        flex: 0 0 100%;
    }
    .one-plan-modal .pricing-modal-top .col-3 {
        margin-top: 30px;
        text-align: left;
    }
    .summary-child .validity {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (max-width: 360px) {
    .subscription-plan .toggle-list .tabs .nav li a {
        font-size: 13px;
    }
    .subscription-plan .nav {
        height: 43px;
    }
}