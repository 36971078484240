.home-banner.banner-bg{
    /* background: url('../../assets/img/home-banner-bg.png') no-repeat left top; */
    background: url('https://ik.imagekit.io/olibr/assets/tr:w-1600,h-408/home-banner-bg.png') no-repeat left top;
    background-size: cover;
    margin-bottom: 50px;   
}

/* Job */
.jobs{
    display:block;
    padding:0 20px;
}
.jobs__title{
    display: flex;
    justify-content: space-between;
}
.jobs__title .link-right{
    margin-right:0px;
    font-size:16px;
}
.jobs__sub-title{
    margin-bottom: 30px;
    /* font-family: "Poppins-Light"; */
    font-weight: 300;
}
.job-cards{
    display:flex;
    flex-wrap: wrap;
    margin:0 -8px;
}
.job-card{
    flex:0 0 25%;
    max-width:calc(100% - 16px);
    background-color: #F0F5F6;
    border:1px solid #E8F1FB;
    border-radius:4px;
    padding:20px 20px 40px;
    margin: 8px;
    position: relative;
}
.job-card:hover{
    cursor: pointer;
    transition: all .3s ease-in-out;
    background-color: #E8F1FB;
    -webkit-box-shadow: 3px 6px 20px -6px rgba(226,236,238,1);
-moz-box-shadow: 3px 6px 20px -6px rgba(226,236,238,1);
box-shadow: 3px 6px 20px -6px rgba(226,236,238,1);
transition: all .3s ease-in-out;
}
.job-card h4{
    font-size: 14px;
    /* font-family: 'Poppins-Medium', sans-serif; */
    /* font-weight: normal; */
    font-weight: 500;
    margin-bottom: 10px;
}
.job-card div{
    margin-bottom: 15px;
}
.job-card .btn-block{
    /* position:absolute; */
    margin:0 -20px -40px;
    bottom: 0%;
    width:calc(100% + 40px);
}
.job-card .year,
.job-card .company-name{
    display:flex;
    align-items: center;
}
.job-card .year img,
.job-card .company-name img{
    margin-right:8px;    
}

@media(max-width:768px){
    .jobs{
        display:block;
        padding:0 20px;
    }
    .job-cards{
        flex-direction: column;
        margin:0;
    }
    .job-cards .job-card{
        flex:0 0 auto;
        max-width:100%;
        margin:10px 0;
    }
    .job-cards .job-card .btn{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .jobs__title{
        font-size:26px;
        flex-direction: column;
        margin-bottom:20px;
    }
    .jobs__title .link-right{
        display:flex;
        justify-content: flex-end;
    }
    .jobs .container{
        padding:0;
    }
}