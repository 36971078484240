.accordion-dashboard{
    /* background-color:#F0F5F6; */
    border:1px solid #D3DCE5;    
    display:block;
    width:100%;
    border-radius: 5px;
}

.landing-wrap{
    display:block;
    padding:0 15px;
}
.landing-banner{
    /* background: url(../../../../assets/img/Landing-banner-bg.webp) no-repeat center center; */
    display:block;
    padding:40px 90px 40px 40px;
    border:1px solid rgba(211, 220, 229, 0.5);
    border-radius: 16px;
    background-size: cover;
    text-align: center;
    color:#242C33;
    margin: 20px 0px 30px 0px;
}
.landing-banner h4{
    display:block;
    text-align: center;
    font-size: 21px;
    line-height: 28px;
    max-width: 67%;
    margin: 0 auto;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    color:#271A3E;
    padding-top: 20px;
}
.landing-banner h4 span{
    color:#271A3E;
    text-transform: uppercase;
    font-size: 39px;
    /* font-family: "Poppins-SemiBold"; */
    font-weight: 600;
}

.step-lists{
    display:flex;
    flex-direction: column;
}
.step-list p, .offer-banner p{
    /* font-family: "Poppins-Regular"; */
    font-weight: 400;
    font-size: 18px;
    color: #242C33;
}
#dashboard-adj {
 padding: 0;
}
#dashboard-adj .jobs__title, #dashboard-adj .faq__title{
    font-size: 24px;
}
#dashboard-adj .faq__text{
    padding-top: 20px;
}

.offer-banner,
.step-list{
    display:flex;
    border:1px solid #E9ECFF;
    padding:15px;
    flex-direction: row-reverse;
    align-items: center;
    border-radius:16px;
    margin-bottom: 20px;
    background: #F9FAFF;
    border-bottom: 4px solid #E9ECFF;
    background-image: linear-gradient(to top, #f6f8ff, #f7f9ff, #f9f9ff, #fafaff, #fbfbff);
}
.offer-banner figure,
.step-list figure{
    display:flex;
    flex:0 0 36%;
    max-width:36%;
    padding:15px;
}
.offer-banner figure img,
.step-list figure img{
    display:block;
    width:100%;
}
.step-list .step-detail{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    padding:15px 15px 15px 55px;
    font-size:16px;
    line-height: 24px;   
}
.step-list .step-detail h5{
    font-size:24px;
    line-height: normal;
    color:#242C33;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
}
.number {
    background-color: #33ACC1;
    color: #fff;
    height: 36px;
    width: 36px;
    display: block;
    border-radius: 50%;
    position: relative;
    font-size: 18px;
    text-align: center;
    line-height: 36px;
    margin-bottom: 10px;
    box-shadow: 0px 11px 7px -3px rgb(51 172 193 / 26%);
-webkit-box-shadow: 0px 11px 7px -3px rgb(51 172 193 / 26%);
-moz-box-shadow: 0px 11px 7px -3px rgb(51 172 193 / 26%);
}
.step-list .step-detail .btn-block{
    margin:0;
    justify-content: flex-start;
}
.step-list .step-detail .btn-block .btn{
    padding:6px 40px;
}
.step-lists .step-list:nth-child(even){
    flex-direction: row;
}
.offer-banner{
    padding:15px 15px 15px 30px;
}
.offer-banner .offer-title{
    color:#242C33;
    font-size:24px;
    line-height: normal;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
}
#dashboard-adj .job-card h4{
    height: 42px;
    overflow: hidden;
}
#dashboard-adj .job-card p{
    height: 80px;
    overflow: hidden;
    font-size: 12px;
}
#dashboard-adj  .tags {
    height: 88px;
    overflow: hidden;
}
#dashboard-adj .job-card .year{
    font-size: 14px;
}
#skill-badge{
    margin-bottom: 20px;
}
#skill-badge .skill-card{
    border: 1px solid #EBEFFA;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    -webkit-box-shadow: 7px 6px 4px -3px rgba(228,239,251,1);
    -moz-box-shadow: 7px 6px 4px -3px rgba(228,239,251,1);
    box-shadow: 7px 6px 4px -3px rgba(228,239,251,1);
    background: #fff;
    text-align: center;
    display: inline-block;
    width: calc(25% - 15px);
    margin-bottom: 15px;
    margin-right: 15px;
}

#skill-badge .skill-card figure{
    display: block;
    text-align: center;
    padding-bottom: 5px;
    height: 32px;
    overflow: hidden;
}
#skill-badge .skill-card figure img{
    width: 32px;
}
#skill-badge .skill-card p{
    display: block;
    text-align: center;
    color: #242C33;
    font-size: 12px;
    /* font-family: "Poppins-Regular"; */
    font-weight: 400;
    height: 40px;
    overflow: hidden;
    line-height: 18px;
    margin:0;
    padding: 0px 0px 5px 0px;
}
#skill-badge .skill-card a{
    text-transform: uppercase;
    font-size: 10px;
    /* font-family: "Poppins-Regular"; */
    font-weight: 400;
    padding: 5px 15px;
    background: #3BA0AA;
    -webkit-box-shadow: 1px -1px 30px 8px rgba(59,160,170,0.2);
-moz-box-shadow: 1px -1px 30px 8px rgba(59,160,170,0.2);
box-shadow: 1px -1px 30px 8px rgba(59,160,170,0.2);
    border-radius: 12px;
    color: #fff;
}

@media(max-width:768px){
    #dashboard-adj .job-card h4, #dashboard-adj .job-card p, #dashboard-adj  .tags, #dashboard-adj .job-card .year{
        overflow: auto;
        height: auto;
    }
    .landing-banner{
        padding:20px;
    }
    .landing-banner h4{
        max-width: 100%;
        font-size:24px;
    }
    .offer-banner, .step-list{
        flex-direction: column;
    }
    .offer-banner figure, .step-list figure{
        flex:0 0 100%;
        max-width: 100%;
    }
    .step-lists .step-list:nth-child(even){
        flex-direction: column;
    }
    #skill-badge .skill-card{
        width: 100%;
        margin-right: 0;
    }
}