.inner-share-btn {
    background-color: #fff;
    border: 1px dashed #e2e2e2;
    padding: 5px 15px;
    display: inline-block;
}
.main-share-btn{
    margin-top: 10px;
}
.main-share-btn:hover{
    cursor: pointer;
}
.main-share-btn .share-text{
    font-size: 14px;
    /* font-family: "Poppins-Semibold"; */
    font-weight: 600;
    color: #3ba0aa;
    letter-spacing: 1px;
}
.main-share-btn .share-profile-icon{
    margin-right: 5px;
    position: relative;
    top: 3px;
}