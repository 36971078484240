.photoheading{
    color: #041213;
    width: 100%;
    display: block;
    padding: 30px 0px;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
  }
  .pad-bottom-20{
    padding-bottom: 20px;
  }
  p{
     color: #222626; 
     font-size: 16px;
     line-height: 26px;
     /* font-family: "Poppins-Light"; */
     font-weight: 300;
  }
  .feature-info{
      display: block;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      background-color: #F0F5F6;
      margin-bottom: 20px;
  }
  .feature-info h1, .pro-tips{
      display: block;
      width: 100%;
      color: #041213;
      font-size: 20px;
      /* font-family: "Poppins-Regular"; */
      font-weight: 400;
      padding-bottom: 5px;
      margin: 0;
  }
  .feature-info p{
      display: block;
      width: 100%;
      color: #222626;
      font-size: 16px;
      /* font-family: "Poppins-Light"; */
      font-weight: 300;
      line-height: 26px;
      margin: 0;
      padding: 0;
  }
  .photo-block{
      margin-bottom: 40px;
  }
  .photo-block, .photo-block h4{
      width: 100%;
      display: block;
      text-align: center;
      color: #041213;
      line-height: 26px;
      /* font-family: "Poppins-Regular"; */
      font-weight: 400;
      font-size: 18px;
      text-transform: capitalize;
  }
  .photo-block p{
      color: #222626;
      font-size: 14px;
      /* font-family: "Poppins-Light"; */
      font-weight: 300;
      line-height: 20px;
  }
