#root {
    display: block !important;
    min-height: auto !important;
}

.company {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0;
    width: 100%;
    flex-direction: column;

}

.company-header {
    display: flex;
    background-color: #fff;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 5px 0;
}

.company-header .company-logo {
    flex: 0 0 auto;
    max-width: 200px;
    margin: 10px 0px 0px 20px;
}

.company-header .company-nav-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.company-header .menu {
    display: flex;
}

.company-header .menu-item {
    display: flex;
}

.company-header .menu-item a {
    display: block;
    padding: 10px;
    color: #444644;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    font-size: 16px;
    /* font-weight: normal; */
    line-height: 24px;
    text-transform: capitalize;
    transition: all .5s ease-in-out;
}

.company-header .menu-item a:hover,
.company-header .menu-item a.current {
    color: #3BA0AA;
    transition: all .5s ease-in-out;
}

@media(max-width:768px) {
    .company-header {
        padding: 5px 15px;
        flex-direction: column;
        justify-content: center;
    }

    .company-header .company-logo {
        margin: 0 auto;
    }

    .company-header .company-nav-right {
        justify-content: center;
    }

    .company-header .menu-item a {
        font-size: 12px;
    }
}

/* Company Banner */
.banner-section {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 100px
}

.company-banner {
    display: flex;
    position: relative;
    width: 100%;
}

.company-banner h2 {
    /* font-family: "Poppins-SemiBold"; */
    /* font-weight: normal; */
    font-weight: 600;
    padding: 110px 50px 0px 50px;
    font-size: 36px;
    line-height: 52px;
    color: #fff;
    position: relative;
    z-index: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    overflow: hidden;

}

.company-banner img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
}

@media(max-width:768px) {
    .company-banner {
        flex-direction: column;
        padding: 50px 10%;
    }

    .company-banner h2 {
        font-size: 20px;
        line-height: 36px;
    }

    .company-banner img {
        height: 100%;
    }
}

/* About Us */
.about-section {
    display: flex;
    position: relative;
    width: 100%;
    /* margin-bottom: 50px; */
    margin-top: 30px;
    justify-content: flex-end;
    padding: 0px;
}

.about-section .about-banner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-section .about-banner img {
    width: 90%;
    height:auto;
}

.about-section .about-content {
    position: relative; 
    display: flex;
    width: 100%;
    right: 0;
    padding: 0;
    margin: 50px 0;
    border-radius: 12px;
    flex-direction: column;
    /* font-family: "Poppins-Regular"; */
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
}

.about-section .about-content:before,
.about-section .about-content:after {
    position: absolute;
    content: '';
    background: url('./../../assets/img/company-profile/dots-icons.png') no-repeat right top;
    background-size: 50px 32px;
    width: 50px;
    height: 32px;
    right: 0;
}

.about-section .about-content:before {
    top: -50px;
}

.about-section .about-content:after {
    bottom: -50px;
}

.company .about-content h2 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
}

.company h2 .section-category {
    color: #768594;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    font-size: 16px;
    /* font-weight: normal; */
    line-height: 24px;
    text-transform: uppercase;
    margin-top:20px
}

.company h2 .section-title {
    justify-content: flex-start;
    /* font-family: "Poppins-Medium"; */
    font-size: 30px;
    /* font-weight: normal; */
    font-weight: 500;
    line-height: 40px;
    text-transform: capitalize;
    color: #000;
    margin: 0;
}

@media(max-width:768px) {
    .about-section {
        flex-direction: column;
        padding: 40px 20px;
        margin-bottom: 0;
    }

    .about-section .about-content:before {
        display: none;
    }

    .company h2 .section-title {
        font-size: 20px;
        line-height: 36px;
    }

    .company h2 .section-category {
        font-size: 14px;
    }
    .about-section .about-content{
        margin:0;
    }
}

/* Our Gallery */
.section-gallery {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    justify-content: flex-end;
    /* padding: 50px 150px; */
}

.section-gallery h2 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 35px;
    text-align: center;
}

.section-gallery h2 .section-title {
    justify-content: center;
}

.gallery ul {
    display: grid;
    grid-gap: 20px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 3fr 1fr 1fr;
}

.gallery ul .large-image {
    grid-row-start: 1;
    grid-row-end: 3;
}

.gallery ul .large-image img {
    object-fit: cover;
    width: 100%;
}

.gallery ul .img-item img {
    object-fit: cover;
    height: 100%;
}

.gallery ul .img-item img:hover {
    box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 1);
    transition: all 0.3s ease-in-out;
}

@media(max-width:768px) {
    .section-gallery {
        padding: 20px;
        margin-bottom: 20px;
    }

    .gallery ul {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 190px 1fr 1fr;
        grid-gap: 10px;
    }

    .gallery ul .img-item.large-image {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    /* .gallery ul .img-item {} */
}

/* Perks & benifits */
.section-benifits {
    background-color: #F3FDFF;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
    padding: 50px;
    flex-direction: column;
}

.section-benifits h2 {
    text-align: center;
    margin-bottom: 35px;
}

.section-benifits .section-title {
    justify-content: center !important;
}

.benifits ul {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    justify-content: center;
}

.benifits ul li {
    background-color: #E8F1FB;
    padding: 12px;
    flex: 1 0 20%;
    max-width: calc(20% - 15px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #E8F1FB;
    transition: all .5s ease-in-out;
}

.benifits ul li:hover {
    border: 2px solid #3BA0AA;
    transition: all .5s ease-in-out;
}

.benifits ul li.selected {
    background: #C9D9EB;
    transition: all .5s ease-in-out;
}

.benifits ul li figure {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.benifits ul li figure img {
    width: auto;
    height: 50px;
    margin-top: 10px
}

.benifits ul li h4 {
    text-align: center;
    font-size: 16px;
}

@media(max-width:768px) {
    .section-benifits {
        padding: 20px;
    }

    .benifits ul li {
        flex: 1 0 50%;
        max-width: calc(50% - 15px);
    }

    .benifits ul li figure img {
        height: 50px;
    }

    .benifits ul li h4 {
        font-size: 14px;
    }
}

/* Members */
.members-section {
    background-color: #fff;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
    padding: 50px;
    flex-direction: column;
}

.members-section h2, .award-recognitions h2 {
    margin-bottom: 35px;
    text-align: center;
}

.members-section h2 .section-title, .award-recognitions h2 .section-title {
    justify-content: center;
}

.member-list ul li img {
    height: 300px;
    width: 300px;
}

.members-section .member-list ul {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    width: 100%;
    justify-content: center;
}

.members-section .member-list ul li {
    flex: 1 0 25%;
    max-width: calc(25% - 15px);
    text-align: center;
    border: 1px solid #D5E1ED;
    border-bottom: 2px solid #D5E1ED;
    font-size: 14px;
    /* font-family: "Poppins-Regular"; */
    font-weight: 400;
    color: #858788;
    transition: all 0.3s ease-in-out;
}

.members-section .member-list ul li:hover {
    box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 1);
    transition: all 0.3s ease-in-out;
}

.members-section .member-list ul li figure {
    margin-bottom: 10px;
}

.members-section .member-list ul li h4 {
    color: #000;
    margin-bottom: 5px;
}

@media(max-width:768px) {
    .members-section {
        padding: 20px;
        margin-bottom: 20px;
    }

    .members-section .member-list ul li {
        flex: 1 0 50%;
        max-width: calc(50% - 8px);
    }

    .members-section .member-list ul li h4 {
        font-size: 16px;
        padding: 0 10px;
        line-height: 18px;
    }

    .members-section .member-list ul li p {
        padding: 0 10px;
        line-height: 18px;
    }
}

/* Hiring */
.hiring {
    background-color: #F3FDFF;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
    padding: 50px;
    flex-direction: column;
}

.hiring h2 {
    margin-bottom: 45px;
    text-align: center;
}

.hiring h2 .section-title {
    justify-content: center;
}

.company-profile {
    display: flex;
    position: relative;
    flex-basis: 100%;
}

.job-positions {
    display: flex;
    background-color: #fff;
    padding: 30px 35px;
    border: 1px solid #E0EAF5;
    position: relative;
    flex-basis: 100%;
    margin-bottom: 50px;
    transition: all 0.3s ease-in-out;
    flex-wrap:wrap;
}

.job-positions:hover {
    box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 1);
    transition: all 0.3s ease-in-out;
}

.job-positions:last-child {
    margin-bottom: 0;
}

.job-positions .company-brand-logo {
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 11px 19px -6px rgba(183, 190, 201, .53);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    left: 20px;
    line-height: 50px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: -23px;
    width: 50px;
    padding: 5px;
}

.job-positions .company-brand-logo img {
    max-width: 100%;
}

.profile-deatil {
    flex: 1;
}

.profile-deatil h3 {
    font-size: 16px;
}

.company-profile-list {
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.company-profile-list li {
    padding: 5px 10px 5px 30px;
    position: relative;
    font-size: 14px;
}

.company-profile-list li:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3BA0AA;
    left: 15px;
    top: 12px;
    position: absolute;
}

.company-profile-list li .breafcase {
    position: relative;
}

.company-profile-list li .breafcase:before {
    position: absolute;
    left: -20px;
    top: 2px;
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background: url('./../../assets/img/company-profile/suitcase.svg') no-repeat left top;
}

.company-profile-list li:first-child::before {
    display: none;
}

.tech-list {
    display: flex;
    grid-gap: 15px;
    flex-wrap: wrap;
}

.tech-list li {
    background-color: #EDF5FD;
    padding: 7px 20px;
    display: flex;
    justify-content: center;
    border-radius: 60px;
    border: 1px solid #E0EAF5;
    font-size: 12px;
}

.tech-list li:hover,
.tech-list li.selected {
    border: 1px solid #3BA0AA;
}

.company-profile .info {
    display: flex;
    padding: 10px;
    flex-basis: 130px;
}

.company-profile .info a {
    border: 1px solid #cad9ea;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    color: #9196b7;
    font-size: 20px;
}

.company-profile .info a+a {
    margin-left: 15px;
}

.company-profile .info-right {
    display: flex;
    padding: 10px;
    flex-basis: 160px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.company-profile .info-right .dropdown,
.company-profile .info-right .dropdown-active {
    margin-bottom: 20px;
    border: 1px solid #C9D3EC;
    padding: 7px 80px 7px 15px;
    border-radius: 8px;
    position: relative;
    width: 180px;
}

.company-profile .info-right .dropdown:after,
.company-profile .info-right .dropdown-active:after {
    display: block;
    content: '';
    width: 24px;
    height: 100%;
    background: url('./../../assets/img/company-profile/drop-arrow.svg') no-repeat center center;
    background-size: 12px 12px;
    position: absolute;
    top: 2px;
    right: 9px;
}

.company-profile .info-right .dropdown>a,
.company-profile .info-right .dropdown-active>a {
    font-size: 12px;
    /* font-family: "Poppins-Regular"; */
    font-weight: 400;
    color: black;
}

.company-profile .info-right .dropdown-content .popupList {
    font-size: 12px;
    /* font-family: "Poppins-Regular"; */
    font-weight: normal;
    border-radius: 4px;
}

.company-profile .info-right .dropdown-content .popupList li {
    border-left: 4px solid transparent;
    display: flex;
}

.company-profile .info-right .dropdown-content .popupList li:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.company-profile .info-right .dropdown-content .popupList li:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.company-profile .info-right .dropdown-content .popupList li span {
    flex: 1;
}

.company-profile .info-right .dropdown-content .popupList li img {
    width: 12px;
    height: 12px;
}

.company-profile .info-right .dropdown-content .popupList li:hover {
    border-radius: 0;
    color: black;
    background-color: #D7ECEE;
}

.company-profile .info-right .dropdown-content .popupList .selected-bg{
    background-color: #D7ECEE;
}

.company-profile .info-right .applicants {
    display: flex;
    /* font-family: "Poppins-Bold"; */
    font-weight: 700;
    font-size: 24px;
    /* font-weight: normal; */
    line-height: 28px;
    color: #3BA0AA;
}

.company-profile .info-right .applicants [data-tooltip]:not([data-flow])::after {
    left: 50%;
    transform: translate(-50%);
    font-size: 12px;
    /* font-family: "Poppins-Regular"; */
    font-weight: 400;
}

.company-profile .info-right .dropdown-active .dropdown-content {
    padding: 0;
    border: 1px solid #C9D3EC;
    margin-top: 10px;
    width: 178px;
    border-radius: 5px;
}

@media(max-width:768px) {
    .hiring {
        padding: 20px;
    }

    .job-positions {
        padding: 30px 20px 20px;
    }

    .profile-deatil h3 {
        font-size: 16px;
    }

    .company-profile .info a {
        width: 34px;
        height: 34px;
    }

    .company-profile .info a+a {
        margin-left: 10px;
    }

    .tech-list li {
        padding: 7px 15px;
    }

    .company-profile .info {
        position: absolute;
        top: -20px;
        right: -15px;
    }

    .profile-deatil {
        padding-top: 42px;
    }
}

/* Contact Us */
.company-contact {
    background-color: #fff;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
    padding: 50px;
    flex-direction: column;
}

.company-contact h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}

.company-contact h2 .link {
    font-size: 16px;
}

.contact-details .social-links {
    display: flex;
    width: 100%;
    justify-content: center;
    grid-gap: 15px;
}

.contact-details {
    display: flex;
    flex-direction: column;
}

.contact-details .contacts {
    display: flex;
    flex-basis: 100%;
    margin-bottom: 50px;
}

.contact-details .contact {
    flex: 1;
    display: flex;
}

.contact-details .contact figure {
    flex: 0 0 60px;
    max-width: 60px;
    max-height: 60px;
    padding: 14px;
    background-color: #EEF4F8;
    border: 1px solid #CFEAFC;
    display: flex;
}

.contact-details .contact figure img {
    flex: 0 0 32px;
    max-width: 32px;
}

.contact-details .contact .contact-detail {
    padding: 0 0 0 15px;
    display: flex;
    flex-direction: column;
    /* font-family: "Poppins-Regular"; */
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    flex: 1;
}

.contact-details .contact .contact-detail h4 {
    /* font-family: "Poppins-Medium"; */
    /* font-weight: normal; */
    font-weight: 500;
    margin-bottom: 5px
}

.contact-details .contact .contact-detail a {
    color: #000;
}

.contact-details .contact address {
    font-style: normal;
}

@media(max-width:768px) {
    .company-contact {
        padding: 20px;
        margin-bottom: 20px;
    }

    .contact-details .contacts {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .contact-details .contact {
        margin-bottom: 20px;
    }
}

.hr {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #E1E9FB;
    margin: 30px 0;
    border: none;
}

.form-title {
    /* font-family: "Poppins-Medium"; */
    /* font-weight: normal; */
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: block;
    margin-bottom: 15px;
    text-transform: uppercase;
    position: relative;
}

.company-profile .form-group {
    /* font-family: "Poppins-Regular"; */
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    flex-direction: column;
}

.company-profile .form-group label {
    margin-bottom: 5px;
    display: inline-block;
}

.company-profile .form-group label i.required {
    color: #ff0000;
}

.add-team {
    display: flex;
    position: relative;
}

.add-team .actions {
    flex: 60px;
    display: flex;
    justify-content: flex-end;
}

.plus-icon,
.minus-icon {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #F15D3B;
    color: #fff;
    font-size: 20px;
    /* font-family: "Poppins-Bold"; */
    font-weight: 700;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20px;
}

.plus-icon {
    background-color: #3BA0AA;
    position: absolute;
    right: 0;
    top: 0px;
}

.add-img-block {
    display: flex;
}

.add-img-block .add-img {
    border: 1px solid #242C33;
    border-radius: 8px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #242C33;
}

.add-img-block .img-list {
    display: flex;
    grid-gap: 15px;
    flex-wrap: wrap;
    width: 100%;
}

.add-img-block .img-list li {
    flex: 1 0 14.2857%;
    max-width: calc(14.2857% - 15px);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.add-img-block .img-list .delete {
    position: absolute;
}

.add-img-block .img-list img {
    height: 100%;
    object-fit: cover;
}

@media(max-width:768px) {
    .add-img-block .img-list li {
        flex: 1 0 50%;
        max-width: calc(50% - 15px);
    }
}

/*  */
.services-lists {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    grid-gap: 10px;
    margin-bottom: 10px;
}

.services-lists li {
    display: block
}

.services-lists li .icon-round {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3BA0AA;
    border-radius: 50%;
    transition: all .5s ease-in-out;
    padding: 6px;
}

.services-lists li .icon-round:hover {
    background-color: #505564;
    transition: all .5s ease-in-out;
}

.services-lists li .icon-round img {
    max-width: 100%;
    filter: brightness(0) invert(1);
}


[data-tooltip] {
    position: relative;
    cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
    line-height: 1;
    font-size: 12px;
    pointer-events: none;
    position: absolute;
    box-sizing: border-box;
    display: none;
    opacity: 0;
}

[data-tooltip]:before {
    content: "";
    border: 5px solid transparent;
    z-index: 100;
}

[data-tooltip]:after {
    content: attr(data-tooltip);
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 15px;
    border-radius: 12px;
    background: #6D6F6D;
    color: #FFFFFF;
    z-index: 99;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    opacity: 1;
}

[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow="top"]::before {
    bottom: 97%;
    border-bottom-width: 0;
    border-top-color: #6D6F6D;
}

[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::after {
    bottom: calc(100% + 8px);
}

[data-tooltip]:not([data-flow])::before,
[tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::before,
[data-tooltip][data-flow="top"]::after {
    left: 50%;
    -webkit-transform: translate(-50%, -8px);
    transform: translate(-50%, -8px);
}

.job-listing {
    padding-top: 20px;
}

.linkedin-circle,
.enquire-circle {
    position: relative;
    display: block;
    z-index: 1;
}

.linkedin-circle a img,
.enquire-circle span img {
    width: 24px!important;
    height: 24px!important;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
}



@media(max-width:768px){
    .company-profile{
        flex-direction: column;
    }
    .company-banner h2{
        padding: 0;
    }
    
}
@media(max-width:580px){
    .benifits ul{
        flex-direction: column;
    }
    .benifits ul li{
        flex-basis: 100%;
        max-width: 100%;
    }
}