.tooltip {
        position: relative;
        display: inline-block;
}
 .tooltip .tooltiptext {
        visibility: hidden;
        max-width: 420px;
        min-width: 120px;
        background-color: black;
        word-wrap: break-word;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 3px;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -60px;
}
/* .tooltip .tooltiptext::after {
    -    content: "";
    -    position: absolute;
    -    top: 100%;
    -    left: 50%;
    -    margin-left: -5px;
    -    border-width: 5px;
    -    border-style: solid;
    -    border-color: black transparent transparent transparent;
    -  } */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }