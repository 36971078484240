.pointer {
  cursor: pointer;
}
.theme-color-txt {
  color: #1371f0 !important;
}
.auto-container {
  position: static;
  padding: 0 15px;
  margin: 0 auto;
}
.flex-end {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.margin-top-30 {
    margin-top: 30px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.candidate-detail-section .upper-box {
  position: relative;
  background-image: url(../../assets/img/bg-4.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  padding: 80px 0 30px;
}
.olibr-logo {
  position: absolute;
  top: 20px;
  right: 15px;
}
.candidate-block-five {
  position: relative;
  margin-bottom: 50px;
}
.candidate-block-five .inner-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  transition: all 0.3s ease;
}
.candidate-block-five .inner-box .content {
  position: relative;
  padding-left: 180px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.candidate-block-five .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 20px;
  outline: 2px solid #e8ebf7;
}
.video-box .image img {
  width: 100%;
  height: 150px;
}
.video-box .image .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  line-height: 36px;
  text-align: center;
  color: #fff;
  font-size: 30px;
  transform: translate(-50%, -50%);
  opacity: 0.8;
}
.candidate-block-five .name {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
}
.profile-preview .upper-box p {
  font-size: 16px;
}
.profile-preview .share-icon {
  margin-left: 6px;
  color: #1371f0;
}
.candidate-block-five .candidate-info {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.candidate-block-five .candidate-info li {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  color: dimgray;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 30px;
}
.candidate-block-five .candidate-info li:first-of-type {
  padding-left: 15px;
}
.candidate-block-five .candidate-info li .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  line-height: 22px;
  color: dimgray;
}
.candidate-detail-outer {
  padding: 50px 0;
}
.job-detail {
  position: relative;
  padding-right: 30px;
  text-align: justify;
}
.sidebar-widget {
  padding: 30px;
  background: #f5f7fc;
  border-radius: 8px;
  margin-bottom: 30px;
}
.sidebar-widget .widget-title {
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 10px;
}
.chip-array {
  margin: -4px 0 0 -4px;
  display: flex;
  flex-wrap: wrap;
  max-height: 76px;
  overflow: hidden;
}
.job-detail h4 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 20px;
}
.job-detail p {
  position: relative;
  font-size: 15px;
  line-height: 26px;
  color: dimgray;
  margin-bottom: 26px;
}
.resume-outer {
  position: relative;
  margin-bottom: 10px;
}
.resume-outer .upper-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.resume-block {
  position: relative;
}
.resume-block .inner {
  position: relative;
  padding-left: 60px;
  /* z-index: unset; */
  padding-bottom: 30px;
}
.resume-block .inner:before,
.theme-blue .resume-block .inner:before {
  position: absolute;
  left: 14px;
  top: 5px;
  bottom: 0;
  /* z-index: -1; */
  border-left: 2px dashed rgba(217, 48, 37, 0.15);
  content: "";
}
.resume-block .name {
  position: absolute;
  left: 0;
  top: 5px;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  background: #f9e0df;
  color: #d93025;
  z-index: 2;
  text-transform: uppercase;
}
.resume-outer.theme-blue .resume-block .name {
  background: #eff4fc;
  color: #1967d2;
}
.resume-block .title-box {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}
.resume-block .info-box {
  position: relative;
  margin-right: 20px;
}
.resume-block .edit-box {
  display: flex;
  align-items: center;
}
.resume-block .info-box h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin: 0;
}
.resume-block .info-box span {
  font-size: 16px;
  line-height: 22px;
  color: #d93025;
}
.resume-outer.theme-blue .resume-block .info-box span {
  color: #1967d2;
}
.resume-block .edit-box .year {
  position: relative;
  font-size: 13px;
  padding: 5px 20px;
  line-height: 15px;
  font-weight: 500;
  border-radius: 20px;
  background: rgba(217, 48, 37, 0.15);
  color: #d93025;
}
.resume-outer.theme-blue .resume-block .edit-box .year {
  background: #eff4fc;
  color: #1967d2;
}
.resume-block .tech-stack {
  display: grid;
  grid-template-columns: 100px 1fr;
  margin-bottom: 10px;
}
.resume-block .tech-stack h4 {
  font-size: 14px;
  margin-bottom: 0;
}
.resume-block .text {
  font-size: 15px;
  color: dimgray;
  /* line-height: 26px; */
  font-weight: 400;
  margin: 0;
  word-wrap: break-word;
  text-align: justify;
}
.list-style-two {
  position: relative;
}
.work-desc ul {
  display: inline;
}
.list-style-two li {
  position: relative;
  font-size: 15px;
  line-height: 24px;
  color: dimgray;
  cursor: default;
  padding-left: 22px;
  margin-bottom: 10px;
}
.list-style-two li:before {
  position: absolute;
  left: 0;
  top: 6px;
  width: 8px;
  height: 8px;
  background: #c4c4c4;
  border-radius: 50%;
  content: "";
}
.profile-preview .role-related-projects,
.profile-preview .additional-projects {
  display: flex;
  flex-wrap: wrap;
}
.profile-preview .role-related-projects .candidate-block,
.profile-preview .additional-projects .candidate-block {
  flex: 0 0 50%;
  padding: 0 15px 0 0;
  position: relative;
  margin-bottom: 10px;
}
.project-cards .inner-box {
  position: relative;
  padding: 20px;
  background: #fff;
  border: 1px solid #ecedf2;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all 0.3s ease;
  max-height: 300px;
  text-align: left;
}
.project-cards h4 {
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
  line-height: 20px;
  font-weight: 500;
  text-transform: capitalize;
}
.project-cards p {
  color: dimgray;
  line-height: 26px;
  text-align: left;
}
.project-cards .designation {
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: capitalize;
}
.project-cards .project-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 77px;
}
.project-cards .project-tags {
  height: 30px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
.job-other-info li {
  position: relative;
  font-size: 13px;
  line-height: 15px;
  margin-right: 15px;
  padding: 5px 20px;
  border-radius: 50px;
  margin-bottom: 10px;
}
.job-other-info li.time {
  background: rgba(25, 103, 210, 0.15);
  color: #1967d2;
}
.project-cards .btn-title {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;
  display: inline-block;
}
.resume-outer .upper-title h4,
.profile-preview .reviews-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202124;
  margin-bottom: 15px;
}
.resume-outer .upper-title .ach-name {
  margin-bottom: 10px;
}
.resume-outer.theme-yellow .resume-block .edit-box .year,
.resume-outer.theme-yellow .resume-block .name {
  background: #fef2d9;
  color: #f9ab00;
}
.resume-outer.theme-yellow .resume-block .info-box span {
  color: #f9ab00;
}
.resume-outer {
  position: relative;
  margin-bottom: 40px;
}
.profile-preview .achievements {
  padding-left: 0;
}
.profile-preview .achievements::before {
  position: unset;
  border-left: none;
}
.resume-block:last-child .inner {
  padding-bottom: 0;
}
.resume-reviews {
  width: 100%;
  padding: 0 12px;
}
.profile-preview .resume-reviews .slick-slider .slick-track {
  width: max-content !important;
}
.testimonial-block {
  position: relative;
  margin-right: 20px;
  transition: all 0.3s ease;
}
.profile-preview .testimonial-block {
  opacity: 1;
  padding: 0;
}
.testimonial-block .inner-box {
  position: relative;
  padding: 40px;
  background: #fff;
  border: 1px solid #ecedf2;

  border-radius: 8px;
}
.profile-preview .resume-reviews .testimonial-block .inner-box {
  min-height: 0;
  max-width: 470px;
  box-shadow: none;
}
.testimonial-block .text {
  font-size: 16px;
  line-height: 26px;
  color: dimgray;
  margin-bottom: 50px;
}
.profile-preview .testimonial-block .text {
  margin: 30px 0 20px;
  min-height: 52px;
  text-align: justify;
}
.resume-reviews .testimonial-block .text:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJTSURBVHgBtVfdcdNAEN7TyeYJjzWDmfGbO8AdICogHeBUEDow7oBUEKUCoAJEBQkVRG+ecSTOsfNm7pY7YUBE2l1NBr4X/3y6T99+u3cjAfTEem1mVbW/Kqvdxe3tPoVHoDT3b6pvd1eV2S+DXpNTfQTCouFQf0aAWWNhYRFW9mDz6TQpJI1gApzLHvydoVOXk8nTXDRyc2PGo1HsTeCcugadfTWZJDnFbzZmrnVdyLjzAgV5BAJG43jJmfDIOBMhTW/iA2nC46DsKWukMvdn6PAtxSvA4hDZFacxGOpls6UtIJ5Pk6QgjdTD5Nw7YGBRrYIIxW/K3cJ/LCi+LkS79+E7aeRYCRknIOTPn40yiq5bomAJDJqFdBqRKgk4aHvK8VJLQhrNQjqNSJV4ZFxLjmfEAhiENJq/W0ZCGuxwQb1dLzl+8ERfcPzDNDqNSGl4kWtuu5bl7sTPT8pItNJoGemThhc553iveAYCrLZ5e1kDPWYDBtp+pLhwgkppIOKnrvn6bSREKs6GF0mSZEvxkdZiGgiqs5A/iSh4DY8UaeBE4MlEayPGmHBwLUBAV29/4Xj2jFkBfwhSidZGrNUpCAi7hTs7IgUvQYBv7Rdy/fEufdpyLVwitgXA5cAZ8Q8lKUhGHH6luHq3SG3xiGMgi4nCcSztlp9wpIhSUQoytuyOGwz0HHqAq0ZpJc6H7y3b2sgPUC8jXDUK1QxEH3jH8ZGv5gUICA/KwN9ELEbSCMMqDhmHtfn7tYACImw5PlIoD6oXKSgu/h7P4B8g6rdj/j9+AEACCd8iCuX7AAAAAElFTkSuQmCC);
  content: "";
  height: 26px;
  position: absolute;
  width: 34px;
  z-index: 2;
  top: 30px;
}
.profile-preview .review-chip {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}
.profile-preview .review-chip li,
.profile-preview .review-time {
  border: 1px solid #ecedf2;
  border-radius: 20px;
  padding: 0 10px;
}
.profile-preview .review-chip li {
  font-size: 11px;
  line-height: 18px;
  color: #000;
  font-weight: 500;
}
.profile-preview .review-chip span {
  color: #1967d2;
  margin: 0 5px;
}
.profile-preview .testimonial-block .star {
  color: #fcb349;
  margin: 0;
}
.testimonial-block .info-box {
  position: relative;
  padding-left: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
  text-transform: capitalize;
}
.profile-preview .testimonial-block .info-box {
  padding-left: 0;
}
.profile-preview .resume-reviews .testimonial-block .info-box:before {
  top: 0;
}
.profile-preview .review-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
}
.profile-preview .review-time {
  background-color: #f0f5f7;
  font-size: 10px;
  display: flex;
  align-items: center;
}
.profile-preview .review-footer .average-value {
  margin-top: 2px;
  color: #1371f0;
}
.profile-preview .read-more {
  color: #1371f0;
}
.project-tags li {
  font-size: 12px;
  margin-right: 0;
}
.chip-array li {
  padding: 0;
  margin-right: 5px;
  font-size: 13px;
}

/* project modal */
.project-modal.tnc-section {
    padding: 30px;
    background: #f6f9ff;
    border: 1px solid #e2eaf8;
}
.news-block-two .inner-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ecedf2;
    background: #fff;
}
.project-modal .inner-box {
    padding: 23px 67px 33px 0;
}
.news-block-two .content-box {
    padding: 15px 30px 0;
    position: relative;
    flex: 1 1;
}
.project-modal .inner-box .content-box .name {
    position: relative;
    font-weight: 500;
    margin: 0;
    background: none;
    line-height: 1.2em;
    font-size: 2rem;
    color: #363636;
    text-transform: capitalize;
}
.project-modal .text-box {
    position: relative;
    margin-bottom: 30px;
}
.project-modal h6 {
    font-weight: 500;
    font-size: 1rem;
}
.project-modal p {
    font-size: 15px;
    line-height: 24px;
    color: dimgray;
    /* margin-bottom: 24px; */
}
.project-modal .desc ul li {
    color: dimgray;
    font-size: 15px;
}

/* SIDEBAR */
.social-media-widget {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.social-media-widget .social-links {
    display: flex;
    align-items: center;
}
.social-media-widget .social-links a {
    font-size: 14px;
    line-height: 26px;
    color: dimgray;
    margin-left: 17px;
    transition: all .3s ease;
}

/* vetted skills */
.test-result-card img {
    margin-left: 10px;
}
.profile-preview .result-body-top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
}
.profile-preview .result-body-bottom .para {
    display: grid;
    grid-template-columns: 80px 3fr 50px;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    line-height: normal;
}
.MuiCardContent-root .cat-name, 
.MuiCardContent-root .test-duration {
    color: #202124;
}
.MuiCardContent-root p {
    font-size: 13px;
    margin-bottom: 5px;
}
.profile-preview .result-body-bottom .score {
    text-align: right;
}
.MuiCardContent-root .result-body-bottom .final-result p {
    font-weight: 500;
}
.test-result-card .MuiCardHeader-content .MuiTypography-root {
    font-family: Jost, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    color: #202124;
}
.test-result-card .MuiTypography-root.MuiCardHeader-subheader {
    font-size: 12px;
    color: dimgray;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1300px) {
  .project-cards .project-desc {
    margin-bottom: 20px;
  }
}
