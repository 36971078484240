.dropbtn-olibr {
  background-color: #ffffff;
  color: #212529;
  border: 1px solid rgba(66, 75, 84, 0.2);
  cursor: pointer;
  min-width: 70px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-olibr {
  position: relative;
  display: inline-block;
}

.dropdown-content-olibr {
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 290px;
  padding-top: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  left: 0;
  max-height: 250px;
  overflow: auto;
}

.dropdown-content-olibr div {
  padding: 2px 5px;
}

.dropdown-content-olibr div:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
