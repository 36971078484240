.testimonial-section {
  position: relative;
  /* padding: 100px 0; */
  /* background-color: #f0f5f7; */
  background: url(https://ik.imagekit.io/olibr/assets/tr:w-1600,h-371/testimonials-bg.webp)
    no-repeat 50%;
  background-size: cover;
  margin: 100px 0 0;
}

.testimonial-section .carousel-outer {
  position: relative;
  margin: 0 -10px;
  width: 100%;
  margin: 0 auto;
}

.testimonial-block {
  position: relative;
  padding: 10px;
  margin-right: 20px;
  opacity: 0.5;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.testimonial-block .inner-box {
  position: relative;
  padding: 40px 40px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  min-height: 390px;
}

/* .testimonial-block .inner-box:before {
  position: absolute;
  height: 26px;
  width: 34px;
  right: 28px;
  top: 25px;
  background-image: url(../../assets/img/icons/quote-icon.png);
  content: "";
  z-index: 2;
} */

.testimonial-block .title::before {
  position: absolute;
  height: 26px;
  width: 34px;
  left: 0px;
  top: -40px;
  background-image: url(../../assets/img/icons/quote-icon.png);
  content: "";
  z-index: 2;
}

.testimonial-block .info-box::before {
  position: absolute;
  height: 26px;
  width: 34px;
  right: 0px;
  top: -45px;
  background-image: url(../../assets/img/icons/quote-icon.png);
  content: "";
  z-index: 2;
  transform: rotate(180deg);
}

.testimonial-block .title {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: #3ba0aa;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 40px;
}

.testimonial-block .text {
  font-size: 14px;
  line-height: 26px;
  color: #312d2d;
  margin-bottom: 50px;
  text-align: justify;
  font-style: italic;
  overflow: hidden;

  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  max-height: 120px;  */
}

/* .read-more-clamp {
  color: blue;
  cursor: pointer;
  display: none; 
} */

.testimonial-block .text span {
  font-size: 42px;
  color: #ccc;
}

.testimonial-block .text span:last-of-type {
  display: block;
  margin-top: 15px;
  text-align: right;
}

.testimonial-block .info-box {
  position: relative;
  padding-left: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 70px;
  /* position: absolute;
  bottom: 30px;
  width: calc(100% - 90px); */
}

.testimonial-block .thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: #101c5d;
  overflow: hidden;
}

.testimonial-block .name {
  font-size: 18px;
  color: #696969;
  font-weight: 500;
}

.testimonial-block .designation {
  position: relative;
  display: block;
  font-size: 14px;
  color: #696969;
}

.testimonial-section.style-two {
  background-color: #ffffff;
  padding-bottom: 50px;
}

.testimonial-section.style-two .testimonial-block {
  opacity: 1;
}

.testimonial-section.style-two .default-dots .owl-dots {
  position: relative;
  margin-top: 40px;
}

.testimonial-section.style-two .testimonial-block .name {
  font-weight: 500;
  color: #202124;
}

.testimonial-section.style-two.alternate .testimonial-block {
  padding: 0;
}

/* slick carousel */
/* slick dots */
.slick-dots li {
  margin: 0 5px;
  width: 8px;
  height: 8px;
  background: #bfc8cb;
  border-radius: 10px;
  transition: all 300ms ease;
}

.slick-dots li button {
  padding: 0;
}

.slick-dots li button::before {
  display: none;
}

.slick-dots li.slick-active {
  width: 20px;
  background: #202124;
}

.slick-dots {
  position: static;
  margin-top: 40px;
}

/* gap-x25 */
.gap-x25 .slick-slide {
  padding: 0 15px;
}

.gap-x25 .slick-list {
  margin: 0 -15px;
  width: 100%;
  margin: 0 auto;
  /* height: 570px; */
}

/* slick navigation */
.slick-arrow {
  position: absolute;
  z-index: 2;
  background: #e2eaf8;
  border-radius: 8px;
  color: var(--primary-color);
  height: 50px;
  width: 50px;
  opacity: 1;
  line-height: 50px;
  text-align: center;
  margin-right: 10px;
  font-weight: 600;
  font-size: 12px;
  transition: all 300ms ease;
}

.slick-arrow:hover,
.slick-arrow:focus {
  background: var(--primary-color);
  color: #fff;
}

.slick-arrow::before {
  display: none;
}

/* arrow-center-between */
.arrow-center-between .slick-prev {
  left: 25px;
}

.arrow-center-between .slick-next {
  right: 25px;
}

/* arrow-rounded */
.arrow-rounded .slick-arrow {
  border-radius: 50%;
}

.testimonial-section-two.style-two .slick-dots {
  position: absolute;
  bottom: 25px;
}

.testimonial-section.style-two.alternate .slick-dots li.slick-active {
  background: #fff;
}

.slick-track .slick-slide {
  width: 50vw;
}
/* media query */
/* @media only screen and (max-width: 1160px) {
  .gap-x25 .slick-list,
  .testimonial-block .inner-box {
    height: 400px;
  }
} */

@media only screen and (max-width: 1266px) {
  .testimonial-block .inner-box {
    padding: 30px 30px;
    min-height: 300px;
  }

  .testimonial-block .thumb {
    height: 50px;
    width: 50px;
  }

  .testimonial-block .info-box {
    /* padding-left: 70px; */
    min-height: 50px;
  }

  .testimonial-block .name {
    font-size: 18px;
    line-height: 1.2em;
  }

  .testimonial-block .designation {
    font-size: 14px;
  }
}

/* @media only screen and (max-width: 1160px) {
  .gap-x25 .slick-list,
  .testimonial-block .inner-box {
    height: 500px;
  }
} */

@media only screen and (max-width: 767px) {
  .testimonial-section {
    padding: 50px 0;
  }
}

/* @media only screen and (max-width: 650px) {
  .gap-x25 .slick-list {
    height: 700px;
  }
}

@media only screen and (max-width: 475px) {
  .gap-x25 .slick-list {
    height: 800px;
  }
} */
