.contact-banner {
    width: 100%;
    display: block;
    min-height: 280px;
    /* background: url("./../../assets/img/contact-bg.webp"); */
    background: url('https://ik.imagekit.io/olibr/assets/tr:w-1600,h-280/contact-bg.webp');
    text-align: center;
  }
  .contact-banner h1 {
    color: #fff;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    margin: 0;
    padding: 70px 0px 0px 0px;
    display: block;
  }
  .contact-banner p {
    font-size: 20px;
    /* font-family: "Poppins-Light"; */
    font-weight: 300;
    line-height: 32px;
    color: #fff;
    display: inline-block;
    width: 80%;
  }
  .contact-section {
    width: 100%;
    display: block;
    padding: 25px 15px;
    box-sizing: border-box;
  }
  .contact-section .form-group {
    margin-bottom: 5px;
  }
  .contact-btn {
    text-align: right;
    margin-top: 15px;
  }
  .contact-info {
    width: 100%;
    display: block;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #d2e2f2;
    margin-bottom: 15px;
    text-align: center;
  }
  .contact-info p {
    font-size: 16px;
    color: #242c33;
    letter-spacing: 2px;
    text-transform: uppercase;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
    margin: 0;
    padding: 0px;
    line-height: 24px;
  }
  .contact-info p span {
    font-size: 13px;
    /* font-family: "Poppins-Light"; */
    font-weight: 300;
    text-transform: none;
  }
  