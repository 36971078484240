.faq-management{
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(145 158 171 / 20%) 0px 3px 3px -2px, rgb(145 158 171 / 14%) 0px 3px 4px 0px, rgb(145 158 171 / 12%) 0px 1px 8px 0px;
    background-image: none;
    padding: 0px 20px;
    /* margin-top: 20px; */
    border-radius: 5px;
    width: 100%;
    border-top-left-radius: 0 ;
    border-top-right-radius: 0 ;
    margin-bottom: -30px;
}

.dltBtn{
    background-color: #F15D3B;
    border-radius: 50px;
    outline: none;
    border: none;
    padding: 2px 4px;
    transition: background-color 0.1s ease-in, box-shadow 0.1s ease-in;
}

.dltBtn:hover{
    background-color: #d14f32;
    box-shadow: 0px 0px 10px 0px #F15D3B;  
    cursor: pointer;
}

.dltBtn img{
    padding-top: 1px;
    width: 16px;
    height: 16px;
}
.editBtn{
    background-color: #3BA0AA;
    border-radius: 50px;
    outline: none;
    border: none;
    padding: 2px 4.5px;
    transition: background-color 0.1s ease-in, box-shadow 0.1s ease-in;
}

.editBtn:hover{
    background-color: #3c888f;
    box-shadow: 0px 0px 10px 0px #3BA0AA;  
    cursor: pointer;
}

.editBtn img{
    padding-top: 1px;
    width: 14px;
    height: 14px;
}

.actionTab{
    display: flex;
    justify-content: space-around;
}


.titleQues{
    /* font-family: 'Poppins-regular'; */
    /* font-weight: 600; */
    font-weight: 400;
    font-size: 14px;
    color: #242C33;
}

.titleQues:hover{
    cursor: pointer;
}

.subQues{
    /* font-family: 'Poppins-regular'; */
    /* font-weight: 500; */
    font-weight: 400;
    font-size: 14px;
    color: #3BA0AA;
}

.subQues:hover{
    cursor: pointer;
}

.postedOn{
    /* font-family: 'Poppins-regular'; */
    font-weight: 400;
    font-size: 12px;
    color: #242C33;
}

.sno{
    text-align: center;
}

.colAction, .serialNumber{
    text-align: center;
    display: block;
    width: 100%;
}
.divider{
    height: 2px;
    background-color: #0e3d9a;
}

.fields:hover{
    border-color: #3BA0AA;
}
.selectF{
    display: flex;
}
.selectFaq{
    /* font-family: 'Poppins-regular'; */
    font-size: 12px;
    /* font-weight: 300; */
    font-weight: 400;
    color: #6D6F6D;
    margin-top: 8px;
}
