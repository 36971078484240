  .sidenav {
    height: 100%;
    position: fixed;
    z-index: 150000;
    top: 0;
    right: 0%;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    box-shadow: -10px -10px 20px #00000040;
  }

  .title-tooltip{
    padding-top: 40px;
  }

  .title-tooltip h2{
    padding-left: 20px;
    font-size: 26px;
    padding-top: 20px;
  }
  
  .helptip{
    color: #63698480
  }
  
  .toolTipText, .helptip{
    padding: 10px 20px;
  }

  .toolTipText h4{
    font-size: 16px;
  }

  .toolTipText ul{
    font-size: 14px;
    padding-left: 20px;
  }
  .toolTipText ul li{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .toolTipText ul li::before{
    color: #3ba0aa;
    content: "\2022";
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
  }

  .toolTipText div{
    background-color: #F6FBFE;
    border: 1px solid #D1E1EA;
    padding: 20px;
    margin-bottom: 20px;
  }

  .toolTipText p{
    font-size: 12px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav .closebtn-tooltip {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 36px;
    margin-left: 0px;
    color: #63698480;
  }

  .closebtn-tooltip:hover{
    cursor: pointer;
  }

  .mainDiv{
    height: 100%;
    position: fixed;
    z-index: 150004;
    top: 0;
    right: 0%;
    overflow-x: hidden;
    transition: 0.5s;
    width: 100%;
  }

  .hide{
    display:none
  }