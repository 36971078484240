.react-datepicker__header {
    background-color: #effbfc !important; 
    /* font-family: 'Poppins-Medium' !important; */
    font-weight: 500 !important;
}

.react-datepicker__month {
    margin: 0;
    padding: 0.3rem !important;
    text-align: center !important;
    /* font-family: 'Poppins-Regular' !important; */
    font-weight: 400 !important;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block !important;
    width: 4rem !important;
    margin: 5px 4px !important;
    border-radius: 1.3rem !important;
    padding: 10px !important;
    text-transform: uppercase !important;
}

.react-datepicker__day{
    transition: 150ms ease-in;
}

.react-datepicker__day:hover{
    border-radius: 1.3rem !important;
    background-color: #c2eaee !important;
}

.react-datepicker__month .react-datepicker__month-text:hover{
    border-radius: 1.3rem !important;
    background-color: #c2eaee !important;
}

.react-datepicker__month-text--keyboard-selected{
    border-radius: 1.3rem !important;
    background-color: #3ba0aa !important;
    color: #fff !important;
}

.react-datepicker__day--selected{
    border-radius: 1.3rem !important;
    background-color: #3ba0aa !important;
    color: #fff !important;
}

.react-datepicker__close-icon::after{
    background-color: #3ba0aa !important;
    padding: 0px !important;
}

.react-datepicker__month-wrapper, .react-datepicker__week{
    transition: 150ms ease-in-out !important;
}

.react-datepicker__week:hover, .react-datepicker__month-wrapper:hover {
    background-color: #d7f9fc !important;
}