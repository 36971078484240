
/* .skill-result-detail{
    background-color: #F3F9FF;
    border-radius: 8px;
    padding-bottom: 10px;
    margin-bottom: 26px;
  } */
  
  .accordion-skill{
    background-color: white;
      height: 300px;
      width: 94%;
      margin: 2px auto;
      border-radius: 8px;
  }
  
  .skill-result-detail .title-header .skill-icon{
    vertical-align: middle;
    margin-right: 10px;
  }
  .skill-result-detail  .skill-icon-pass{
    position: absolute;
    margin: -28px -17px;
  }
  
  .skill-result-detail .title-right p{
    line-height: 9px;
  }
  .skill-result-detail{
    padding-top:15px
  }


.collapse-button-opens{
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  z-index: 10;
  transition: 150ms ease-in;
  margin-left: 10px;
  border: 1px solid #d9e1ea;
  height: 30px;
  width: 30px;
  text-align: center;
  box-shadow: 13px 41px 96px 0px rgba(198,218,236,0.89);
-webkit-box-shadow: 13px 41px 96px 0px rgba(198,218,236,0.89);
-moz-box-shadow: 13px 41px 96px 0px rgba(198,218,236,0.89);
}
.collapse-button-opens img{
  vertical-align: middle;
  margin-top: 2px;
  margin-left: 1px;
}

.skill-result-detail .date-icon img{
    vertical-align: middle;
    margin-right: 5px;
}
.skill-result-detail .date-icon span{
  font-size: 12px;
  color: #767A7D;
}
.skill-result-detail .coding-challange{
    /* font-family: 'Poppins-Regular'; */
    font-weight: 400;
    margin-top: 10px;   
     padding-left: 15px;
    box-sizing: border-box; 
}

@media (max-width: 768px) {
  .skill-result-detail .time{
    margin:5px 0px;
  }
  .linear-progressbar-1{
    width: 100%!important;
  }
  .result-stats{padding: 15px;}
  .collapse-button-opens{margin-bottom: 15px;}
   .skill-result-detail .coding-challange{padding-left: 0; margin-bottom: 0;}
}

.skill-result-detail .detail-btn{
    background-color: #F15D3B;
    padding: 7px 37px;
    border-radius: 8px;
    border-width: 0;
    color: white;
    margin-bottom:10px;
    width: 153px;
}
.skill-result-detail .time{
  font-size: 15px;
    /* font-family: 'Poppins-Regular'; */
    font-weight: 400;
    line-height: 16px;
}
.skill-result-detail .time .duration{
    color:#F0A039;
    font-size: 20px;
}

.linear-progressbar-1{
  height: 8px;
  width: 100%;
  margin-top: 4px;
  background-color: #E5E5E5;
  border-radius: 10px;
}


.linear-progressbar-1 div{
    height: 100%;
    background-color: #FCB349;
     border-radius: 10px;
}

.total-rows-progress .linear-progressbar-1 div{
    background-color: #72BF44;
}

.total-rows-progress .percentage{
    color:  #72BF44;
    font-size: 18px;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
}


.col-2-3{
width: 25%;
}

.result-box{
  margin-bottom: 20px;
  display: block;
  background-color: #E2ECF6;
  border-radius: 8px;
  padding: 15px 15px 0px 15px;
}
.result-box .title-header{
  margin-bottom: 15px;
}
.result-stats{
  background-color: white;
  border-radius: 8px;
  width: 100%;
  display: block;
  margin-bottom: 15px;
}
.result-stats .date-icon{
  margin-top: 10px;
}