:root {
  --theme-color: #3ba0aa;
}

html {
  scroll-behavior: smooth;
}

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/* @font-face {
  font-family: "Poppins-Light";
  src: url("./assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("./assets/fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: "Poppins-Regular", sans-serif; */
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(44, 56, 74, 0.95);
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  min-height: 100vh;
}

p {
  margin: 0 0 1rem;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Poppins-Medium", sans-serif; */
  color: #242c33;
  /* font-weight: normal; */
  font-weight: 500;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 12px;
}

#root {
  display: flex;
  min-height: 100vh;
  flex: 1;
  width: 100%;
}

.App {
  display: flex;
  min-height: 100vh;
  flex: 1 0 100%;
  max-width: 100%;
}

.wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.main {
  flex: 0 0 calc(100% - 250px);
  margin-left: 250px;
  overflow-x: auto;
  min-height: 100vh;
  /* padding-top: 97px; */
}

/* .main-collapsed {
  flex: 0 0 calc(100% - 15px);
  margin-left: 15px;
  overflow-x: auto;
} */

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  display: block;
  max-width: 1280px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  flex: 0 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.col-1 {
  width: 8.33333333%;
}

.col-2 {
  width: 16.66666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33333333%;
}

.col-5 {
  width: 41.66666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33333333%;
}

.col-8 {
  width: 66.66666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33333333%;
}

.col-11 {
  width: 91.66666667%;
}

.col-12 {
  width: 100%;
}

.form-group {
  margin-bottom: 10px;
}

table.step-table {
  width: 100%;
}

.step-table th {
  text-align: left;
  padding: 10px;
  font-weight: normal;
  font-size: 14px;
}

.step-table td {
  padding: 5px;
}

label {
  margin-bottom: 0.5rem;
}

.floating-label {
  margin-bottom: 0;
  position: relative;
  top: 10px;
  font-size: 80%;
  left: 10px;
  background-color: #fff;
  padding: 3px 5px;
  color: var(--theme-color);
  z-index: 1;
}

.new-label {
  color: var(--theme-color);
  font-size: 12px;
  position: relative;
  top: 2px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="url"],
/* input[type="file"], */
input[type="date"],
input[type="month"],
select,
textarea {
  /* font-family: "Poppins-Regular"; */
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: #ffffff;
  border: 1px solid rgba(66, 75, 84, 0.2);
  box-shadow: 0px 4px 4px rgba(218, 232, 245, 0.1);
  border-radius: 4px;
}

input[type="text"]:hover,
input[type="password"]:hover,
input[type="email"]:hover,
input[type="number"]:hover,
input[type="tel"]:hover,
input[type="date"]:hover,
select:hover,
textarea:hover {
  border-color: #3ba0aa;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

textarea {
  min-height: 80px;
}

Input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.text-right {
  text-align: right !important;
}

.placeholder::before {
  content: attr(placeholder) !important;
  color: #aaa;
  width: 100%;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.placeholder:focus::before,
.placeholder:active::before {
  content: "";
  width: 0%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.spl {
  font-size: 18px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  width: 100%;
}

.form-required {
  border: 1px solid red !important;
}

.form-requird .ck-editor {
  border: 1px solid red !important;
}

.form-required-quill .quill {
  border: 1px solid red !important;
}

.date-required input {
  border: 1px solid red !important;
}

.select-required .css-13cymwt-control {
  border: 1px solid red !important;
}

.select-required-border {
  border: 1px solid red !important;
}

.spl-icon {
  position: relative;
  float: left;
  margin-top: -7px;
}

.spl-icon img {
  width: 70px;
  padding: 10px;
}

.home-banner {
  display: block;
}

.why-join {
  display: block;
  padding: 0 20px;
  margin-bottom: 80px;
}

.section-title {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  /* font-family: "Poppins-Medium", sans-serif; */
  /* font-weight: normal; */
  font-weight: 500;
  font-size: 32px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.cards .card {
  flex: 0 0 33.3333%;
  max-width: calc(33.3333% - 16px);
  background-color: #e8f1fb;
  border: 1px solid #e8f1fb;
  display: block;
  border-radius: 5px;
  margin: 8px;
  padding: 15px;
  transition: all 0.3s ease-in-out;
}

.card:hover {
  transition: all 0.3s ease-in-out;
  background-color: #f0f5f6;
  -webkit-box-shadow: 3px 6px 20px -6px rgba(226, 236, 238, 1);
  -moz-box-shadow: 3px 6px 20px -6px rgba(226, 236, 238, 1);
  box-shadow: 3px 6px 20px -6px rgba(226, 236, 238, 1);
  transition: all 0.3s ease-in-out;
}

.cards .card figure {
  display: block;
  clear: both;
  width: 60px;
  margin-bottom: 10px;
}

.cards .card .card__title {
  font-size: 16px;
  /* font-family: "Poppins-Medium"; */
  /* font-weight: normal; */
  font-weight: 500;
  margin-bottom: 10px;
  color: #000;
}

@media (max-width: 768px) {
  .why-join {
    display: block;
    padding: 0 20px;
  }

  .cards .card {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 8px 0;
  }

  .section-title {
    font-size: 26px;
  }
}

.why-join .section-title .link {
  /* font-family: "Poppins-Medium"; */
  /* font-weight: 400; */
  font-weight: 500;
}

.section-sub-title {
  display: flex;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  justify-content: center;
  margin-bottom: 10px;
}

.home-banner__text {
  padding: 80px 15px 80px 15px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
}

.home-banner__text p span,
.how-join__text .timeline-list h4 span {
  color: #3ba0aa;
  /* font-family: "Poppins-Light"; */
  font-weight: 300;
  font-size: 20px;
  text-transform: capitalize;
  line-height: 40px;
}

.how-join {
  display: flex;
}

.how-join .container {
  display: flex;
}

.how-join__img {
  flex: 0 0 40%;
  max-width: 40%;
  display: flex;
  padding: 40px;
}

.how-join__img img {
  width: 100%;
}

.how-join__text {
  padding: 80px 40px 80px 15px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
}

.how-join__title {
  margin-bottom: 20px;
}

.how-join__text .timeline-list {
  list-style: none;
  counter-reset: mycounter;
  padding: 0;
}

.how-join__text .timeline-list li {
  position: relative;
  margin-left: 50px;
  padding: 5px 0;
}

.how-join__text .timeline-list li:before {
  content: counter(mycounter);
  counter-increment: mycounter;
  position: absolute;
  top: 5px;
  left: -50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--theme-color);
  border: 2px solid var(--theme-color);
  background: #f0f5f6;
  text-align: center;
}

.how-join__text .timeline-list li:after {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background-color: var(--theme-color);
  position: absolute;
  left: -34px;
  top: 38px;
}

.how-join__text .timeline-list li:last-child:after {
  display: none;
}

.how-join__text .timeline-list h4 {
  font-size: 18px;
  /* font-family: "Poppins-Medium", sans-serif; */
  /* font-weight: normal; */
  font-weight: 500;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .how-join .container {
    flex-direction: column;
  }

  .how-join__img {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .how-join__text {
    flex: 0 0 auto;
    max-width: 100%;
    padding: 40px 20px;
  }

  .how-join__title {
    font-size: 26px;
  }
}

/* Faq */
.faq {
  display: block;
  padding: 80px 20px;
}

.faq .container {
  display: flex;
}

.faq__title {
  font-size: 34px;
  /* font-family: "Poppins-Medium", sans-serif; */
  /* font-weight: normal; */
  font-weight: 500;
  margin-bottom: 20px;
}

.faq__text {
  padding: 40px 40px 40px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 16px;
}

.faq__img {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 40px;
  display: block;
}

.faq .faq__text > .btn-block {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .faq {
    display: block;
    padding: 0 20px;
  }

  .faq .container {
    flex-direction: column;
    padding: 0;
  }

  .faq__img {
    flex: 0 0 auto;
    max-width: 100%;
    order: 1;
  }

  .faq__text {
    flex: 0 0 auto;
    max-width: 100%;
    padding: 40px 0px;
    order: 2;
  }

  .faq__title {
    font-size: 26px;
  }
}

.choose-stack {
  display: block;
  font-size: 14px;
}

.choose-stack .sub-heading {
  flex-direction: column;
}

.choose-stack .sub-heading div {
  display: flex;
  justify-content: space-between;
}

.choose-stack h4 {
  font-size: 18px;
  margin-bottom: 10px;
  /* font-family: "Poppins-SemiBold", sans-serif; */
  font-weight: 600;
}

.sub-heading {
  display: flex;
  justify-content: space-between;
}

.sub-heading h4 {
  font-size: 18px;
  /* font-family: "Poppins-Medium", sans-serif; */
  font-weight: 500;
}

/* .blog-container .blog-card{
  flex:0 0 100%;
  max-width:calc(100% - 16px);  
  display:block;
  border-radius:5px;
  margin:8px;
  padding:15px;
} */

.blog {
  display: block;
  padding: 40px 20px;
  background-color: #f6feff;
}

.blog-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.blog-container > .btn-block {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
}

.blog-left {
  flex: 0 0 50%;
  max-width: calc(50% - 8px);
}

.blog-right {
  flex: 0 0 100%;
  max-width: calc(100% - 8px);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
}

.blog-card-home {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
}

.blog-card-home:hover {
  cursor: pointer;
}

.blog-card-home .blog-img {
  width: 100%;
  display: block;
}

.blog-card-home .blog-img figure {
  display: block;
}

.blog-card-home .blog-img img {
  width: 100%;
  display: block;
}

.blog-cont {
  height: 66px;
  overflow: hidden;
  color: #626262;
  margin-bottom: 10px;
}

.blog-block {
  display: block;
  padding: 10px 20px;
  width: 100%;
}

.blogger {
  display: flex;
  margin-bottom: 15px;
}

.blogger-img {
  width: 60px;
}

.blogger-img img {
  border-radius: 50px;
  width: 50px;
}

.blogger-detail-bottom {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.blogger-detail {
  /* display: flex; */
  /* flex-direction: column; */
  padding: 0 20px;
}

.blogger-detail span,
.blogger-detail-bottom span {
  font-size: 14px;
  color: #3ba0aa;
  padding: 0px 0px;
}

.blogger-detail-bottom span {
  color: #000;
  font-weight: 600;
}

.blogger-detail p,
.blogger-detail-bottom p {
  margin: 0;
  font-size: 12px;
}

.date-name span {
  padding: 0px 10px;
  color: black;
}

.blog-content {
  font-size: 14px;
}

.blog-content h3 {
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
  max-height: 48px;
  overflow: hidden;
}

.blog-post {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-right .blog-content h3 {
  font-size: 16px;
}

@media (max-width: 768px) {
  /* .blog-container{
      
  } */
  .blog-left {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .blog-right {
    flex: 0 0 100%;
    max-width: 100%;
    grid-template-columns: 1fr;
  }
}

.home-banner .container {
  display: flex;
  gap: 35px;
  align-items: center;
  justify-content: center;
}

.home-banner__title {
  font-size: 34px;
  /* font-family: "Poppins-Medium", sans-serif; */
  /* font-weight: normal; */
  font-weight: 500;
  margin-bottom: 10px;
}

.home-banner__sub-title {
  font-size: 20px;
  /* font-family: "Poppins-Regular", sans-serif; */
  font-weight: normal;
  margin-bottom: 20px;
  margin-top: -10px;
}

.home-banner__text h2 {
  margin-bottom: 20px;
}

.home-banner__img {
  flex: 0 0 40%;
  max-width: 40%;
  display: flex;
  padding: 10px;
}

.home-banner__img img {
  width: 500px;
  object-fit: contain;
}

.h-banner-bg {
  background: url(https://ik.imagekit.io/olibr/assets/tr:w-736,h-533/abstract-right-bg.webp)
      top right,
    url(https://ik.imagekit.io/olibr/assets/tr:w-736,h-533/abstract-left-bg.webp)
      top left;
  background-repeat: no-repeat;
}

.h-banner-color {
  background-image: linear-gradient(
    to right top,
    #efeffc,
    #f3f3fd,
    #f7f7fd,
    #fbfbfe,
    #ffffff
  );
}

@media (max-width: 768px) {
  .home-banner {
    margin-bottom: 40px;
  }

  .home-banner .container {
    flex-direction: column;
  }

  .home-banner__img {
    flex: 0 0 auto;
    max-width: 100%;
    order: 1;
  }

  .home-banner__text {
    flex: 0 0 auto;
    max-width: 100%;
    padding: 40px 20px;
    order: 2;
  }

  .home-banner__text h2 {
    font-size: 26px;
  }

  .home-banner__text h1 {
    font-size: 26px;
  }
}

.margin-bottom-none {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.margin-top-none {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.link {
  color: var(--theme-color);
  /* font-family: "Poppins-Regular", sans-serif; */
  font-weight: normal;
  cursor: pointer;
}

.link-small {
  color: var(--theme-color);
  /* font-family: "Poppins-Regular", sans-serif; */
  font-weight: normal;
  font-size: 14px;
}

.btn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 1px solid;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  line-height: 1.5;
  font-size: 15px;
  text-transform: capitalize;
  padding: 10px 22px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #3ba0aa;
  color: #fff;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  /* font-weight: normal; */
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  transition: all 0.3s ease-in-out;
  background-color: #196f78;
  color: #fff;
}

.btn2 {
  background-color: #0c484e;
}

.btn.btn-gray:hover {
  transition: all 0.3s ease-in-out;
  background-color: #383938;
}

.btn.btn-lg {
  padding: 10px 30px;
}

.btn.btn-primary {
  background-color: #3ba0aa;
}
.btn.btn-orange {
  background-color: #e74c3c;
}

.btn-full {
  width: 100%;
}

.btn-outline {
  background-color: #fff;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.btn + .btn {
  margin-left: 10px;
}

.link-circle {
  float: right;
}

.test-list {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}

.test-list li {
  flex: 0 0 auto;
  max-width: 25%;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
}

.test-list-stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.test-list-stack li {
  flex: 0 0 auto;
  max-width: 25%;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
}

.link-circle img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
}

.link-circle.danger {
  background-color: #f15d3b;
}

.link-circle svg {
  height: auto;
  width: 18px;
  fill: #fff;
}

.link-circle + .link-circle {
  margin-left: 10px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

@media (max-width: 768px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    flex-basis: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.main-collapsed {
  margin-left: 73px;
  flex: 0 0 calc(100% - 73px);
}

@media (max-width: 1200px) {
  .main {
    margin-left: 0px;
    flex-basis: 100%;
  }
}

.olibr-breadcrumbs {
  width: 100%;
  display: block;
  background: #f1f2f8;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  padding: 0px 15px;
  box-sizing: border-box;
}

.olibr-breadcrumbs ul {
  padding: 15px 0px;
  display: block;
  list-style: none;
}

.olibr-breadcrumbs ul li {
  font-size: 14px;
  color: #a4a9be;
  display: inline;
  padding: 0px 10px 0px 0px;
  text-transform: capitalize;
}

.olibr-breadcrumbs ul li a {
  color: var(--theme-color);
  text-decoration: none;
  display: inline;
}

.block-header {
  border-bottom: 3px solid #d2e2f2;
  display: block;
  position: relative;
  margin: 0 0 20px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  text-transform: capitalize;
}

.block-header h4 {
  display: inline-block;
  position: relative;
  padding: 0 0 10px;
  font-size: 18px !important;
  text-transform: uppercase;
}

.block-header h4 img {
  height: 20px;
  width: 20px;
  margin-left: 8px;
  margin-top: 4px;
}

.block-header h4:after {
  background-color: var(--theme-color);
  display: block;
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0px;
  width: 100%;
  height: 2px;
}

.block-header img {
  height: 30px;
  width: 30px;
}

.block-header img:hover {
  cursor: pointer;
}

.block-header.small {
  font-size: 16px;
}

.block-header span {
  float: right;
}

.flex-end {
  justify-content: flex-end !important;
}

.text-danger {
  color: red;
}

.next {
  font-size: 16px;
}

.btn-full {
  width: 100%;
}

.create-profile {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(216 236 252 / 20%) 0px 3px 3px -2px,
    rgb(216 236 252 / 14%) 0px 3px 4px 0px,
    rgb(216 236 252 / 12%) 0px 1px 8px 0px;
  background-image: none;
  padding: 15px;
  margin-top: 20px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #f1f1f1;
}

.sidebar-is-open {
  position: "relative";
  left: "260px";
  top: "20px";
}

.addon {
  z-index: 0;
}

.txt-trans {
  text-transform: capitalize;
}

.single-chart {
  width: 100%;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

#notification .notification-number {
  position: absolute;
  top: 19px;
  right: 68px;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.green .circle {
  stroke: #3ba0aa;
}

.circular-chart.red .circle {
  stroke: #f15d3b;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.4em;
  text-anchor: middle;
}

.vetted-skill .single-chart {
  width: 94px;
  margin: 0px auto;
}

.project-block p {
  font-size: 12px;
  line-height: 17px;
}

.sidebar-nav li a {
  font-size: 14px;
}

.sidebar-nav li a img {
  width: 16px;
}

.sidebar-nav li a svg {
  width: 16px;
}

.title-head {
  color: #242c33;
  font-size: 14px;
  font-weight: 700;
  padding-top: 15px;
}

.title-p {
  color: #242c33;
  font-size: 13px;
  font-weight: 500;
}

.project-desc-fixed {
  height: 88px;
  overflow: hidden;
  font-size: 14px;
  /* font-family: "Poppins-Light"; */
  font-weight: 300;
  color: #242c33;
  margin-bottom: 30px;
  padding-top: 10px;
}

.project-desc p,
.project-desc ol,
.project-desc ul,
.project-desc li {
  font-size: 14px;
  color: #242c33;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
}

.project-desc-fixed ul,
.project-desc-fixed li,
.project-desc-fixed ol,
.project-desc-fixed p {
  font-size: 13px !important;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  color: #000;
  line-height: 20px !important;
}

.project-desc-fixed ul {
  list-style: disc;
}

.project-desc-fixed ol,
.project-desc-fixed ul {
  margin-left: 15px;
}

.project-desc-fixed ol li,
.project-desc-fixed ul li {
  padding: 4px 15px;
}

.project-desc-fixed p {
  line-height: 16px;
}

.icon-tags-finalize {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px 0px;
  height: 35px;
  overflow: hidden;
}

.icon-tags-finalize li {
  flex: 0 0 auto;
  margin: 5px 2px;
  padding: 2px 1px;
}

.icon-tags-finalize li a {
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  background-color: #fff;
  display: block;
  padding: 5px 8px;
  /* font-family: "Poppins-Light", sans-serif; */
  font-weight: 300;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  color: #242c33;
  display: flex;
  align-items: center;
}

.icon-tags-finalize li a img {
  height: 14px;
  width: auto;
  margin-right: 2px;
}

.project-block ul.icon-tags-finalize li a {
  background: #0db8c0;
  border: 1px solid #0fcacd;
  color: #fff;
}

.new-profile-details .tellurstory {
  font-size: 13px;
  /* font-family: "poppins-medium"; */
  font-weight: 500;
  /* text-transform: capitalize; */
  height: 120px;
  text-align: justify;
  line-height: 20px;
  overflow: hidden;
  margin-top: 30px;
}

.new-profile-img .profile {
  border: 10px solid #ffffff;
  width: 350px;
  height: 350px;
  overflow: hidden;
  max-width: none;
}

.new-profile-img .fresher {
  margin: 10px;
}

.fresher {
  width: 100px !important;
  position: absolute;
}

input[type="checkbox"].short-check {
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  margin: 60px 10px 0px 0px;
  visibility: visible;
}

.spotlight {
  display: block;
  position: relative;
  width: 100%;
  background-color: #f6feff;
  padding: 50px 0px;
}

.spotlight span {
  width: 100%;
  max-width: 220px;
  position: relative;
  display: inline-block;
  background: #fff;
  border: 1px solid #d3d5e0;
  border-radius: 6px;
  text-align: center;
  -webkit-box-shadow: 2px 5px 19px -3px rgb(211, 230, 231);
  -moz-box-shadow: 2px 5px 19px -3px rgb(211, 230, 231);
  box-shadow: 2px 5px 19px -3px rgb(211, 230, 231);
  transition: all 0.3s ease-in-out;
  margin: 5px;
  padding: 0;
}

.spotlight span img {
  width: 220px;
  height: 220px;
  overflow: hidden;
  border-radius: 6px;
}

.spotlight span h5 {
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 2px 5px 2px;
  font-size: 13px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  color: #146169;
  line-height: 18px;
}

.ql-video {
  width: 100%;
  height: 60vh;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-editor {
  min-height: 200px;
  overflow-y: scroll;
  resize: vertical;
}

.ql-container .ql-editor {
  height: 500px;
  overflow: scroll;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.spotlight span p {
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  color: #424b54;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  padding: 0px 0px 2px 0px;
  height: 42px;
  overflow: hidden;
}

.spotlight span:hover {
  transition: all 0.3s ease-in-out;
  opacity: 0.7;
}

.cjm {
  margin: 20px;
}

.new-profile-img {
  position: relative;
}

.new-profile-img::before {
  content: "";
  position: absolute;
  bottom: 20px;
  left: 10px;
  z-index: 2;
  width: 32px;
  height: 32px;
  background: url(assets/img/profile-brand.png) no-repeat;
  background-size: cover;
}

.new-profile-img-after::before {
  content: "";
  position: absolute;
  bottom: 60px;
}

.apply-now {
  font-size: 12px;
  color: #fff;
  background: #3ba0aa;
  text-transform: capitalize;
  padding: 7px 20px;
  border-radius: 32px;
  outline: none;
  border: none;
  text-decoration: none;
  line-height: 30px;
  transition: all 0.3s ease-in-out;
}

.apply-now:hover {
  background: #196f78;
  transition: all 0.3s ease-in-out;
}

span.company-name {
  font-size: 12px;
  /* font-family: "Poppins-Light"; */
  font-weight: 300;
  color: #000;
}

.job-primary-info {
  display: block;
  position: relative;
  margin-bottom: 10px;
}

.job-primary-info span {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  background-color: #f0f9f9;
  border: 1px solid #ddf3f3;
  padding: 0px 10px;
  border-radius: 32px;
}

.job-primary-info span figure {
  display: inline;
  padding-right: 5px;
  vertical-align: middle;
}

.job-primary-info span p {
  display: inline;
  position: relative;
  font-size: 10px;
  color: #233030;
  line-height: 16px;
  padding: 0;
  margin: 0;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  text-transform: uppercase;
}

.job-primary-info span.refresh {
  background-color: #d7f2e3;
  border: none;
  margin-top: 10px;
}

#message-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
}

#message-overlay .message-box {
  width: 100%;
  max-width: 350px;
  margin: 200px auto 0px auto;
  padding: 30px 20px 10px;
  background: #fff;
  border-radius: 16px;
  text-align: center;
  display: block;
  border: 1px solid #f8f8f8;
  -webkit-box-shadow: 13px 11px 56px -12px rgba(145, 145, 145, 1);
  -moz-box-shadow: 13px 11px 56px -12px rgba(145, 145, 145, 1);
  box-shadow: 13px 11px 56px -12px rgba(145, 145, 145, 1);
}

#message-overlay .message-box h6 {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  padding: 5px 0px;
  margin: 0;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
}

#message-overlay .message-box p {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #000;
  padding: 0;
  margin: 0;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
}

#message-overlay .message-box figure img {
  width: 140px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between !important;
}

.cursor {
  cursor: pointer;
}

.Toastify__toast--error {
  border-left: 5px solid #f15d3b !important;
  border-radius: 15px !important;
  background: #fbf4f3 !important;
}

.Toastify__toast--error::after {
  /* content : url('../assets/images/svg/closeToast.svg'); */
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast--error::before {
  /* content: url("../assets/images/svg/errorToast.svg"); */
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast--success {
  border-left: 5px solid #21b444 !important;
  border-radius: 15px !important;
  background: #f0f9fa !important;
}

.Toastify__toast--success::before {
  /* content: url("../assets/images/svg/successToast.svg"); */
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast--success::after {
  /* content : url('../assets/images/svg/closeToast.svg'); */
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast--warning {
  border-left: 5px solid #e78326 !important;
  border-radius: 15px !important;
}

.Toastify__toast--warning::before {
  /* content: url("../assets/images/svg/warnToast.svg"); */
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast--warning::after {
  /* content : url('../assets/images/svg/closeToast.svg'); */
  position: absolute;
  color: #e78326;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast--info {
  border-left: 5px solid #3074e8 !important;
  border-radius: 15px !important;
  background: #f6fbff !important;
}

.Toastify__toast--info::before {
  /* content: url("../assets/images/svg/warnToast.svg"); */
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast--info::after {
  /* content : url('../assets/images/svg/closeToast.svg'); */
  position: absolute;
  color: #3074e8;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast-body {
  color: #444c63;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
}

.Toastify__toast > button > svg {
  display: none;
}

.Toastify__toast--success .Toastify__toast-body {
  color: #21b444;
}

.Toastify__toast--error .Toastify__toast-body {
  color: #f15d3b;
}

.Toastify__toast--info .Toastify__toast-body {
  color: #506c89;
}

/*duplicate css start*/
.wizard-block {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(145 158 171 / 20%) 0px 3px 3px -2px,
    rgb(145 158 171 / 14%) 0px 3px 4px 0px,
    rgb(145 158 171 / 12%) 0px 1px 8px 0px;
  background-image: none;
  padding: 20px;
  margin-top: 20px;
  border-radius: 34px;
  width: 100%;
}

.progress-bar {
  display: block;
  padding: 15px;
}

.progress-track {
  display: block;
  background: linear-gradient(
    180deg,
    #d8dbdc 0%,
    rgba(202, 216, 223, 0.28) 100%
  );
  border-radius: 5px;
  height: 12px;
}

.current-progress {
  background: linear-gradient(180deg, #5d8c2c 0%, rgba(49, 87, 10, 0.54) 100%);
  border-radius: 5px;
  height: 12px;
  width: 25%;
  transition: all 0.5s ease-in-out;
}

.bar-completed {
  font-size: 12px;
  /* font-family: "Poppins-Light", sans-serif; */
  font-weight: 300;
  text-transform: uppercase;
}

.tab-block {
  display: block;
  padding: 0 20px;
}

.tabs {
  display: flex;
}

.tabs .tab {
  flex: 1;
  border-right: 1px solid #475d6970;
}

.tabs .tab a {
  padding: 15px 15px;
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;
  background: linear-gradient(
    rgb(144, 163, 172) 0%,
    rgba(87, 110, 121, 0.7) 100%
  );
  height: 100%;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.tabs .tab a .num {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #fff;
  font-size: 14px;
}

.tabs .tab a .tab-icon {
  display: flex;
  margin: 0 auto 10px;
  width: 32px;
  height: 32px;
  border: 2px solid #e9f8fa;
  outline: none;
  border-radius: 50%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.tabs .tab a .tab-icon img {
  width: 60%;
}

.tabs .tab a h4 {
  color: #fff;
  font-size: 13px;
  /* font-family: "Poppins-Medium", sans-serif; */
  font-weight: 500;
  text-transform: uppercase;
  font-weight: normal;
}

/* .tabs .tab a:hover, */
.tabs .tab a.current {
  background: linear-gradient(
    rgb(43, 180, 193) 0%,
    rgba(11, 146, 159, 0.82) 100%
  );
  transition: all 0.5s ease-in-out;
}

.com-block {
  display: block;
  padding: 20px;
}

.grey-block {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgb(250, 250, 250);
  border-radius: 32px;
  border: 1px solid rgba(211, 220, 229, 0.5);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 16px;
  /* font-family: "Poppins-Medium", sans-serif; */
  font-weight: 500;
}

.timeline-form {
  margin-bottom: -10px;
}

.timeline {
  position: relative;
  padding-left: 40px;
}

.timeline:after {
  background-color: transparent;
  border-left: 1.2px dashed #7fa2c5;
  content: "";
  margin-left: -1px;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 1px;
  height: calc(100% - 10px);
}

.timeline-event {
  position: relative;
  font-size: 14px;
  /* font-family: "Poppins-Regular", sans-serif; */
  font-weight: normal;
  margin-bottom: 5px;
}

.timeline-event p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  margin-top: -5px;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
}

.timeline-event p a {
  color: #196f78;
}

.timeline-event .timeline-dot {
  background-color: #d2e2f2;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: -32px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-title {
  font-size: 16px;
  /* font-family: "Poppins-Medium", sans-serif; */
  /* font-weight: normal; */
  font-weight: 500;
  line-height: 28px;
}

.timeline-subtitle {
  color: #424957;
  font-size: 14px;
  font-weight: normal;
  /* font-family: "Poppins-Regular", sans-serif; */
  display: inline-block;
  padding-bottom: 15px;
}

.timeline-event .list {
  list-style-type: disc;
  padding-left: 20px;
}

.timeline-event .list li {
  margin-bottom: 10px;
}

.sub-sub-title {
  /* font-family: "Poppins-Medium", sans-serif; */
  font-style: normal;
  /* font-weight: normal; */
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}

.project-blocks {
  display: flex;
  grid-gap: 14px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.project-blocks .project-block {
  display: block;
  background: #fefeff;
  border: 1px solid rgba(211, 220, 229, 0.9);
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  /* font-weight: normal; */
  /* font-family: "Poppins-Light", sans-serif; */
  font-weight: 300;
  flex: 1 0 25%;
  max-width: calc(25% - 10px);
}

.project-blocks .project-block h5 {
  display: flex;
  /* font-family: "Poppins-Medium", sans-serif; */
  font-style: normal;
  /* font-weight: normal; */
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  color: #242c33;
  height: 18px;
  overflow: hidden;
}

.project-blocks .project-block .icon-tags {
  margin-bottom: 10px;
}

.project-block ul.icon-tags-finalize li a {
  background: #edf5fd;
  border: 1px solid #e0eaf5;
  color: #000;
}

.project-block-footer {
  display: flex;
}

.project-block-footer .frame {
  flex: 1;
}

.project-block-footer .frame img {
  vertical-align: -webkit-baseline-middle;
}

.project-block-footer a.link {
  font-size: 12px;
}

.project-block-footer .link {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.navigation .link {
  color: var(--theme-color);
  text-transform: uppercase;
  border: none;
  background: none;
  cursor: pointer;
}

.navigation .link.disabled {
  color: rgb(127, 131, 127);
}

.block-sub-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: 0;
}

.block-sub-heading h5 {
  font-size: 18px;
}

.block-sub-heading-paddingless {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: 0 0 0px;
}

.block-sub-heading h5 small {
  font-size: 18px;
  color: #7f837f;
  display: flex;
  /* font-family: "Poppins-Light", sans-serif; */
  font-weight: 300;
}

.note {
  font-size: 13px;
  text-transform: uppercase;
}

table .action {
  background-color: #f15d3b;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
}

.action-btn {
  background-color: #f15d3b;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  -webkit-box-shadow: 2px 5px 19px -3px rgba(248, 171, 154, 1);
  -moz-box-shadow: 2px 5px 19px -3px rgba(248, 171, 154, 1);
  box-shadow: 2px 5px 19px -3px rgba(248, 171, 154, 1);
}

table .action + .action {
  margin-left: 10px;
}

form {
  margin-bottom: 30px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 30px;
}

.project-list .card-wrap {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 5px;
  display: inline-flex;
}

.project-list .project-card {
  display: flex;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid rgba(211, 220, 229, 0.5);
  border-radius: 8px;
  padding: 15px;
  margin: 5px;
  box-shadow: none;
}

.project-list .project-card:hover {
  border: 1px solid rgba(211, 220, 229, 0.5);
  box-shadow: 0px 4px 5px rgba(109, 111, 109, 0.4);
  cursor: pointer;
}

.project-list .project-card h5 {
  /* font-family: "Poppins-SemiBold", sans-serif; */
  font-size: 14px;
  color: var(--theme-color);
  /* font-weight: normal; */
  font-weight: 600;
}

.project-list .project-card p {
  /* font-family: "Poppins-Regular", sans-serif; */
  /* font-size:16px; */
  /* color:var(--theme-color); */
  font-weight: normal;
  height: 78px;
  overflow: hidden;
  font-size: 14px;
}

.project-list .project-card .project-card-header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.project-list .project-card .project-card-header .edit-icon {
  display: inline-flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-color);
  border-radius: 50%;
  -webkit-box-shadow: 2px 5px 19px -3px rgba(141, 223, 231, 1);
  -moz-box-shadow: 2px 5px 19px -3px rgba(141, 223, 231, 1);
  box-shadow: 2px 5px 19px -3px rgba(141, 223, 231, 1);
}

.edit-icon-del {
  display: inline-flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-color);
  border-radius: 50%;
  -webkit-box-shadow: 2px 5px 19px -3px rgba(141, 223, 231, 1);
  -moz-box-shadow: 2px 5px 19px -3px rgba(141, 223, 231, 1);
  box-shadow: 2px 5px 19px -3px rgba(141, 223, 231, 1);
}

.project-list .project-card .project-card-header .edit-icon img {
  width: 60%;
}

.project-list .project-card .attached {
  color: #aaaaaa;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.project-list .project-card .attached i {
  padding-right: 5px;
  display: flex;
  align-items: center;
}

.project-list .project-card .attached i svg {
  width: 18px;
}

/* .project-list .project-card .tags{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
} */
.project-list .project-card .tags li a {
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.tech-tags li {
  flex: 0 0 auto;
  padding: 0 5px 10px;
}

.tech-tags li > div {
  display: flex;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 60px;
  padding: 3px 10px;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
}

.tech-tags li > div i {
  flex: 0 0 30px;
  max-width: 30px;
}

.tech-tags li > div span {
  flex: 1;
}

.tech-tags li:hover > div {
  border-color: var(--theme-color);
  color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}

.portfolio-slider {
  display: flex;
}

.portfolio-slider ul {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}

.portfolio-slider ul li {
  flex: 1 0 33.3333%;
  max-width: 33.3333%;
  border: 10px solid #fff;
  box-shadow: 0px 4px 5px rgba(109, 111, 109, 0.2);
}

.portfolio-slider ul li img {
  width: 100%;
}

.form-group.middle label {
  margin: 0 0 0 15px;
}

.skill-tags {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.skill-tags .tags {
  flex: 1 0 auto;
  margin: 0;
  height: 70px;
  overflow: hidden;
}

.skill-tags .btn-block {
  flex: 0 0 100px;
  max-width: 100px;
  margin: 0;
}

.portfolio-slider {
  display: flex;
  margin-bottom: 30px;
}

.word-limit {
  font-size: 14px;
  font-weight: 300;
}

/* .profile-preview{

} */
.profile-wrap {
  display: flex;
  margin-bottom: 30px;
}

.profile-wrap .profile-img {
  flex: 0 0 240px;
  max-width: 240px;
}

.profile-wrap .profile-img figure {
  display: block;
  border-radius: 15px;
  overflow: hidden;
}

.profile-wrap .profile-img figure img {
  width: 100%;
  object-fit: cover;
}

.profile-wrap .profile-details {
  flex: 1;
  display: flex;
  padding: 0 20px 20px;
  flex-direction: column;
}

.rate-avaiable {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.rate-avaiable .star-rating {
  display: flex;
  align-items: center;
}

.rate-avaiable .star-rating img {
  margin-right: 5px;
}

.profile-wrap .profile-details .user-name {
  /* font-family: "Poppins-Medium", sans-serif; */
  font-weight: 500;
  font-size: 20px;
}

.profile-wrap .profile-details .user-designation {
  color: #7f837f;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tags li {
  flex: 0 0 auto;
  padding: 5px;
  margin: 0;
}

.tags li a {
  display: flex;
  padding: 3px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  color: #242c33;
}

.tags li a:hover,
.tags li a.active {
  border-color: var(--theme-color);
  color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -5px;
}

.tech-tags li {
  flex: 0 0 auto;
  padding: 0 5px 10px;
}

.tech-tags li > div {
  display: block;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 60px;
  padding: 3px 10px;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
}

.tech-tags li > div img {
  flex: 0 0 20px;
  max-width: 20px;
}

.tech-tags li > div span {
  vertical-align: top;
  display: inline-block;
  padding-left: 5px;
  min-height: 20px;
  padding-top: 2px;
}

.tech-tags li:hover > div {
  border-color: var(--theme-color);
  color: var(--theme-color);
  transition: all 0.3s ease-in-out;
}

.test-card {
  box-sizing: border-box;
  display: inline-flex;
  background-color: rgb(249, 254, 255);
  border-radius: 12px;
  border: 1px solid rgba(211, 220, 229, 0.5);
  box-shadow: rgb(236 245 254 / 69%) 0px 4px 4px;
  padding: 8px;
}

.test-card .skill-img {
  flex: 0 0 50px;
  max-width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.test-card .skill-img figure {
  border: 1px solid rgb(219, 216, 216, 0.6);
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-card .skill-img figure img {
  width: 30px;
  height: auto;
}

.test-card .skill-img .test-logo {
  width: 20px;
  height: 20px;
}

.result-logo {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* .result-logo img {
  width: 100px;
  height: auto;
} */

.test-card .skill-detail {
  width: 125px;
  padding: 0 10px 0 10px;
  position: relative;
  text-align: left;
}

.test-card .skill-detail h5 {
  height: 36px;
  overflow: hidden;
  line-height: 18px;
  font-size: 14px;
  cursor: pointer;
}

.test-card .skill-detail h6 {
  word-wrap: break-word;
  font-size: 14px;
  /* font-family: "Poppins-Medium", sans-serif; */
  /* font-weight: normal; */
  font-weight: 500;
  margin-bottom: 2px;
}

.test-card .skill-detail p {
  margin-bottom: 0px;
  font-size: 13px;
}

.test-card .skill-detail .link {
  text-transform: capitalize;
  font-size: 12px;
  cursor: text;
}

.test-card-stack {
  box-sizing: border-box;
  display: inline-flex;
  background-color: rgb(249, 254, 255);
  border-radius: 12px;
  border: 1px solid rgba(211, 220, 229, 0.5);
  box-shadow: rgb(236 245 254 / 69%) 0px 4px 4px;
  padding: 8px;
  /* width: 100%; */
  margin: 6px;
}

.test-card-stack .skill-img-stack figure {
  border: 1px solid rgb(219, 216, 216, 0.6);
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.test-card-stack .skill-detail-stack {
  width: 175px;
  padding: 0 10px 0 10px;
  position: relative;
  text-align: left;
}

.test-card-stack .skill-detail-stack h6 {
  word-wrap: break-word;
  font-size: 14px;
  /* font-family: "Poppins-Medium", sans-serif; */
  /* font-weight: normal; */
  font-weight: 500;
  margin-bottom: 2px;
  height: 42px;
  overflow: hidden;
}

.test-card-stack .skill-detail-stack p {
  margin-bottom: 7px;
  font-size: 13px;
}

.btn-block {
  display: flex;
  padding: 0px;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.stack-map-div {
  display: flex;
}

.skill-map-div {
  display: flex;
  justify-content: space-between;
}

.danger,
.success {
  /* font-family: "Poppins-Regular", sans-serif; */
  font-weight: 400;
}

.status-icon {
  position: absolute;
  right: 0px;
  top: 0px;
}

.status-icon img {
  width: 18px;
  height: 18px;
}

.search-block {
  position: relative;
}

.search-block .form-control {
  padding-right: 30px;
}

.search-block .search-btn {
  position: absolute;
  right: 20px;
  top: 7px;
  width: 20px;
  height: 20px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 15px;
}

.project-list .card-wrap {
  flex: 0 0 33.3333%;
  max-width: 33.33333%;
  padding: 5px;
}

.project-list .project-card {
  display: flex;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid rgba(211, 220, 229, 0.5);
  box-shadow: 0px 4px 5px rgba(109, 111, 109, 0.2);
  border-radius: 32px;
  padding: 15px;
  margin: 5px;
  width: 100%;
}

.project-list .project-card h5 {
  /* font-family: "Poppins-SemiBold", sans-serif; */
  font-weight: 600;
  font-size: 16px;
  color: var(--theme-color);
  /* font-weight: normal; */
}

.project-list .project-card .project-card-header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.project-list .project-card .project-card-header .edit-icon {
  display: inline-flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-color);
  border-radius: 50%;
}

.project-list .project-card .project-card-header .edit-icon img {
  width: 60%;
}

.project-list .project-card .attached {
  color: #aaaaaa;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.project-list .project-card .attached i {
  padding-right: 5px;
  display: flex;
  align-items: center;
}

.project-list .project-card .attached i svg {
  width: 18px;
}

.project-list .project-card .tags li a {
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}

.project-list .project-card .view-detail {
  display: flex;
  align-items: center;
}

.portfolio-slider {
  display: flex;
  margin-bottom: 30px;
}

.study {
  display: block;
  margin-bottom: 15px;
}

.study h5 {
  /* font-family: "Poppins-Medium", sans-serif; */
  font-weight: 500;
  font-size: 14px;
}

.tools {
  /* font-family: "Poppins-Medium", sans-serif; */
  font-weight: 500;
  color: #6d6f6d;
}

.tools table tr td figure {
  display: flex;
  align-items: center;
  color: #242c33;
}

.tools table tr td figure img {
  width: 40px;
}

.technical-skills {
  margin-bottom: 30px;
}

.technical-skills table tr td figure {
  display: flex;
  align-items: center;
  color: #242c33;
}

.certifications table {
  font-size: 12px;
}

.certifications h6 {
  font-size: 13px;
}

.certifications p {
  margin-bottom: 0;
}

.certifications table td {
  padding: 5px;
}

.certifications table tr td:last-child {
  vertical-align: bottom;
}

.highlight {
  margin-bottom: 20px;
}

.highlight h6 {
  font-size: 14px;
  color: var(--theme-color);
}

.highlight .tags {
  margin-bottom: 10px;
}

.tool-info-pp {
  font-size: 16px;
}

.tool-info-pp-date {
  font-size: 14px;
}

.acad-item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.list-text {
  font-size: 14px;
  /* font-weight: normal; */
  /* font-family: "Poppins-Light", sans-serif; */
  font-weight: 300;
  display: flex;
  flex-direction: column;
  color: #7f837f;
}

.list-text-new {
  font-size: 14px;
  /* font-weight: normal; */
  /* font-family: "Poppins-Light", sans-serif; */
  font-weight: 300;
  display: flex;
  flex-direction: row;
  color: #7f837f;
}

ul.list-text-new {
  margin-left: 20px;
}

ul.list-text-new li {
  margin-bottom: 15px;
  list-style: disc;
  color: #000;
}

.list-text-new li span {
  display: block;
}

.list-text-new li span {
  display: block;
}

.list-text li strong {
  font-weight: normal;
  /* font-family: "Poppins-Regular", sans-serif; */
  display: block;
  color: #000;
}

.list-text-new li strong {
  font-weight: normal;
  /* font-family: "Poppins-Regular", sans-serif; */
  display: block;
  color: #242c33;
}

.tech-skill-title strong {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-top: 15px;
  display: inline-block;
}

.new-profile-wrap {
  display: block;
  padding: 20px;
}

.color-orange {
  color: #f15d3b;
}

.color-green {
  color: #679b31;
}

.read-more {
  color: #3ba0aa;
}

/* Profile Top */
.profile-top {
  display: flex;
  width: 100%;
  /* background: url(assets/img/profile-theme-bg.jpg) no-repeat top center; */
  background: url("https://ik.imagekit.io/olibr/assets/tr:w-1082,h-200/profile-theme-bg.jpg")
    no-repeat top center;
  padding: 50px;
  border-radius: 12px;
  padding-bottom: 0px;
  background-size: contain;
}

.hirenow-bg {
  display: block;
  width: 100%;
  /* background: url(assets/img/hirenow-bg.jpg) no-repeat top left; */
  background: url("https://ik.imagekit.io/olibr/assets/tr:w-1082,h-132/hirenow-bg.jpg")
    no-repeat top left;
  padding: 20px;
  background-size: cover;
  text-align: center;
  margin: 10px 0px 15px 0px;
}

.hirenow-bg h4 {
  color: #196f78;
  text-transform: uppercase;
  padding-bottom: 10px;
  display: block;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
}

.profile-top .profile-img {
  flex: 0 0 140px;
  max-width: 140px;
}

.new-profile-wrap .new-profile-img figure {
  display: block;
  border-radius: 15px;
  overflow: hidden;
}

.new-profile-wrap .new-profile-img figure img {
  width: 100%;
  object-fit: cover;
}

.new-profile-wrap p {
  font-size: 14px;
}

.profile-top .new-profile-details {
  flex: 1;
  padding: 10px 20px 0px 20px;
  background-color: #fff;
}

.profile-top .new-profile-details .user-name {
  font-size: 20px;
  /* font-weight: normal; */
  line-height: 1.5;
  text-transform: capitalize;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  color: #000;
  margin-top: 10px;
}

.profile-top .new-profile-details .user-designation {
  font-size: 16px;
  /* font-weight: normal; */
  line-height: 23px;
  text-transform: capitalize;
  color: #242c33;
  margin-bottom: 0px;
  /* font-family: "Poppins-Light"; */
  font-weight: 300;
  text-transform: uppercase;
}

.profile-top .new-profile-details .candidate-avaiable {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  text-transform: capitalize;
  /* font-family: "Poppins-Regular"; */
}

.profile-top .new-profile-details .candidate-avaiable strong {
  /* font-family: "Poppins-Medium"; */
  /* font-weight: normal; */
  font-weight: 500;
}

.profile-top .user-status {
  font-size: 16px;
  line-height: 24px;
  /* font-family: "Poppins-Regular"; */
  font-weight: normal;
  float: right;
}

.profile-top .user-status strong {
  /* font-family: "Poppins-Medium"; */
  /* font-weight: normal; */
  font-weight: 500;
}

/* Tags */
.icon-tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 10px;
}

.icon-tags li {
  flex: 0 0 auto;
  margin: 2px;
  padding: 5px;
}

.icon-tags li a {
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  background-color: #fff;
  display: block;
  padding: 5px 12px;
  /* font-family: "Poppins-Regular", sans-serif; */
  font-weight: 400;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  color: #000;
  display: flex;
  align-items: center;
}

.icon-tags li a img {
  height: 14px;
  width: auto;
  margin-right: 5px;
}

/* Skill table */
.skill-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 32px;
}

.skill-table tr th {
  font-size: 14px;
  /* font-weight: normal; */
  /* font-family: "Poppins-Medium", sans-serif; */
  font-weight: 500;
  text-align: left;
  padding: 5px;
}

.skill-table tr th.proficiency {
  width: 128px;
  max-width: 128px;
}

.skill-table tr th.experience {
  width: 90px;
  max-width: 90px;
}

.skill-table tr td {
  font-size: 14px;
  /* font-weight: normal; */
  /* font-family: "Poppins-Light", sans-serif; */
  font-weight: 300;
  text-align: left;
  padding: 5px;
}

.skill-table.certifications tr td span {
  display: block;
  color: #7f837f;
}

.skill-table.certifications tr td strong {
  display: block;
  color: #000;
  /* font-family: "Poppins-Regular", sans-serif; */
  font-weight: normal;
}

.c-icon,
.angular-icon,
.django-icon,
.slack-icon,
.invision-icon,
.selenium-icon,
.react-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.align-middle {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.vetted-skills {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 14px;
}

.vetted-skill {
  display: block;
  background: #fafcff;
  border: 1px solid #e6effd;
  box-shadow: 0px 4px 6px 4px rgba(212, 223, 238, 0.12),
    0px 4px 6px 4px rgba(234, 242, 254, 0.12);
  border-radius: 4px;
  padding: 14px;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}

.vetted-skills .badge {
  position: absolute;
  right: 10px;
  top: -18px;
}

.vetted-skills h5 {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  color: #242c33;
  text-align: center;
}

.vetted-skills .skill-graph {
  margin-bottom: 10px;
}

.circle-wrap {
  margin: 15px auto 20px;
  width: 65px;
  height: 65px;
  background: #e6e2e7;
  border-radius: 50%;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 65px;
  height: 65px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 65px, 65px, 75px);
}

.circle-wrap .circle .mask .fill {
  clip: rect(0px, 75px, 60px, 0px);
  background-color: #3ba0aa;
}

.circle-wrap .circle .mask.full,
.circle-wrap .circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(126deg);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(126deg);
  }
}

.circle-wrap .inside-circle {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #fff;
  line-height: 55px;
  text-align: center;
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 14px;
  color: #3ba0aa;
}

.vetted-skills .align-middle {
  font-size: 10px;
  color: #242c33;
}

.proj-desc {
  margin-top: 10px;
  padding: 0px;
}

.proj-desc p,
.proj-desc ul,
.proj-desc li,
.proj-desc div,
.proj-desc h1,
.proj-desc h2,
.proj-desc h3,
.proj-desc h4,
.proj-desc h5,
.proj-desc h6 {
  font-size: 13px;
  /* font-family: "poppins-medium"; */
  font-weight: 500;
}

.proj-desc ul {
  list-style: disc;
  padding-left: 20px;
  padding-bottom: 15px;
}

/*Global Table design css start here*/
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  border-bottom: 1px solid #d6dff4;
  border-collapse: collapse;
  text-align: left;
  font-size: 12px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  color: #000;
  padding: 5px;
  /* font-weight: normal; */
  background: rgb(246, 247, 252);
}

.table td {
  font-size: 12px;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  border-bottom: 1px solid #ecf2ff;
  color: #000;
  padding: 8px 5px;
}

.table tr {
  background: #fff;
}

.table tr:hover {
  background: rgb(246, 247, 252);
}

.table .column0 {
  text-align: center;
}

.table-border-bottom {
  border-bottom: 2px solid black;
}

.table-blog {
  width: 100%;
  min-width: 600px;
}

.table-div {
  overflow-x: auto;
}

/*Global Table design css End here*/
.submitBtn {
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  background-color: #3ba0aa;
  outline: none;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  padding: 8px 40px;
  color: white;
}

.submitBtn.redButton {
  margin-top: 15px;
  background-color: #f15d3b;
  border-radius: 5px;
  cursor: pointer;
}

.cancelBtn {
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  padding: 7px 40px;
  color: #f15d3b;
  border: 1px solid #f15d3b;
  margin: 0px 10px;
  cursor: pointer;
}

.themeButton {
  border: none;
  outline: none;
  padding: 8px 40px;
  color: #fff;
  background-color: #3ba0aa;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  transition: 0.2s background-color ease-out;
  border-radius: 5px;
  font-size: 14px;
}

.themeButton:hover {
  background-color: #2f686d;
  cursor: pointer;
}

.main-div-tab {
  margin: 0px 0px;
  padding: 0px;
}

.tabContainer {
  margin: 15px 0px 0px 0px;
  padding: 0px 0px;
  background-color: #f5fafb;
  display: flex;
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  align-items: center;
}

.tabBtn {
  outline: none;
  border: none;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  font-size: 14px;
  padding: 12px 30px;
  background-color: #f5fafb;
  color: #83a1a7;
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  transition: 0.2s background-color ease-out, 0.2s color ease-out;
}

.tabBtn-active {
  outline: none;
  border: none;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  font-size: 14px;
  padding: 12px 30px;
  color: #223b3e;
  background-color: #cee7ea;
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  transition: 0.2s background-color ease-out, 0.2s color ease-out;
}

.tabBtn:hover,
.tabBtn-active:hover {
  cursor: pointer;
}

.circlePlus img {
  padding: 0;
  margin: 0;
  border-radius: 100px;
}

.circlePlus {
  border-radius: 100px;
  background-color: #3ba0aa;
  padding: 0;
  margin: 0;
}

.add-group-btn {
  margin-bottom: 10px;
}

.adornment {
  position: relative;
}

.browseImage {
  position: absolute;
  top: 10px;
  right: 15px;
  border: none;
  outline: none;
  background-color: white;
  color: #3ba0aa;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 21px;
}

.browseImage-verify {
  position: absolute;
  top: -20px;
  right: 0px;
  color: #f70f0f;
  font-size: 12px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 1px 3px;
}

.browseNoTop {
  top: 5px;
  margin-top: 5px;
}

/* .browseImageNew {
  top: 5px;
  margin-top: 0;
} */

.bottom-button {
  text-align: right;
}

.mt30 {
  margin-top: 30px;
}

.between-center {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.selectFaq {
  margin-right: 5px;
  font-size: 14px;
}

.img-loader {
  /* width: 100%; */
  text-align: center;
  margin-top: 30px;
}

.fullHeight {
  height: 60px;
  overflow: hidden;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 15px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  color: #000;
}

.fullHeight-perm {
  font-size: 13px !important;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  color: #000;
  line-height: 20px !important;
}

.fullHeight-perm ul,
.fullHeight-perm li,
.fullHeight-perm ol,
.fullHeight-perm p {
  font-size: 13px !important;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  color: #000;
  line-height: 20px !important;
}

.fullHeight-perm ul {
  list-style: disc;
}

.fullHeight-perm ol,
.fullHeight-perm ul {
  margin-left: 15px;
}

.fullHeight-perm ol li,
.fullHeight-perm ul li {
  padding: 4px 15px;
}

.fullHeight-perm p {
  line-height: 16px;
}

.company-down {
  margin-bottom: 10px;
}

.margin-30 {
  margin-bottom: 30px;
}

.h-icon img {
  width: 24px;
  vertical-align: middle;
  margin-right: 7px;
}

.list-text li {
  padding-bottom: 10px;
}

#profile-preview h4 {
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
}

.mb-10 {
  margin-bottom: 10px;
}

.eng-test-block {
  display: block;
  max-width: 1000px;
  margin: 0px auto;
  width: 100%;
}

/*duplicate css End*/

.partner-logo {
  display: block;
  border-top: 1px solid #f0f5f6;
  border-bottom: 1px solid #f0f5f6;
  padding: 10px 0;
}

.partner-logo ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
}

.partner-logo ul li {
  display: block;
  flex: 0 0 14.2857%;
  max-width: calc(14.2857% - 10px);
}

.partner-logo ul li span {
  display: block;
  padding: 15px;
  margin: 5px;
}

.testimonial {
  display: block;
  padding: 80px 20px;
  background: url("https://ik.imagekit.io/olibr/assets/tr:w-1600,h-371/testimonials-bg.webp")
    no-repeat center center;
  background-size: cover;
  color: #fff;
}

.testimonial__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 30px;
  position: relative;
}

.testimonial__text:before {
  position: absolute;
  /*content:"";*/
  background: url("./assets/img/icons/left-quotes.svg") no-repeat left top;
  height: 50px;
  width: 50px;
  top: 0;
  left: 0;
}

.testimonial__text h2 {
  font-size: 24px;
  color: #fff;
  text-align: center;
}

.testimonial__text .tag {
  background-color: #fff;
  border-radius: 15px;
  display: inline-block;
  padding: 5px 15px;
}

.open-profileview {
  background-color: #e9e9e9;
  padding-bottom: 20px;
}

.project-title-section {
  display: block;
  width: 100%;
  /* background: url(assets/img/project-theme-bg.webp) no-repeat top center; */
  background-image: linear-gradient(
    to bottom,
    #d4f3f1,
    #d8f4f2,
    #ddf5f4,
    #e1f6f5,
    #e5f7f6
  );
  background-size: cover;
  min-height: 290px;
}

.pro-pad {
  padding: 25px;
}

.project-primary-info {
  background: #f6fbfe;
  display: block;
  padding: 15px 10px 0px 10px;
  box-sizing: border-box;
}

.project-title-section h2 {
  display: block;
  margin: 80px 0px 0px 0px;
  padding: 0px 0px 0px 20px;
  font-size: 20px;
  /* font-family: "Poppins-SemiBold"; */
  font-weight: 600;
  color: #000;
}

.project-primary-info h6 {
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: #000;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  text-transform: capitalize;
  line-height: 20px;
}

.project-primary-info p {
  font-size: 14px;
  color: #393e43;
  line-height: 20px;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  margin: 0;
  padding: 0px 0px 20px 0px;
}

.post-content h1 {
  font-size: 28px;
}

.post-content h2 {
  font-size: 24px;
}

.post-content h3 {
  font-size: 20px;
}

.post-content h4 {
  font-size: 18px;
}

.post-content h5 {
  font-size: 14px;
}

.post-content h5 {
  font-size: 12px;
}

.post-content p a,
.blog-content p a {
  color: #3ba0aa !important;
  text-decoration: underline;
}

.post-content {
  text-align: justify;
  text-justify: inter-word;
}

#blog-details-id h1 {
  font-size: 28px;
}

#blog-details-id h2 {
  font-size: 24px;
}

.scroll-to-top {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.scroll-to-top:hover {
  background-color: #555;
}

.vrf-email {
  display: block;
  padding: 5px;
}

.vrf-email h5 {
  font-size: 14px;
}

p.red {
  color: #ff0808;
}

.number-arrow-hide::-webkit-outer-spin-button,
.number-arrow-hide::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.bg-red-button {
  background-color: #f15d3b !important;
  color: white;
  font-size: 13px;
  /* font-family: "poppins-light"; */
  font-weight: 300;
  /* font-weight: 100; */
}

#blog-details-id .sidebar-header h4 img {
  vertical-align: middle;
  margin-right: 5px;
}

.tellYourStory-btn {
  margin-right: 12px;
  margin-top: -5px;
}

.faqTabCursor {
  cursor: pointer;
}

@media (max-width: 768px) {
  .profile-top {
    padding: 0;
    display: block;
  }

  .new-profile-img img {
    width: 100% !important;
    height: auto !important;
  }

  .header-banner-h4 {
    margin-left: 20px;
  }

  .test-list li {
    max-width: 50%;
  }

  .new-profile-wrap {
    padding: 0 10px;
  }

  .vetted-skill {
    width: 100%;
  }

  .project-blocks .project-block {
    flex: 1 0 50% !important;
    max-width: calc(100% - 10px) !important;
  }
}

.tellIcon {
  margin-left: 15px;
}

.maintenance {
  background-color: #fbe6c7;
  padding: 20px 40px;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  text-align: center;
}

.lnndaO {
  white-space: pre-line !important;
}

/* 
help footer

*/
.help-footer {
  background-color: white;
  height: 40px;
  width: 100%;
  border-top: 1px solid #c6c6c6;
  margin-bottom: 6px;
  position: fixed;
  z-index: 1999;
  bottom: -14px;
  left: 0;
}

.help-footer a {
  color: #6b6b6b;
  line-height: 16.94px;
  font-size: 16px;
  height: 17px;
  vertical-align: text-top;
  padding-left: 2px;
}

.help-footer div {
  margin-top: 6px;
}

.help-footer span {
  color: #6b6b6b;
  line-height: 16.94px;
  font-size: 16px;
  height: 17px;
  vertical-align: text-top;
  padding-left: 2px;
  padding-right: 2px;
}

.help-footer span:hover {
  cursor: pointer;
}

.help-footer .vr {
  border-left: 2px solid gray;
  height: 50px;
}

.help-footer .mr-5 {
  margin-right: 5px;
}

.dark-background {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2000;
  opacity: 80%;
  left: 0px;
  top: 0px;
}

.story-update-icon {
  z-index: 2001;
  position: relative;
}

.story-question-icon img {
  z-index: 2001;
  position: relative;
  border-radius: 50%;
  background-color: white;
  width: 26px !important;
  height: 26px !important;
}

.add-project-icon {
  z-index: 2001;
  position: relative;
  background-color: white;
}

.tour-story {
  z-index: 2002;
  color: #3ba0aa;
  right: 100px;
  width: 297px;
  position: absolute;
  text-align: left;
  top: 319px;
}

.tour-story .tour-story-heading {
  width: 407px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #6cf2ff;
  padding-left: 36px;
}

.tour-story .tour-quest-heading {
  width: 407px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #6cf2ff;
  padding-left: 36px;
}

.tour-story .circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  color: #3ba0aa;
  float: left;
  margin-left: 0px;
  font-size: 14px;
  padding: inherit;
  font-weight: bold;
}

.tour-tell-descr {
  clear: both;
  color: white;
  text-align: left;
  width: 320px;
}

.tour-next-btn {
  width: 99px;
  height: 28px;
  left: 1001px;
  border: 2px dotted #73f3ff;
  border-radius: 50px;
  font-size: 16px;
  line-height: 19px;
  background-color: transparent;
  color: #6cf2ff;
  cursor: pointer;
}

.tour-skip-btn {
  width: 99px;
  height: 28px;
  left: 1001px;
  border: 2px dotted #73f3ff;
  border-radius: 50px;
  font-size: 16px;
  line-height: 19px;
  background-color: transparent;
  color: #6cf2ff;
  margin-left: 10px;
  cursor: pointer;
}

.display-none {
  display: none;
}

.questionMark {
  z-index: 9999;
  color: #3ba0aa;
  margin-left: -10px;
  width: 297px;
  position: absolute;
  text-align: left;
}

.questionMark .tour-quest-heading {
  width: 407px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #6cf2ff;
  padding-left: 36px;
}

.questionMark .circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  color: #3ba0aa;
  float: left;
  margin-left: 0px;
  font-size: 14px;
  padding: inherit;
  font-weight: bold;
}

.tour-camera-icon svg {
  z-index: 2001;
  position: relative;
  border-radius: 50%;
  background-color: white;
  padding-top: 2px;
  width: 22px !important;
  height: 22px !important;
}

.camera-area {
  z-index: 9999;
  color: #3ba0aa;
  margin-left: -10px;
  width: 297px;
  position: absolute;
  text-align: left;
  margin-top: 276px;
}

.camera-area .tour-quest-heading {
  width: 407px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #6cf2ff;
  padding-left: 36px;
}

.camera-area .circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  color: #3ba0aa;
  float: left;
  margin-left: 0px;
  font-size: 14px;
  padding: inherit;
  font-weight: bold;
}

.a-button {
  appearance: auto;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  color: #6cf2ff;
}

.my-review .content {
  /* height: 276px; */
  /* left: 366px; */
  /* top: 243px; */
  background: #fafafa;
  border: 1px solid rgba(211, 220, 229, 0.5);
  /* margin: 15px 21px; */
  border-radius: 12px;
  text-align: center;
  padding: 30px 50px;
}

.my-review .content p {
  /* width: 960px; */
  /* font-style: normal; */
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #041213;
  /* margin: 31px auto; */
}

.my-review .heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding: 11px 22px;
}

.review-form input {
  margin: 10px;
}

.review-form textarea {
  margin: 10px;
}

.text-danger {
  color: #f15d3b !important;
}

.text-warning {
  color: #ff9905 !important;
}

.text-success {
  color: #3ba0aa !important;
}

.text-inactive {
  color: #8f98a8 !important;
}

.text-error {
  color: red !important;
}

.right-aligned {
  text-align: right;
}

.search-section {
  background-color: #d8f1f3;
  position: relative;
  display: block;
  padding-bottom: 40px;
}

.search-f {
  max-width: 800px;
  width: 100%;
  margin: 0px auto;
  padding: 20px;
  box-sizing: border-box;
}

.filter-row {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.filter-row .themeButton {
  display: block;
  width: 100%;
  margin-top: 15px;
  text-align: center;
}

/* .search-section input,
.search-section select {
  box-sizing: border-box;
  height: 48px;
  font-family: "Poppins-Light";
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
  color: #9f9f9f;
  -webkit-box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 0.53);
  -moz-box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 0.53);
  box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 0.53);
} */

/* .search-box-block {
  background-color: #f15d3b;
  width: 72px;
  height: 48px;
  float: left;
  position: relative;
  margin-left: -8px;

  border-radius: 8px;
  font-size: 28px;
  color: #fff;
  text-align: center;
  line-height: 54px;
  transition: all 0.3s ease-in-out;
}
.search-box-block:hover {
  transition: all 0.3s ease-in-out;
  background-color: #3ba0aa;
} */

.search-box-block img {
  width: 24px;
  height: 24px;
  margin: 15px 22px auto;
}

.search-f .nopadding {
  padding: 0;
}

.search-cat-list {
  background: #f2f5fa;
  /* height: 67px; */
}

.search-cat-list .item {
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  padding: 10px 20px;
  display: inline-block;
  color: #5f5f5f;
  cursor: pointer;
}

.search-cat-list .active {
  color: #000000;
  background-color: #fff;
  padding: 10px 20px;
  height: max-content;
  width: max-content;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
}

.search-filter-box {
  background: #ffffff;
  border: 1px solid #d5e1ef;
  border-radius: 4px;
  margin-top: 22px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 20px;
  width: 100%;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.search-filter-box .filters {
  color: #000000;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  display: inline-block;
  margin: 0;
}

.search-filter-box .filter {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #f15d3b;
}

.search-filter-box label {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.grid-container {
  display: grid;
  grid-template: 150px 150px 150px 150px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  border-radius: 4px;
}

.grid-container-2 {
  display: grid;
  grid-template: 100px 100px 100px;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  border-radius: 4px;
}

.grid-container > div {
  border: 1px solid #e0eaf5;
  text-align: center;
  padding: 20px 0;
}

.grid-container-2 > div {
  border: 1px solid #e0eaf5;
  text-align: center;
  padding-top: 20px;
  font-size: 30px;
}

.grid-margin {
  margin-top: 10px;
  margin-bottom: 5px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
}

/* .trending-block {
  background-color: #ffefcd;
  padding: 20px;
  margin: 10px -15px;
}

.trending-job {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
  color: #000;
}

.job-type-block {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 10px 15px 5px 15px;
  font-family: "Poppins-Medium";
  margin-bottom: 10px;
}

.job-type-block > div {
  display: flex;
  justify-content: space-between;
}

.job-type-block > div .view-job {
  font-size: 14px;
  text-transform: capitalize;
  color: #e9610c;
  font-family: "Poppins-Medium";
}
.job-type-block > div .view-job a {
  color: #e9610c;
}
.job-type-block > div .view-job a:hover {
  text-decoration: underline;
}

.job-type-block > div .arrow {
  font-size: 20px;
}

.type-name {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  font-family: "Poppins-Medium";
} */

.job-card-holder {
  background-color: #f2f5fa;
  margin-top: 20px;
  padding: 20px 20px 30px 40px;
  margin-bottom: 25px;
}

.job-card-holder h3 {
  font-size: 20px;
  color: #000;
}

.job-listing-section .skill {
  margin-bottom: 15px;
  margin-top: -10px;
}

.input-checkbox {
  display: inline-block;
  position: relative;
}

.input-checkbox input[type="checkbox"] {
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  visibility: visible;
  position: absolute;
  z-index: 10;
  top: -40px;
  left: -30px;
}

.jl-btn img,
.jl-btn svg {
  width: 32px;
  height: 32px;
  float: right;
  margin: 0px 10px;
}

.jl-btn a {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #9196b7;
  border: 1px solid #cad9ea;
  float: right;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.jl-btn a:hover {
  color: #146169;
  transition: all 0.3s ease-in-out;
  border: 1px solid #146169;
}

.jl-logo {
  position: relative;
}

.jl-logo span {
  width: 50px;
  height: 50px;
  background: #fff;
  overflow: hidden;
  -webkit-box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 0.53);
  -moz-box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 0.53);
  box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 0.53);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 20px;
  top: -23px;
  text-align: center;
  line-height: 50px;
}

.job-postedby-block h5 {
  color: #000;
  font-size: 14px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  text-transform: capitalize;
}

.job-postedby-block h5 span {
  font-size: 12px;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
}

.job-postedby-block .user {
  position: relative;
  display: block;
  flex-basis: 50px;
}

.job-postedby-block .user img {
  width: 50px;
  height: 50px;
}

.job-postedby-block .posted-by {
  flex: 1;
  padding: 0 15px;
}

.popular-job {
  position: relative;
}

.pj-row {
  display: block;
  width: 100%;
  padding: 10px 10px 3px 10px;
  margin-top: 15px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #d5e1ef;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.pj-row h4 {
  font-size: 12px;
  line-height: 18px;
  color: #000;
  padding: 0;
  margin: 0px 0px 0px 0px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
}

.pj-row a {
  font-size: 12px;
  color: #3ba0aa;
  line-height: 20px;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
}

.pj-row a:hover {
  color: #0c484e;
}

.pj-row .logo,
.jd-content .logo {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  border: 1px solid #d2e2f2;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: #fff;
}

.jd-content {
  display: block;
  width: 100%;
  padding: 20px;
  background-color: #d8f1f3;
  border-radius: 4px;
  margin-bottom: 20px;
}

.jd-content h1 {
  font-size: 20px;
}

.jd-content .logo {
  width: 120px;
  height: 120px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.jd-content .logo img {
  width: 100%;
  height: auto;
}

.jd-content .skill li {
  background-color: #fff;
}

.search-f h1 {
  font-size: 31px;
  padding: 40px 0px 0px 0px;
}

.next-prev-job span {
  color: #3ba0aa;
}

@media (max-width: 768px) {
  .search-box-block {
    width: 100%;
    margin: 20px 0px 0px 0px;
  }
}

/* MainBox CSS */
.job-actions {
  display: flex;
  background-color: #3f3f3f;
  padding: 15px;
  width: 100%;
  margin-bottom: 32px;
}

.job-actions .btn-block {
  margin: 0;
  flex-basis: auto;
  width: auto;
}

.job-actions .txt {
  flex: 1;
  display: flex;
  align-items: center;
  color: #fff;
}

.job-actions .txt p {
  margin: 0;
  color: #fff;
  /* font-family: "Poppins-Regular"; */
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
}

.job-actions .btn-block .btn {
  border: none;
  min-width: 130px;
  height: 44px;
  /* font-family: "Poppins-Medium"; */
  font-size: 14px;
  /* font-weight: normal; */
  font-weight: 500;
  line-height: 21px;
}

/* Trending Job */
.trending-block {
  padding: 20px;
  background-color: #ffefcd;
  color: #000;
  margin-bottom: 20px;
}

.trending-block .trending-title {
  /* font-family: "Poppins-Medium"; */
  font-size: 20px;
  /* font-weight: normal; */
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 5px;
}

.trending-block .type-list {
  display: flex;
  grid-column-gap: 15px;
}

.trending-block .job-type {
  flex: 1;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  /* font-family: "Poppins-Medium"; */
  font-size: 14px;
  /* font-weight: normal; */
  font-weight: 500;
  line-height: 21px;
}

.trending-block .type-name {
  margin-bottom: 10px;
}

.trending-block .job-type a {
  color: #f37424;
  display: flex;
  justify-content: space-between;
}

/* .trending-block .job-type a span {} */

.trending-block .job-type a i {
  font-size: 20px;
  color: #a39f9f;
  font-style: normal;
}

.job-desc-block ul li::before {
  font-size: 25px;
  height: 10px;
  position: relative;
  top: 5px;
  margin-left: -15px;
  width: 20px;
}

.job-desc-block ul {
  display: flex;
  flex-direction: column;
}

/* .job-desc-block ul li {} */

.justify-content-left {
  justify-content: flex-start !important;
}

.justify-content-right {
  justify-content: flex-end !important;
}

.mw130 {
  min-width: 130px;
}

@media (max-width: 768px) {
  .trending-block .type-list {
    flex-direction: column;
  }

  .trending-block .job-type {
    margin-bottom: 20px;
  }

  #job-list .job-card-holder {
    padding: 20px;
  }
}

.flex {
  display: flex;
}

/* Job Listing */
.card-header.job-head {
  display: flex;
}

.card-header.job-head h4 {
  flex: 1;
}

.card-header.job-head .header-right a {
  border: none;
  width: auto;
  height: auto;
  box-shadow: none;
  padding: 5px 10px;
}

.toggle-list {
  display: flex;
  flex-direction: column;
}

.toggle-list .toggle-item {
  border-bottom: 1px solid #d2e2f2;
  padding: 20px 0;
}

.toggle-list .toggle-item h3 {
  font-size: 20px;
  /* font-family: "Poppins-Medium"; */
  /* font-weight: normal; */
  font-weight: 500;
  line-height: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.toggle-list .toggle-item h3 a {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.toggle-list .toggle-item h3 a i {
  font-style: normal;
  font-size: 30px;
  color: #3ba0aa;
}

.toggle-list .toggle-item h3 small {
  font-size: 12px;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
}

/* .toggle-list .toggle-item .toggle-content {} */

.toggle-list .tabs {
  display: flex;
  position: relative;
  flex-direction: column;
}

.toggle-list .tabs .nav {
  display: flex;
  border-bottom: 2px solid #e1e9fb;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.toggle-list .tabs .nav li a {
  color: #242c33;
  position: relative;
  display: block;
  padding: 10px 15px;
  font-size: 14px;
  /* font-family: "Poppins-Medium"; */
  /* font-weight: normal; */
  font-weight: 500;
  transition: all 0.5s ease-in-out;
}

.toggle-list .tabs .nav li a:hover,
.toggle-list .tabs .nav li a.active {
  color: #3ba0aa;
  transition: all 0.5s ease-in-out;
}

.toggle-list .tabs .nav li a.active:after {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #3ba0aa;
  position: absolute;
  bottom: -2px;
  left: 0;
  transition: all 0.5s ease-in-out;
}

.toggle-list .tabs .tab-details {
  width: 100%;
}

.job-posted .link {
  margin-left: 15px;
  text-transform: uppercase;
}

.job-category {
  display: flex;
  padding-bottom: 30px;
}

.job-category .user-img {
  flex-basis: 150px;
  max-width: 150px;
  height: 150px;
  border-radius: 8px;
  border: 1px solid #d3dce550;
  overflow: hidden;
}

.job-category .user-img img {
  object-fit: cover;
  height: 150px;
  border-radius: 8px;
}

.job-category .user-details {
  flex: 1;
  padding: 0 20px 20px;
}

.job-category .block-title {
  font-size: 16px;
  /* font-family: "Poppins-Medium"; */
  /* font-weight: normal; */
  font-weight: 500;
  line-height: 36px;
}

.job-category .block-title small {
  font-size: 12px;
  /* font-family: "Poppins-regular"; */
  font-weight: normal;
  line-height: 18px;
}

.job-category .block-title h4 {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.job-category .icon-tags {
  margin-bottom: 5px;
}

.job-category .icon-tags li a {
  font-size: 12px;
  line-height: 21px;
  border-color: #e5e5e5;
}

.job-category .availablity {
  position: relative;
  padding-left: 20px;
  font-size: 12px;
  /* font-family: "Poppins-light"; */
  /* font-weight: normal; */
  font-weight: 300;
  line-height: 18px;
}

.job-category .availablity:before {
  position: absolute;
  left: 5px;
  top: 5px;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3ba0aa;
}

.shortlists {
  flex-basis: 100px;
  position: relative;
  justify-content: center;
  padding: 15px 14px 0;
  display: flex;
  grid-gap: 15px;
}

.shortlists .user-icon {
  position: relative;
  right: 0px;
  top: 0px;
}

.shortlists .check-icon,
.shortlists .cross-icon {
  position: absolute;
  right: -5px;
  top: 3px;
}

.shortlists .check-icon {
  right: -8px;
}

.shortlists .ico-group.pending .user-icon,
.shortlists .ico-group.pending .check-icon {
  filter: grayscale(1);
}

.shortlists .ico-group.rejected .check-icon {
  display: none;
}

.shortlists .ico-group.rejected .cross-icon {
  display: block;
}

.shortlists .ico-group.rejected .user-icon,
.shortlists .ico-group.rejected .cross-icon {
  filter: hue-rotate(156deg) contrast(2.7);
}

.shortlists [data-tooltip]:not([data-flow])::after {
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%) translateY(0px);
}

.reject-tooltip-s [data-tooltip]:not([data-flow])::after {
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%) translateY(0px);
}

.shortlists .watch-icon,
.shortlists .calander-icon,
.shortlists .cross-icon,
.shortlists .circle-check {
  display: none;
}

.shortlists .ico.calander .calander-icon {
  display: block;
}

.shortlists .ico.watch .watch-icon {
  display: block;
}

.shortlists .ico.circle-check .circle-check {
  display: block;
  fill: #27959f;
}

#jobs .tech-list {
  height: 50px;
  overflow: hidden;
}

#jobs .icon-tags {
  height: 46px;
  overflow: hidden;
}

.job-listing-section .applynow {
  font-size: 16px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  margin-top: -11px;
  display: block;
}

.job-det,
.save-job:hover path {
  fill: #3ba0aa;
}

.job-det,
.save-job:hover {
  color: #3ba0aa;
}

.job-det,
.save-job-active path {
  color: #3ba0aa;
  fill: #3ba0aa;
}

.image-gallery-content .image-gallery-image {
  width: 720px;
  height: 480px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.info-right .applynow {
  position: absolute;
  display: inline-block;
  top: 0px;
  font-size: 12px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  right: 0;
}

.company-profile .profile-deatil h3 span {
  display: inline-block;
  vertical-align: middle;
}

.jd-content .text-right {
  text-align: right;
  font-size: 12px;
  float: right;
}

.client-map {
  max-width: 580px;
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  justify-content: center;
  margin-top: 30px;
  text-align: center;
}

.client-map .cm-card img {
  text-align: center;
  padding: 15px 0px;
}

.client-map .cm-card {
  max-width: 245px;
  height: 140px;
  border: 1px solid #d6ddee;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  flex: 1 0 240px;
  padding: 10px;
  -webkit-box-shadow: -2px 5px 4px 0px rgba(212, 223, 249, 1);
  -moz-box-shadow: -2px 5px 4px 0px rgba(212, 223, 249, 1);
  box-shadow: -2px 5px 4px 0px rgba(212, 223, 249, 1);
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.client-map .cm-card:hover {
  background-color: #3ba0aa;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.cm-outer {
  background: #f8faff;
  height: 600px;
  padding-top: 50px;
}

.client-map .cm-card:hover h2 {
  color: #fff;
}

.client-map .cm-card h2 {
  font-size: 18px;
  width: 100%;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
}

.user-location {
  font-size: 12px;
  color: #000;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  word-break: break-all;
}

.user-location span {
  display: inline-block;
  padding-right: 4px;
  margin-top: 2px;
  /* vertical-align: middle; */
}

#account-setting {
  display: block;
  position: relative;
  font-size: 14px;
  color: #000;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  margin-top: 20px;
}

#account-setting .setting-card {
  background: #f3f7ff;
  padding: 20px;
  display: block;
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 15px;
}

#account-setting .setting-card h2,
#account-setting .setting-card h3,
#account-setting .setting-card h4 {
  display: block;
  width: 100%;
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  margin-bottom: 24px;
}

#account-setting .setting-card h3 {
  font-size: 16px;
  margin-bottom: 0px;
}

#account-setting .setting-card h3 span {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

#account-setting .setting-card h4 {
  font-size: 14px;
  padding: 0;
  margin: 14px 0px 0px 0px;
}

#account-setting .setting-card p:not(.colored-p) {
  font-size: 16px;
  color: #000;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

#account-setting .setting-card p.noti {
  font-size: 14px;
}

#account-setting .setting-card .logout {
  font-size: 16px;
  text-transform: capitalize;
  color: #f15d3b;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
}

#account-setting .setting-card .logout:hover {
  text-decoration: underline;
}

#account-setting .setting-card label {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  #account-setting .setting-card p:not(.colored-p) {
    margin-bottom: 20px;
  }

  .job-listing-section .applynow {
    margin-top: 10px;
    text-align: left;
  }

  .jl-btn svg,
  .jl-btn img {
    text-align: left;
    float: left;
  }

  .search-f h1 {
    font-size: 20px;
    padding: 0px 0px 0px 0px;
  }

  .jd-content .text-right {
    text-align: right;
    font-size: 12px;
    width: 100%;
    display: block;
  }

  .order {
    order: 1;
  }

  .search-section {
    padding-bottom: 0;
  }

  #account-setting .setting-card p.noti {
    margin-bottom: 0;
  }
}

.job-det-popular p {
  color: #3f3f3f;
  /* font-family: Poppins-Regular; */
  font-size: 13px;
  font-weight: 400;
  height: auto;
  line-height: 20px;
  margin: 4px 0;
  text-align: left;
  text-transform: capitalize;
}

.job-det-popular span {
  color: #3ba0aa;
  font-size: 12px;
  line-height: 18px;
  margin-right: 5px;
  vertical-align: top;
}

.description-block-job p ul {
  list-style: disc;
  padding-left: 20px;
}

.css-lm8j94-menu {
  z-index: 99999 !important;
}

.zero-mp {
  padding: 0;
  margin-bottom: 0;
}

.mr5 {
  margin-right: 5px;
}

.client-message {
  border: 1px solid #d4eef2;
  padding: 15px;
  border-radius: 5px;
  background: #e6fafd;
  font-size: 12px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  -webkit-box-shadow: 3px 5px 10px -2px #d2e2f2;
  -moz-box-shadow: 3px 5px 10px -2px rgba(181, 175, 181, 1);
  box-shadow: 3px 5px 10px -2px #d2e2f2;
  width: 50%;
  flex: 0 0 100%;
  margin-top: 20px;
  text-align: center;
}

.min-width177 {
  min-width: 177px;
}

.subtip {
  position: relative;
  display: inline-block;
  margin: 5px 4px;
  vertical-align: middle;
}

.subtip .subtiptext {
  visibility: hidden;
  width: 200px;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  /* font-family: Poppins-Regular; */
  font-weight: 400;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: -140%;
  margin-left: -60px;
  -webkit-box-shadow: 3px 5px 34px -2px rgba(181, 175, 181, 1);
  -moz-box-shadow: 3px 5px 34px -2px rgba(181, 175, 181, 1);
  box-shadow: 3px 5px 34px -2px rgba(181, 175, 181, 1);
}

.subtip .subtiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.subtip:hover .subtiptext {
  visibility: visible;
}

.subtip img {
  height: 14px;
}

.pre-next-btn-text {
  color: #3ba0aa;
  cursor: pointer;
}

.flex-row-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.item-row-gap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.vh-center-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.flex-space-between-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.c-box {
  background: #232e35;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  padding: 20px;
  width: 360px;
  -webkit-box-shadow: 10px 10px 58px -1px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 10px 10px 58px -1px rgba(0, 0, 0, 0.44);
  box-shadow: 10px 10px 58px -1px rgba(0, 0, 0, 0.44);
}

.camera-area .c-box {
  margin: 45px 34px;
}

.c-box .num {
  position: relative;
  font-size: 12px;
  line-height: 22px;
  display: block;
  text-align: left;
}

.c-box .num span {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  color: #000;
  line-height: 24px;
  text-align: center;
  /* font-family: 'Poppins-Medium'; */
  font-weight: 500;
  font-size: 12px;
  float: none;
}

.c-box h2,
.c-box p {
  color: #fff;
  text-align: left;
  margin-bottom: 7px;
}

.c-box h2 {
  font-size: 14px;
  padding: 0;
  margin: 5px 0px 5px 0px;
  /* font-family: 'Poppins-Medium'; */
  font-weight: 500;
}

.c-box p {
  font-size: 12px;
  line-height: 18px;
}

.c-box--arrow-left {
  position: absolute;
  z-index: 10;
  left: 200px;
  margin-top: 0px;
}

/* .c-box--arrow-left::after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  z-index: 10;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  margin-top: -10px;
  top: 50%;
  border-right: 10px solid #232e35;
  left: -10px;
} */
.c-box--arrow-top {
  position: relative;
  z-index: 10;
}

.c-box--arrow-top::after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  z-index: 10;
  border: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-left: -10px;
  left: 62%;
  border-bottom: 10px solid #232e35;
  top: -10px;
}

.questionMark .c-box--arrow-top::after {
  left: 48% !important;
}

.tour-story .c-box--arrow-top::after {
  left: 82% !important;
}

.education-area .c-box--arrow-top::after {
  left: 37% !important;
}

.education-area {
  margin-top: -22px !important;
}

.employement-area .c-box--arrow-top::after {
  left: 76% !important;
}

.add-project-sec {
  top: 277px !important;
}

.tool-section .c-box--arrow-top::after {
  left: 26% !important;
}

.tools-area .c-box--arrow-top::after {
  left: 19% !important;
}

.skills-area .c-box--arrow-top::after {
  left: 23% !important;
}

.create-profile .tool-section {
  top: unset;
  right: 327px;
}

.create-profile .socialProfile-area {
  margin-top: 48px;
}

.socialProfile-area .c-box--arrow-top::after {
  left: 54% !important;
}

.create-profile .achievement-area {
  top: unset;
  right: 323px;
}

.achievement-area .c-box--arrow-top::after {
  left: 47% !important;
}

span.skip {
  text-align: left;
  cursor: pointer;
  display: inherit;
  float: left;
  font-size: 12px;
  margin-top: 4px;
}

span.skip:hover {
  text-decoration: underline;
}

.c-box a.btn {
  color: #fff;
  border: none;
  border-radius: 32px;
  padding: 4px 15px;
  font-size: 12px;
}

.skillmh {
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 24px;
  /* font-family: 'Poppins-Medium'; */
  font-weight: 500;
  background: #cef5fc;
  border-radius: 8px;
  border: 1px solid #a1e8f4;
  padding: 20px;
  color: #000;
}

.skillmh a {
  text-transform: uppercase;
  color: #f84545;
  letter-spacing: 1px;
  border-bottom: 2px solid #f84545;
  /* font-family: "Poppins-SemiBold"; */
  font-weight: 600;
}

.ss-click {
  font-size: 14px;
  /* font-family: 'Poppins-Regular'; */
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #b5dee5;
  padding: 10px;
  background: #e2eff2;
  margin: 10px 0px;
  color: #000;
}

#profile-edit svg {
  height: 30px;
  width: 28px;
}

.update-req {
  display: flex;
}

.update-req .update-req-span {
  margin-top: 5px;
  margin-left: 10px;
}

.copy-link-btn {
  padding: 7px 12px;
  box-sizing: border-box;
  cursor: pointer;
  color: #3ba0aa;
  border: 1px solid #3ba0aa;
  line-height: 20px;
  border-radius: 50px;
  height: 36px;
  font-size: 13px;
  margin-top: 12px;
}

.copy-link-btn img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.copy-link-btn img {
  vertical-align: middle;
  height: 20px;
}

.cover-tem {
  display: flex;
  align-items: center;
  gap: 25px;
}

.toggle-item h3 a span {
  font-size: 16px;
  line-height: 20px;
}

.client-view-profile .label {
  margin-top: -23px;
  margin-left: 164px;
}

.client-view-profile .clear-view {
  color: #f15d3b;
  padding: 7px;
}

.detail-header-right .clear-view-img {
  height: 23px;
}

.company-profile .c-box--arrow-top {
  position: absolute;
}

.company-profile .profile-banner {
  margin-top: 33px;
}

.company-profile .c-box--arrow-top::after {
  left: 25% !important;
}

.photo-gide-area {
  margin-top: 2px;
}

.photo-gide-area .c-box--arrow-top::after {
  left: 69% !important;
}

.tool-story-area .c-box--arrow-top::after {
  left: 48% !important;
}

.c-box--arrow-top {
  position: absolute;
}

.tell-your-story-area {
  right: 24px;
  margin-top: 18px;
}

.tell-your-story-area::after {
  left: 81% !important;
}

.education-area-dev::after {
  left: 35% !important;
}

.employment-tour-dev::after {
  left: 74% !important;
}

.project-tour-area {
  right: 0px;
  margin-top: 40px;
}

.project-tour-area::after {
  left: 83% !important;
}

.cert-area-tour::after {
  left: 46% !important;
}

.skills-tour-area::after {
  left: 21% !important;
}

.tool-tour-section::after {
  left: 22% !important;
}

.social-area-dev-tour::after {
  left: 47% !important;
}

.achievement-tour-area::after {
  left: 40% !important;
}

.setting-jobs-skill .select-text {
  font-size: 12px !important;
  margin: 9px 0 !important;
}

.setting-jobs-skill .select-text input {
  width: 12px;
  height: 12px;
  visibility: visible;
  margin-right: 5px;
  margin-top: 7px;
}

.setting-jobs-skill .toggle-item {
  border-bottom: unset;
  padding: unset;
}

.stack-btn-setting {
  padding: 12px 1px !important;
}

.jp {
  font-size: 12px !important;
  /* font-family: "Poppins-Medium" !important; */
  font-weight: 500 !important;
}

.user-c {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fa-star {
  transition: all 0.1s ease-in-out;
}

.fa-star:hover {
  transform: scale(0.9);
  color: #e6ab2d;
}

.rating-checked {
  color: #faba2e;
}

.rating-checked-no-padding {
  margin-right: 0 !important;
}

.rating-unchecked {
  color: #adadad;
}

.rate-head {
  font-size: 15px;
  font-weight: 600;
}

.rating-stars {
  margin-top: 10px;
}

.rating-stars .para {
  font-size: 13px;
  font-weight: 600;
}

.fa-star {
  margin: 8px;
}

#review-panel {
  display: block;
  background: #f6fbfe;
  padding: 15px;
  border: 1px solid #d0dded;
  border-radius: 8px;
  margin-bottom: 15px;
}

#review-panel span .user img {
  position: relative;
  display: block;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  overflow: hidden;
}

#review-panel h4 {
  font-size: 16px;
  color: #000;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: 20px;
  text-align: center;
  padding-top: 10px;
}

#review-panel .designation {
  font-size: 12px;
  margin: 0px;
  padding: 0;
  line-height: 16px;
  text-align: center;
  margin-bottom: 20px;
}

#review-panel .message {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  margin-top: 5px;
  letter-spacing: 1px;
  text-align: center;
}

#review-panel ul {
  display: block;
  list-style: none;
  text-align: center;
}

#review-panel ul li {
  background: #fff;
  border: 1px solid #c7e5eb;
  border-radius: 32px;
  padding: 7px 15px;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
  color: #000;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
  box-shadow: -2px 2px 26px 9px rgba(209, 229, 233, 0.75);
  -webkit-box-shadow: -2px 2px 26px 9px rgba(209, 229, 233, 0.75);
  -moz-box-shadow: -2px 2px 26px 9px rgba(209, 229, 233, 0.75);
}

#review-panel ul li span {
  display: inline-block;
  margin-left: 15px;
  /* font-family: "Poppins-Semibold"; */
  font-weight: 600;
}

#review-panel ul li span img {
  height: 12px;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .user-c {
    flex-direction: column;
  }

  .user-c .user-location {
    width: 100%;
  }

  .banner-section {
    margin-bottom: 0;
  }

  #mySidenav {
    width: 75% !important;
  }

  .my-review .content {
    padding: 10px 20px;
  }

  .my-review .content p {
    font-size: 13px;
    line-height: 24px;
  }
}

.skills-management .skill-group-btn {
  margin-right: 3px;
}
#review-panel ul li {
  display: block;
  padding: 7px 20px;
}

#review-panel ul li span {
  float: right;
}

.review-modal p {
  font-size: 13px;
  font-weight: 600;
}

.comment-date {
  display: inline-block;
  background-color: #d7f2e3;
  padding: 4px 14px;
  color: #000000;
  font-size: 10px;
  border-radius: 50px;
}

i.blink {
  animation: blinker 1.5s linear infinite;
  color: #ed2828;
  /* font-family: 'Poppins-SemiBold'; */
  font-weight: 600;
  position: absolute;
  right: 40px;
}

.cm-btn {
  position: absolute;
  right: 50px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.job-listing .client-job-views {
  font-size: 12px;
  color: #7c9092;
}

.bottom-info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.job-time {
  justify-content: left;
  display: flex;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 10px;
}

.job-view {
  display: flex;
  justify-content: right;
  flex: 0 0 50%;
  max-width: 50%;
}

.main-detailed {
  padding: 20px;
}

.detailed-report-graph {
  width: 20%;
  margin: auto;
}

.detailed-report {
  height: 200px;
  border: 1px solid #e8e8f7;
  border-radius: 5px;
  padding: 25px 10px;
  text-align: center;
  background: #f7f7ff;
}

.score-circle {
  width: 20%;
  margin: auto;
}

.noti-box {
  background: #f6fbfe;
  border: 1px solid #d1e1ea;
  position: absolute;
  top: 65px;
  right: 70px;
  border-radius: 8px;
  z-index: 1;
  max-width: 350px;
  width: 100%;
  max-height: 360px;
  overflow: auto;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 13px 12px 49px 31px rgba(209, 225, 234, 0.57);
  -moz-box-shadow: 13px 12px 49px 31px rgba(209, 225, 234, 0.57);
  box-shadow: 13px 12px 49px 31px rgba(209, 225, 234, 0.57);
}

.noti-box ul {
  padding: 10px;
  display: block;
  list-style: none;
}

.noti-box ul li {
  padding: 10px;
  border-bottom: 1px solid #d8eaf5;
  font-size: 12px;
  color: #000;
  /* font-family: 'Poppins-Regular'; */
  font-weight: 400;
}

.noti-dot {
  color: #3ba0aa;
  padding: 2px;
  height: 3px;
  width: 3px;
}

/* .noti-box ul li::before {
  content: "\2022";
  color: rgb(138, 176, 234);
  font-weight: 100;
  margin-left: -1em;
  display: inline-block;
  position: absolute;
  font-size: 18px;
  line-height: 13px;
} */

.fa-angle-right {
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eef7fd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8eaf5;
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b5d1e3;
  border-radius: 16px;
}

/* For Firefox */
* {
  scrollbar-width: 5px;
  scrollbar-color: #d8eaf5 #eef7fd;
}

.noti-box ul li strong {
  width: 100%;
  display: block;
}

.noti-box ul li:hover,
.noti-box ul li.selected {
  /* background: #eef5f9; */
  transition: all 0.3s ease-in-out;
}

.job-posted-noti {
  background: #e1ecc8;
}

.shortlisted-noti {
  background: #e9f6dd;
}

.noti-box ul li .date {
  font-size: 10px;
  display: block;
  width: 100%;
  color: #6b808d;
  /* font-family: 'Poppins-Medium'; */
  font-weight: 500;
  padding-top: 5px;
}

.noti-header {
  font-size: 15px;
  color: #041213;
  background-color: #e9f6ff;
  height: 40px;
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  padding-left: 25px;
}

.noti-header span {
  color: #000000;
  /* font-family: 'Poppins-Regular'; */
  font-weight: 400;
}

.video-banner-bg {
  /* background: url("./assets/img/icons/video-banner.svg"); */
  background: url("https://ik.imagekit.io/olibr/icons/tr:w-883,h-164/video-banner.svg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  width: 100%;
  margin-bottom: 20px;
}

.videobanner-text {
  padding-left: 60px;
  padding-top: 60px;
}

.videobanner-text h3 {
  font-size: 20px;
}

.video-inst-box h4 {
  font-size: 24px;
  margin-bottom: 10px;
  /* font-family: "Poppins-SemiBold"; */
  font-weight: 600;
  text-transform: capitalize;
}

.video-inst p {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
}

.video-inst-box {
  padding: 20px 40px;
  background-color: #fff;
  border: 1px solid #b6d1ed;
  border-radius: 5px;
  margin-bottom: 10px;
}

.video-inst-box h4 {
  text-transform: capitalize;
  text-decoration: underline;
  color: #3ba0aa;
}

.video-inst-box ul li {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 7px;
}

.video-inst-box ul li::before {
  content: "\2022";
  color: #7fd2da;
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

@media (max-width: 768px) {
  .noti-box {
    right: 0;
    max-width: none;
    width: 100%;
  }
}

.new-profile-img .share-video-icon {
  display: block;
  width: 94%;
  padding: 10px 0px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 160px;
  text-align: center;
  margin-left: 3%;
  z-index: 1;
}

.share-video-icon img {
  width: 42px;
  height: 42px;
  border: none;
  cursor: pointer;
}

.plus-icon-job {
  background-color: #3ba0aa;
  position: relative;
  left: 48px;
}

.minus-icon-job {
  top: 29px;
}

.css-1h54aiu-control {
  border-color: #ccc !important;
}

.dr-logo {
  position: absolute;
  top: -58px;
}

.dr-logo img {
  width: 120px;
}

#detail-report tbody tr td {
  border-bottom: 5px solid #f0f3fa;
}

#detail-report thead tr th {
  color: #134150;
  background: #d4f1f4;
}

.suspicious-bg {
  background: #f2fcfe !important;
}

.bar-loader {
  display: inherit;
  position: relative;
  width: 200px !important;
  margin: auto !important;
  height: 4px;
  overflow: hidden;
  background-color: rgba(59, 160, 170, 0.2);
  background-clip: padding-box;
}

.skill-test-outer {
  background: #f8faff;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 1px solid #f5f8ff;
}

.upper-line {
  border-top: thin solid #e2e2e2;
}

.profile-preview-spinner {
  max-height: 108px;
  margin: 2px auto;
  margin-bottom: 10px;
}

.profile-preview-spinner .percentage {
  fill: #3ba0aa;
  /* font-family: 'Poppins-SemiBold'; */
  font-weight: 600;
}

.input-img {
  height: 24px;
  position: absolute;
  top: 30px;
  left: 10px;
}

.job-apply-banner {
  min-height: unset;
  /* background-image: url("./assets/img/icons/apply-now-bg.svg"); */
  background-image: url("https://ik.imagekit.io/olibr/icons/tr:w-864,h-156//apply-now-bg.svg");
}

.job-apply-banner .redButton {
  margin-bottom: 45px;
}

.job-apply-banner .videobanner-text {
  padding-top: 27px;
}

.job-details-open img {
  padding-top: 0px;
  margin: -2px 4px;
}

.job-details-open p {
  line-height: 12px;
}

.test-list .non-core {
  background-color: #e6ebec;
  font-size: 10px;
  padding: 2px 6px;
  color: #7c8d9b;
  /* font-family: "Poppins-SemiBold"; */
  font-weight: 600;
  border-radius: 12px;
  border: 1px solid #e6edf2;
  text-transform: capitalize;
  display: flex;
  position: absolute;
  margin: -21px 5px 10px 115px;
  z-index: 1;
}

#skill-explanation .accordion-faq {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

#skill-explanation .accordion-content-faq {
  /* padding: 10px 20px 0px 10px; */
  transition: 0.5s display linear;
  position: relative;
  z-index: 1;
  background-color: #f2fcfe;
  padding: 0px 10px;
  /* width: 100%; */
  /* border: 1px solid #F2FCFE;*/
}

#skill-explanation .accordion-content-faq p {
  background-color: #f2fcfe;
  position: absolute;
  padding: 10px 20px;
  display: block !important;
  width: 100% !important;
}

.skill-test-description {
  color: #242c33;
  text-align: right;
  /* font-family: 'Poppins-Medium'; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.skill-test-description b {
  color: #f63b0f;
}

.skill-description {
  color: #242c33;
  /* font-family: 'Poppins-Medium'; */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin: 10px;
}

#candidate .skill-description b {
  color: #258993;
}

#candidate .toggle-list .toggle-item {
  margin-bottom: 15px;
  padding: 15px 15px 10px 15px;
  background: #d4f1f4;
  display: block;
}

#candidate .toggle-list .toggle-item h3 a {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  color: #000;
  /* font-family: 'Poppins-SemiBold'; */
  font-weight: 600;
}

#candidate .toggle-content {
  background: #fff;
  padding: 15px;
}

#candidate .job-category {
  display: flex;
  padding-bottom: 0px;
  border-bottom: 1px solid #e1e9fb;
  margin-bottom: 20px;
}

#candidate .candi-icon {
  display: flex;
  width: 100%;
}

#candidate .candi-icon span {
  vertical-align: middle;
  margin-right: 5px;
}

.result-message {
  display: flex;
  align-items: center;
  vertical-align: middle;
  /* transform: translateY(-50%); */
  padding-bottom: 40px;
  margin-left: 20px;
}

.flex {
  display: flex;
  gap: 10px;
}

.jobProfileComment {
  padding: 15px 0px 0;
}

.jobProfileFeedBack {
  padding: 15px 0px 0;
  /* flex-basis: 153px; */
  flex-basis: 25%;
  justify-content: flex-end;
}

.clear-feedback {
  height: 10px;
  cursor: pointer;
}

.sm-btn-candidates {
  font-size: 10px;
  padding: 4px 25px;
  margin-left: 5px;
  cursor: pointer;
}

.job-feedback {
  margin-right: 8px;
  vertical-align: middle;
}

.ico-candidate-comment path {
  filter: grayscale(1);
}

.close-text-details {
  color: #757b9c !important;
  font-size: 18px !important;
  color: red !important;
  /* font-family: 'Poppins-Medium' !important; */
  font-weight: 500 !important;
}

.signup-checkbox {
  margin: 20px -14px 1px;
}

.signup-checkbox .input-checkboxs {
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  visibility: visible;
}

.signup-checkbox p {
  margin-top: -4px;
  font-size: 12px;
  margin-left: -8px;
}

.signup-checkbox u {
  color: #e74c3c;
  cursor: pointer;
}

.term-condtions {
  height: 100px;
  width: 100%;
  border-bottom: 1px solid #e7edf4;
}

.terms-content-header {
  padding: unset;
}

.term-condtions .heading-term {
  padding: 17px 0px 0px;
  /* font-family: 'Poppins-Semibold'; */
  font-weight: 600;
}

.term-condtions-area .content-list {
  border: unset;
  background: #f2fcfe;
  margin: 20px;
  box-sizing: border-box;
  height: 500px;
  overflow: auto;
}

.term-condtions-area .title {
  font-size: 14px;
  color: #000;
  margin: 0;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

.term-condtions p {
  color: #000;
  /* font-family: 'Poppins-Regular'; */
  font-weight: 400;
}

.term-condtions-area .content-list p {
  font-size: 14px;
  color: #000;
  line-height: 24px;
  /* font-family: 'Poppins-Regular'; */
  font-weight: 400;
  border-bottom: 1px solid #e7edf4;
  padding-bottom: 20px;
}

.bg-warning {
  background-color: #f15d3b;
}

.term-condtions-area .video-inst-box ul li::before {
  color: #f15d3b;
}

.term-condtions-area .heading {
  margin-left: -14px;
  /* font-family: 'Poppins-Medium'; */
  font-weight: 500;
  font-size: 18px;
}

.add-job-form .plus {
  color: #3ba0aa;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  vertical-align: middle;
  cursor: pointer;
}

.add-job-form .add-head {
  color: #3ba0aa;
  /* font-family: Poppins-Light; */
  font-size: 16px;
  font-style: normal;
  /* font-weight: 600; */
  font-weight: 300;
  line-height: 38px;
  text-transform: capitalize;
  vertical-align: middle;
  cursor: pointer;
}

.questionaries-area .header-title {
  color: #242c33;
  /* font-family: Poppins-Medium; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.questionaries-area .checked-header {
  margin-right: 10px;
  vertical-align: middle;
  height: 15px;
}

.questionaries-header {
  padding: unset;
}

.questionaries-area {
  padding: 0px 15px;
}

.questionaries-area .header-section {
  height: 63px;
  background-color: #f0f4ff;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  padding-top: 12px;
}

.box-collapse-container {
  border: 1px solid #d4f1f4;
  margin-top: 15px;
}

.box-collapse-container .headers {
  background-color: #e1fcff;
}

.box-collapse-container .minus-1 {
  margin-right: 20px;
  /* font-family: 'Poppins-Medium'; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}

.box-collapse-lists .checkbox {
  visibility: visible;
  width: unset;
  height: unset;
}

.box-collapse-container .table {
  min-width: unset;
}

.box-collapse-container tbody {
  border: 1px solid #d4f1f4;
}

.box-collapse-container th {
  border-bottom: unset;
  background-color: #e1fcff;
  height: 44px;
}

.box-collapse-container td {
  border-bottom: unset;
}

.box-collapse-container .th-last {
  text-align: end;
  width: 65px;
  padding-right: 21px;
}

.box-collapse-container .round-check {
  visibility: visible;
  width: unset;
  height: unset;
}

.box-collapse-container .td-last-plus {
  /* font-family: Poppins-Medium; */
  font-size: 18px;
  font-style: normal;
  /* font-weight: 600; */
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

.box-collapse-container .hide {
  display: none;
}

.box-collapse-container .checked-header {
  margin-right: unset;
}

.questionaries-area .submitBtn {
  margin: 23px 0px 10px;
}

.questionari-lists {
  background-color: #d4f1f4;
  margin: 0px 13px;
  width: 97%;
}

.questionari-lists .heading {
  color: #000;
  /* font-family: Poppins-Medium; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 18px 5px 3px;
}

.questionari-lists .list {
  /* font-family: Poppins-Light; */
  font-size: 14px;
  font-style: normal;
  /* font-weight: 400; */
  font-weight: 300;
  line-height: 36px;
  margin: 6px 10px;
}

.questionari-lists .crossIcon {
  float: right;
  cursor: pointer;
}

.starText {
  color: red;
}

.question-list-dev .list {
  color: #263238;
  /* font-family: 'Poppins-Regular'; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 19px 13px;
}

.question-list-dev .input {
  margin: 4px 15px;
  width: 97%;
  height: 37px;
  border-radius: 8px;
  border: 1px solid #b2d3d6;
}

.question-list-dev .border-red {
  border: 1px solid red;
}

.fourteen {
  font-size: 14px;
  color: #227c85;
}

.box-collapse-container .small-btn {
  padding: 7px 15px;
  font-size: 10px;
  margin-top: 10px;
}

.box-collapse-container .th-end {
  width: 0px;
  text-align: center;
}

.box-collapse-container .full-width {
  width: 100%;
}

.box-collapse-container .td {
  padding: 8px 8px 0px;
}

.box-collapse-container .border-red {
  border: 1px solid red;
}

.box-collapse-container .adQuestion {
  margin: 0px 7px;
  padding: 0px 4px;
  font-size: 11px;
  cursor: pointer;
}

.questionP {
  margin-left: 10px;
}

.add-que-img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 7px;
  vertical-align: middle;
}

.term-condtions-area .term-head {
  margin-left: -16px;
  padding: 0px;
  border-bottom: none !important;
  font-size: 18px !important;
  /* font-family: 'Poppins-Medium' !important; */
  font-weight: 500 !important;
  padding-bottom: 0px !important;
}

.term-condtions-area .inner-p {
  font-size: 14px;
  margin-left: 2px;
  margin-top: 6px;
  padding-bottom: unset !important;
}

.term-condtions-area .inner-li {
  margin-left: 17px;
  font-weight: unset;
}

.term-condtions-area .remove-p {
  padding-bottom: unset !important;
  border-bottom: none !important;
}

.term-condtions-area .nstd-li {
  margin-left: 32px;
  font-weight: unset;
}

#skill-explanation .accordion-content-faq ul li {
  padding: 7px;
  color: #000;
}

.linkedin-btn {
  background-color: #0073af;
}

.linkedin-btn .icon {
  margin-top: -4px;
  font-size: 21px;
  border-right: 1px solid #757575;
  padding-right: 8px;
  left: 16px;
  position: absolute;
}

.linkedin-area .hr {
  margin: 26px 1px;
  width: 99%;
}

.linkedin-area .circle {
  margin-top: -42px;
  position: absolute;
  margin-left: 152px;
  height: 30px;
  width: 30px;
  background-color: #eff5fc;
  color: black;
  padding-top: 10px;
  /* font-family: 'Poppins-Regular'; */
  font-weight: 400;
  font-size: 11px;
}

#term-condtion .content-list {
  /* background: unset; */
  height: unset;
  overflow: unset;
}

.new-feature .first-img {
  margin: 0px auto;
}

.new-feature .headings {
  color: #263238;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: justify;
  line-height: 34px;
  padding-right: 15px;
}

.new-feature .first-img {
  width: 180px;
}

.new-feature .title {
  color: #17646c;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  margin-top: 13px;
}

.new-feature .items {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  padding-right: 15px;
}

.new-feature .bg-gray {
  background-color: #cdd4d5 !important;
}

.new-feature .circle {
  cursor: pointer;
}

.new-feature .learn-more {
  color: #f15d3b;
  cursor: pointer;
}

.new-feature .circle-area {
  margin-top: 10px;
}

#new-feature {
  height: 480px;
  overflow: auto;
}

.scroll-box {
  overflow: auto;
  white-space: nowrap;
}

.pad0 {
  padding: 0 !important;
}

.filter-title {
  float: right;
  color: #757575;
  /* font-family: 'Poppins-Light'; */
  font-weight: 300;
  margin-top: 4px;
  font-size: 14px;
}

.card-container {
  width: 100%;
  background: linear-gradient(180deg, #f9faff 0%, #f1f2f9 100%);
  border-radius: 16px;
  border: 1px solid #e5e7f2;
  padding: 22px 0px;
  margin-top: 17px;
}

.card-container .title {
  color: #271a3e;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}

.card-container .itms {
  margin-top: 26px;
}

.card-container .itms img {
  height: 50px;
  width: 59.62px;
}

.card-container .itms p {
  font-family: "Montserrat", sans-serif;
  color: #271a3e;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 auto;
  padding-bottom: 25px;
}

.tab-container {
  margin-top: 19px;
}

.tab-container::after {
  top: 7px;
  left: 61px;
  height: calc(100% - 74px);
}

.tab-container .timeline-dot {
  background-color: #a4c0c5;
  height: 36px;
  width: 36px;
  text-align: center;
  line-height: 36px;
  /* font-family: 'Poppins-Medium'; */
  font-weight: 500;
  color: #fff;
  margin-left: 34px;
  margin-top: -1px;
}

.tab-container .child-card {
  border-radius: 16px;
  border: 1px solid rgba(211, 220, 229, 0.5);
  background: #f9faff;
  width: 94%;
  margin: 8px 0px 60px 66px;
}

.tab-container .title {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 30px 23px 5px;
  text-transform: capitalize;
  line-height: 28px;
}

.tab-container .desc {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 23px 10px 23px;
  line-height: 28px;
}

.tab-container .timeline-event {
  margin-bottom: 16px;
}

.tab-container .active .child-card {
  background-color: #d4f1f4;
}

.tab-container .active .timeline-dot {
  background-color: #33acc1;
}

.tab-container .bg-red-button {
  margin: 0px 21px 30px;
  font-size: 14px;
  border: none;
  /* font-family: 'Poppins-Semibold'; */
  font-weight: 600;
}

#dev-cta,
#job-cta {
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 20px 50px 15px;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: linear-gradient(90deg, #deedff, #d5e3f2);
}

#dev-cta .btn,
#job-cta .btn {
  background-color: #f15d3b;
}

#dev-cta h5,
#job-cta h5 {
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

#dev-cta h5 span,
#job-cta h5 span {
  color: #e74c3c;
}

.wizard-block.skill-test {
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .new-profile-wrap .icon-tags li {
    flex: 0 1 auto;
  }

  #dev-cta,
  #job-cta {
    display: block;
    text-align: center;
  }

  #dev-cta img,
  #job-cta img {
    margin-top: 20px;
  }

  .client-message {
    display: none;
  }

  .test-list li {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .test-list li .test-card .skill-detail {
    width: 100%;
  }

  .test-list li .test-card {
    width: 100%;
  }

  .home-banner__text .spl p {
    margin-left: 4.3rem;
  }
}

@media (max-width: 768px) {
  .tab-container::after {
    left: 15px;
    z-index: -1;
  }

  .tab-container .timeline-dot {
    position: absolute;
    display: block;
    left: -78px;
  }

  .tab-container .child-card {
    margin: 8px 0px 60px 0px;
    width: 100%;
  }

  /*.timeline {
  padding-left: 0px;
}*/
  .tab-block .tabs {
    flex-direction: column;
  }

  .table_scroll {
    overflow-x: auto;
  }

  .dash-block .card {
    overflow-x: auto;
    margin-bottom: 20px;
  }

  .table {
    overflow-x: auto;
  }

  .toggle-list .tabs .nav {
    overflow-x: auto;
  }

  #candidate .job-category {
    flex-direction: column;
  }

  .job-category .user-img {
    margin-bottom: 15px;
  }

  .job-category .user-details {
    padding: 0 0 20px;
  }

  .jobProfileFeedBack {
    flex-basis: auto;
    display: flex;
    justify-content: flex-start;
  }

  .company-profile-list {
    flex-direction: column;
  }

  .dash-block .card .card-header h4 {
    flex: 1 0 100%;
    width: 100%;
  }

  .dash-block .card .card-header {
    flex-wrap: wrap;
  }

  .job-rquirement .btn-block {
    flex-wrap: wrap;
  }

  .job-rquirement .btn-block button {
    width: 100%;
    margin: 0 0 10px;
  }

  .about-banner {
    min-height: auto;
  }

  .member-list ul li img {
    height: auto;
  }
}

.verified-no .new-label {
  top: -2px;
  margin-right: 10px;
}

.verified-no input[type="checkbox"] {
  width: unset;
  height: unset;
  visibility: unset;
}

.tab-container .bg-red-button {
  margin: 0px 21px 13px;
}

.selectable-area .rdt_TableCol input[type="checkbox"] {
  width: unset;
  height: unset;
  visibility: unset;
}

.selectable-area input[name="select-row-undefined"] {
  width: unset;
  height: unset;
  visibility: unset;
}

.selectable-area .rdt_Pagination {
  display: none !important;
}

.hide-select-all .rdt_TableHeadRow input[type="checkbox"] {
  visibility: hidden !important;
}

.new-feature .bg-red-button {
  /* font-family: 'Poppins-Semibold'; */
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.new-feature h2 {
  color: #17646c;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-align: center;
}

.active-tab {
  border-bottom: 2px solid #3ba0aa;
  color: #3ba0aa;
  cursor: pointer;
  border-radius: 3px;
}

.un-active-tab {
  border-bottom: 4px solid transparent;
}

.un-active-tab:hover {
  border-bottom: 4px solid #3ba0aa;
  color: #3ba0aa;
  cursor: pointer;
  border-radius: 3px;
}

/* client add job choose skills */
.choose-skill-lists {
  margin-bottom: 10px;
}

.choose-skill-lists input[type="checkbox"] {
  width: unset;
  height: unset;
  visibility: visible;
  margin-left: 18px;
}
.css-1033fig-multiValue .react-select-cus-chek,
.css-sqbtjo-multiValue .react-select-cus-chek,
.css-wkhfz-MultiValueGeneric .react-select-cus-chek {
  width: unset !important;
  height: 11px !important;
  vertical-align: middle;
  visibility: visible !important;
  margin-right: 3px;
}

.required-title-check {
  font-weight: bold;
  font-size: 18px;
  margin-right: 7px;
  color: #3ba0aa;
}

.custom-react-select {
  font-style: italic;
  margin-top: 14px;
  margin-left: 0px;
}

.custom-react-select svg {
  display: none;
}

.relocate-area {
  width: 98.5%;
}

.relocate-area li {
  display: inline-block;
  background-color: #ff6a48;
  padding: 3px 16px;
  color: #fff;
  border-radius: 16px;
  margin-top: 10px;
  margin-left: 10px;
}

.relocate-area span {
  font-size: 10px;
  padding-left: 6px;
  vertical-align: middle;
  cursor: pointer;
}

.reloacte-head {
  margin-top: 9px;
  font-size: 14px;
}

.award-recognitions {
  background-color: #f3fdff;
  width: 100%;
}

.award-recognitions .box-item {
  width: 23%;
  height: 277px;
  border-radius: 8px;
  background-color: white;
  margin-left: 21px;
  margin-bottom: 10px;
  border-bottom: 4px solid #d2e2f2;
}

.award-recognitions .box-item p {
  font-weight: 500;
}
.award-recognitions .box-item img {
  height: 212px;
}

.award-recognitions .right-sec {
  position: absolute;
  right: 9%;
  z-index: 1;
  margin-top: -80px;
}

.award-recognitions .title {
  color: #000;
  letter-spacing: 3.2px;
  margin-top: 11px;
}
.award-recognitions .right-sec p {
  margin-top: -24px;
  letter-spacing: unset;
  font-weight: 500;
  text-align: right;
  font-size: 12px;
}
.about-section .company-title {
  font-weight: 500;
}
.award-recognitions .box-container {
  justify-content: center;
  margin: 15px;
}
.seach-box-client input {
  padding: 0.55rem 0.75rem;
  font-style: italic;
}

.filter-input-client {
  height: 38px;
  border-color: grey !important;
}
.check-filter-client .label {
  margin-left: 144px;
  margin-top: -23px;
}
.check-filter-client .checked {
  background-color: #3ba0aa;
}

.check-filter-client .open-relocate {
  font-size: 14px;
}
.padding-unset {
  padding: unset;
}
.browseNoTopAdmin {
  top: unset;
  right: unset;
  margin-left: 214px;
  margin-top: 6px;
}
.mt-12 {
  margin-top: 12px;
}

.hide-budget .span-1 {
  position: absolute;
  margin-top: 5px;
  font-size: 9px;
  margin-left: -35px;
}
.hide-budget .label {
  width: 34px;
  height: 17px;
}
.hide-budget .span-2 {
  position: absolute;
  margin-top: 2px;
  font-size: 13px;
  margin-left: -185px;
}
.hide-budget .label::after {
  width: 13px;
  height: 13px;
}
@media (max-width: 768px) {
  .hide-budget .span-2 {
    margin-top: -22px;
  }
  .hide-budget .span-1 {
    margin-top: -19px;
  }
}
.required-skill-star {
  color: rgb(255, 64, 0);
  margin-top: -20px;
}

.filter-cross-icon {
  margin-right: 5px;
  margin-left: 6px;
  color: #aeaeae;
  font-size: 13px !important;
  vertical-align: middle !important;
}

.width-100 {
  width: 100% !important;
}

.dev-client-filter th {
  color: #526186 !important;
  font-weight: 500;
  text-align: center !important;
}
.dev-client-filter td {
  color: #242c33 !important;
  font-weight: 400;
  text-align: center !important;
}
.dev-client-filter .icon-tags {
  justify-content: center;
}
.dev-client-filter .width-19 {
  width: 18%;
}
.dev-client-filter .width-31 {
  width: 31%;
}
.dev-client-filter .text-primary {
  color: #3ba0aa !important;
}
.dev-client-filter .text-danger {
  color: #f15d3b !important;
}
.height-38 {
  height: 38px;
}

.search-link-filter img {
  position: absolute;
  width: 18px;
  right: 54px;
  margin-top: -33px;
}
.setting-verify {
  position: absolute;
  margin-left: 7px;
  color: #f70f0f;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 1px 3px;
}
.pointer {
  cursor: pointer;
}
.even-bg {
  background-color: #f6feff;
}
.mb-40 {
  margin-bottom: 40px;
}
.home-banner__text h1 strong {
  color: #196f78;
}
.number-verification input {
  margin-bottom: 12px;
}
.pl-0 {
  padding-left: 0px;
}
.vertical-bottom {
  vertical-align: bottom !important;
}
.browseImage.verified-number {
  right: unset;
  margin-top: -5px;
  margin-left: -137px;
  background-color: unset;
}

.currency-sign {
  position: absolute;
  margin: 6px 8px;
  font-size: 13.5px;
}
.budgetMin {
  padding-left: 19px !important;
}
.currency-sign-dev {
  position: absolute;
  margin: 29px 8px 0px;
  font-size: 13.5px;
  z-index: 2;
}
.limitation label {
  position: absolute;
  margin-top: 10px;
  margin-left: 1px;
}
.limitation > :first-child {
  margin-top: 30px;
  margin-left: 40px;
}

.hstrip {
  background-color: #242c33;
  text-align: center;
  padding: 10px 0px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 1px;
  width: 100%;
}
.hstrip a {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  border-bottom: 2px solid #0ee9ff;
  color: #0ee9ff;
  font-weight: 600;
  padding-left: 5px;
  font-style: italic;
  text-decoration: none;
}
.ml-30 {
  margin-left: 30px;
}

/* subscrption banner */
.subscription-banner {
  color: #4f495c;
  border: 1px solid #e9e4f4;
  border-radius: 5px;
  background-color: #f5f3f9;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 390px;
}
.subscription-banner small {
  margin: 0 10px 0 5px;
}
@media screen and (max-width: 733px) {
  /* .header-right {
    width: 100%;
    margin-top: 20px;
  } */
  .subscription-banner {
    min-width: 0;
    top: 50px;
    left: 0;
  }
}
@media screen and (max-width: 630px) {
  .header-right {
    flex: 0 0 100%;
    margin-top: 30px;
  }
  .header-right .dropdown,
  .header-right #notification {
    position: absolute;
    top: 20px;
  }
  .header-right #notification {
    right: 80px;
  }
  .subscription-banner {
    width: 100%;
  }
  #notification .notification-number {
    right: -5px;
    top: -5px;
  }
}
@media screen and (max-width: 420px) {
  .subscription-banner {
    flex-wrap: wrap;
    gap: 10px;
  }
  .subscription-banner button {
    flex: 0 0 100%;
  }
}
.test-candidate-card {
  margin-top: 20px;
}

.blur-6px {
  filter: blur(6px);
}

.restriction-btn {
  position: absolute;
  z-index: 1;
  margin-top: 167px;
  margin: 197px 41%;
}
.pr-discount {
  color: #242c33;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  margin-top: -30px;
  margin-bottom: -10px;
}
.pr-discount span {
  font-size: 28px;
  font-weight: 700;
  color: #227c85;
  vertical-align: middle;
}
.ml-0 {
  margin-left: 0px !important;
}
.custom-skill-query {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dae7e9;
  padding: 8px;
}
.custom-skill-circle {
  width: 100%;
}
.custom-skill-circle .circle {
  background-color: #fff;
  border: 1px solid #dae7e9;
  color: #000;
  height: 27px;
  width: 27px;
  padding-top: 7px;
  font-weight: bold;
}
.selected-query {
  border-radius: 8px;
  border: 1px solid #b5dee5;
  background: #e2eff2;
  margin-top: 10px;
  padding: 8px;
}
.job-detail-ul{
  height: auto!important;
  overflow: auto!important;
}
.icon-tags .symbols{
  font-size: 23px;
  /* color: #3BA0AA; */
  /* vertical-align: bottom; */
  line-height: 43px;

}
.icon-tags  .carli{
    color: #3BA0AA;
}
.job-detail-skill{
  width: 100%;
  background-color: #ADE1E5;
  border-radius: 10px;
}
.job-detail-skill .icon-tags{
  overflow: auto!important;
  height: auto!important;
}

.job-detail-skill .heading{
  margin-left: 10px;
  position: absolute;
  margin-top: 15px;
}
.job-detail-skill .icon-tags{
  margin-left: 9px;
}

.job-detail-skill .circle{
  position: absolute;
  margin-top: -14px;

}
.job-detail-skill+.custom-skill-circle .circle{
  position: absolute;
  margin-top: 8px;
}
.job-detail-skill hr{
  margin: 14px 0px;
  border-color: #A5DEE3;
}
.job-detail-skill+.custom-skill-circle hr{
  margin-top: 22px;
  border-color: #A5DEE3;
}
.client-jobs .icon-tags{
  max-height: 92px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .job-detail-skill .heading{
    margin-left: 10px;
    position: relative;
    margin-top: 15px;
  }
}

.email-input-file{
  position: absolute;
  right: 37px;
  margin-top: -24px;
  cursor: pointer;
}

.job-list-skill{
  width: 100%;
  background-color: #F7F9FD;
  border-radius: 10px;
  padding: 0px;
  border:0.3px solid #D9E6F5;
}
.job-list-skill .heading{
  margin-left: 10px;
  position: absolute;
  margin-top: 15px;
  font-weight: bold;
}
.job-list-skill .icon-tags{
  margin-left: 5px;
}

.job-list-skill hr{
  margin-top: -13px;
  height: 0.1px;
  background-color:  #CFDAE7;
  border: none;
}
.job-list-skill+.custom-skill-circle hr{
  margin-top: -13px;
  background-color:  #CFDAE7;
  height: 0.1px;
  border: none;
}
.job-list-skill .circle{
  height: 22px;
  padding-top: 4px;
}
.job-list-skill+.custom-skill-circle .circle{
  height: 22px;
  padding-top: 4px;
}

@media (max-width: 768px){
  .job-list-skill .icon-tags {
    margin-left: 144px;
}
}
.select-box-error {
  border:1px solid red;
}
.radio-btn-big{
  padding: 13px;
  border-radius: 11px;
  width: 100%;
  height: 50px;
  border: 1px solid #e1e1e1;

}
.radio-btn-error input{
  margin: 2px 8px;
}
.radio-btn-big.active {
  border: 1px solid blue;
}
.radio-btn-big input{
  margin-right: 8px;
}
.margin-top-10{
  margin-top: 10px;
}
.border-red{
  border: 1px solid red!important
}
.add-skills-table {
  width: 100%;
}
.add-skills-table .icon {
  width: 35px;
  display: inline-block;
  cursor: pointer;
}
.add-skills-table .minus-icon {
  top: 6px;
  margin-bottom: 15px;
}
.input-file {
  display: none;
}
.pending-skill .small-btn {
  padding: 7px 15px;
  font-size: 10px;
  /* margin-top: 10px; */
}