
.react-icon{    
    background: url('../../assets/img/icons/react-icon.svg') no-repeat left top; 
    background-size: 16px 16px;   
}
.angular-icon{
    background: url('../../assets/img/icons/angular-icon.svg') no-repeat left top;
    background-size: 16px 16px;
}
.c-icon{
    background: url('../../assets/img/icons/c-icon.svg') no-repeat left top;
    background-size: 16px 16px;
}
.django-icon{
    background: url('../../assets/img/icons/django-icon.svg') no-repeat left top;
    background-size: 16px 16px;
}
.selenium-icon{
    background: url('../../assets/img/icons/selenium-icon.svg') no-repeat left top;
    background-size: 16px 16px;
}
.slack-icon{
    background: url('../../assets/img/icons/slack-icon.svg') no-repeat left top;
    background-size: 16px 16px;
}
.invision-icon{
    background: url('../../assets/img/icons/invision-icon.svg') no-repeat left top;
    background-size: 16px 16px;
}
.social-share-buttons{
    display: flex;
    justify-content: space-around;
}

.height-unset{
    height: auto!important;
}

.remove-max-width{
    max-width: unset!important;
}
.profile-printable{
    visibility: hidden;
}
.user-location{
    margin-bottom: 0px;
}
.user-location img{
    vertical-align: middle;
}

.new-profile-details img{
    vertical-align: middle;
}
.skill-result-detail{
    margin: 17px 0px;
}
.skill-result-heading{
    margin: 17px 0px;
}