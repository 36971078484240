.calbtn{
    padding:10px 10px;
    background-color: #DCF0FF;
    border:1px solid #A9CFED;
    font-size:12px;
    line-height: 18px;
    text-transform: capitalize;
    padding:8px 15px;
    display: inline-block;
    color:#6677AF;
    cursor: pointer;
}
.calbtn-primary{
    background-color: #3BA0AA;
    color:#fff;
}
.interview-calendar{
    display:block;
    background-color: #F6F8FF;
    border:1px solid  #E4EBFD;
    padding:15px;
    margin: 0 15px;
}
.calendar-header{
    display:block;
}
.calendar-header .header-top{
    display:flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.calendar-header .btn-group{
    display:flex;
}
.calendar-header .header-top h2{
    font-size: 24px;
}
.calendar-header .header-bottom{
    display: flex;
    margin-bottom: 15px;
}
.calendar-header .header-bottom .header-left{
    flex:0 0 50%;
    max-width:50%;
    display: flex;
    align-items: center;
    padding-left: .5rem;
    margin: 1rem 0 .5rem;
}
.calendar-header .header-bottom .header-left div:first-of-type {
    color: #3BA0AA;
    margin: 0 2rem 0 -4px;
}
.calendar-header .header-bottom .header-left a{
    color:#5D6C9F;
    text-decoration: none;
    font-weight: 500;
    transition: all .5s ease-in-out;
}
.calendar-header .header-bottom .header-left a.current{
    color: #3BA0AA;
    transition: all .5s ease-in-out;
}
.calendar-header .header-bottom .header-left a + a{
    margin-left:15px;
}
.calendar-header .header-bottom .header-right{
    flex:0 0 50%;
    max-width:50%;
    display:flex;
    justify-content: flex-end;
}
.calendar-header .header-bottom .calendar-search{
    margin-left:15px;
    position: relative;
}
.calendar-header .header-bottom .form-control{
    padding:10px 40px 10px 10px;
    border:1px solid #D2E2F2;
}
.calendar-header .header-bottom .calendar-search i{
    position: absolute;
    right:0;
    top:0;
    height:100%;
    width:40px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.calendar{
    display:block;
    margin-bottom: 15px;
    overflow-x: scroll;
}
.calendar-tabs{
    display:block;
    margin-bottom: 15px;
    border-bottom: 2px solid #E1E9FB;
}
.calendar-tabs ul{
    display:flex;
    list-style: none;
    margin:0;
    gap: .8rem;
}
.calendar-tabs ul li{
    margin-right: 4px;
    padding: 0 1rem .3rem .5rem;
    font-size: 14px;
    font-weight: 500;
}
.calendar-tabs ul li a{
    display:block;
    padding:10px;
    color:#5D6C9F;
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 500;
    position: relative;
}
.calendar-tabs ul li a i{
    display: inline-block;
    margin-right:5px;
}
.calendar-tabs ul li.current a{
    color:#3BA0AA;
}
.calendar-tabs ul li a:after{
    display: block;
    position: absolute;
    content:"";
    bottom:0;
    left:0;
    width:0;
    height:2px;
    background-color: #3BA0AA;
    transition: all 1s ease-in-out;
}
.calendar-tabs ul li:hover a:after,
.calendar-tabs ul li.current a:after{
    width:100%;
    transition: all 1s ease-in-out;
}
.interview-cal{
    background-color: #fff;
    border-collapse: collapse;
}
/* .interview-cal tbody{
    
}
.interview-cal tbody tr{
    
} */
.interview-cal tbody tr td{
    border:1px solid #DCE2F5;
    padding: 15px;
    /* width:14.2857%; */
    /* max-width: 14.2857%; */
    width: 200px;
    max-width: 200px;
    vertical-align: top;
    text-align: left;
    height: 140px;
}
/* .interview-cal tbody tr td:last-child{
    border-right:none;
} */
.interview-cal .day-date{
    color:#298B8D;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.interview-cal .day-date .day{
    font-size:12px;
    line-height: 18px; 
}
.interview-cal .day-date .date{
    font-size:24px;
    line-height: 36px; 
    font-weight: 600;
}
/* .interview-cal .schedule{
    height: 2.5rem;
    display: flex;
    border:1px solid #DAE0F7;
    border-radius:44px;
    align-items: center;
    color:#242C33;
    margin-bottom: 10px;
    background-color: #fff;
} */
.interview-cal .schedule {
    display: inline-block;
    width: max-content;
}
.interview-cal .schedule:not(:first-of-type) {
    margin-left: -10px;
}
.interview-cal .schedule figure {
    width:36px;
    height:36px;
    border-radius: 50%;
    display:block;
    overflow: hidden;
    margin-left: -3px;
    background-color: white;
    /* border:2px solid #fff; */
    /* border: 1px solid rgba(195, 221, 248, 0.50); */
    box-shadow: 0px 1px 6px 3px rgba(228, 239, 251, 0.70);
    outline: 3px solid #3BA0AA;
}

.interview-cal .schedule figure.pending{
    outline: 3px solid orange;
}
.interview-cal .schedule figure img{
    object-fit: contain;
    max-width: 100%;
}
.interview-cal .schedule .agenda{
    padding:10px 5px 10px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display:inline-block;
    max-width: 100px;
    font-size: .75rem;
}
.interview-cal .schedule .scheduled-time{
    padding:10px 5px;
    display:inline-block;
}
.interview-cal .schedule.cancel{
    background-color: #F1D8D8;
    border-color:#FCB9B9;
    color:#693030;
}
.interview-cal .schedule.pending{
    background-color: #FFF9E6;
    border-color:#ECDEB0;
    color:#998F3A;
}

.active-tab {
    color: #3BA0AA;
    border-bottom: 2px solid #3BA0AA;
    margin-right: 15px;
    cursor: pointer;
    display: flex;
    gap: .4rem;
}

.inactive-tab {
    border-bottom: 2px solid transparent;
    margin-right: 15px;
    cursor: pointer;
    display: flex;
    gap: .4rem;
}

.dateTitle {
    height: 30px;
    /* background-color: #3BA0AA; */
    /* color: #fff; */
    background-color: #fff;
    color: #3BA0AA;
    font-size: .8rem;
    font-weight: 500;
    text-align: inherit;
    padding-left: 10px;
    padding-top: 1rem;
}

.more-link {
    color: #3BA0AA;
}
.more-link {
    background-color: #298B8D;
    color: white;
    outline: 3px solid white;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    display: inline-block;
    line-height: 39px;
    text-align: center;
    box-shadow: 0px 1px 6px 3px rgba(228, 239, 251, 0.70);
    margin-left: -10px;
}