.login-page{
    /* background: url('../../../assets/img/lo-si-bg.webp') no-repeat center center; */
    background: url('https://ik.imagekit.io/olibr/assets/tr:w-1600,h-1500/lo-si-bg.webp') no-repeat center center;
    background-size: cover;
    display:block;
    /* min-height:100vh; */
    min-width:100vw;
    color:#212b36;
    display:flex;
    flex-direction: column;
}
.login-header{
    display:block;
    padding:20px;
    flex:0 0 auto;
}
.login-header a{
    display:inline-block;
}
.login-header a img{
    max-width:200px;
}
/* .login-header h2{

} */
.login-page .login-block{
    display:flex;
    width:100%;
    flex:1;
    padding:40px;
}
.login-block .login-left{
    flex:0 0 50%;
    max-width:50%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.login-block .login-left h1{
    font-size:36px;
    /* font-family:'Poppins-Bold',sans-serif; */
    /* font-weight:normal; */
    font-weight: 700;
    line-height: 1;
}
.login-block .login-left h4{
    font-size:36px;
    /* font-family:'Poppins-Bold',sans-serif; */
    /* font-weight:normal; */
    font-weight: 700;
    line-height: 1;
}
.login-block .login-left h5{
    font-size:36px;
    /* font-family:'Poppins-Normal',sans-serif; */
    font-weight:normal;
    position: relative;
}
.login-block .login-left h5 span{
    background-color: #f7fcff;
    padding-left:10px;
    padding-right:10px;
    z-index: 1;
    display:inline-block;
    position: relative;
}
.login-block .login-left h5:before,
.login-block .login-left h5:after{
    content:'';
    display:block;
    height:3px;
    background-color:rgba(36, 44, 51, 0.4);
    position: absolute;
    left:50%; 
    transform: translateX(-50%);
    z-index: 0;
}
.login-block .login-left h5:before{
    top:calc(50% - 5px);
    
    width:120%;
}
.login-block .login-left h5:after{
    top:calc(50% + 5px);
    width:110%;
}
.login-block .login-right{
    flex:0 0 50%;
    max-width:50%;
    display: flex;
    justify-content: center;
}
.login-form{
    background: #fff;
    box-shadow: rgb(236 245 254) 0px 4px 14px 6px;
    border-radius: 34px;
    max-width: 400px;
    width: 100%;
    margin: 0px auto;
    height: fit-content;
    padding:40px 30px;
}
.login-form-header{
    display:flex;
    margin-bottom:30px;
}
.login-form-header figure{
    display:block;
    flex:0 0 50px;
}
.login-form-header figure img{
    width:50px;
}
.login-form-header .login-form-text{
    flex:1;
    padding:0 20px;
    font-size:14px;
}
.login-form-header .login-form-text p{
    margin:0;
    font-size:13px;
}
.login-form-header .login-form-text h3{
    /* font-family: 'Poppins-Bold', sans-serif; */
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 10px;
}
.forgot-pass{
    display:flex;
    justify-content: flex-end;
    font-size:13px;
}
.copyright{
    display:flex;
    justify-content: center;
    padding:20px;
    font-size:12px;
}


@media(max-width:768px){
    .login-page .login-block{
        flex-direction: column;
    }
    .login-block .login-left{
        flex:0 0 auto;
        max-width:100%;        
    }
    .login-block .login-left h4{
        font-size:26px;
    }
    .login-block .login-right{
        flex:0 0 auto;
        max-width:100%;
    }
    .login-form{
        border-radius: 10px;
        padding:25px;
    }
}

.signup{
    display:flex;
    flex-direction: column;
    font-size:14px;
    /* font-family: "Poppins-Regular"; */
    font-weight: normal;
    color:#242C33;
}
.signup-block{
    display:flex;
    margin-bottom: 25px;
}
.signup-block .signup-left{
    flex:0 0 50%;
    padding:15px;
    /* background: url('../../../assets/img/lo-si-left.webp') no-repeat center bottom; */
    background: url('https://ik.imagekit.io/olibr/assets/tr:w-712,h-295/lo-si-left.webp') no-repeat center bottom;
    background-size: 100%;
}
.signup-block .signup-left .text-logo{
    display:flex;
    margin: 0 auto 34px;
    justify-content: flex-start;
    max-width: 400px;
    padding:0 15px;
}
.signup-block .signup-left .text-logo img{
    height:57px;
}
.signup-block .signup-left .helptohire {
    position: relative;
    padding:24px 36px;
    margin:0 auto;
    display: block;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #F5F8FF;
    box-shadow: 1px 4px 10px 7px rgba(210, 226, 242, 0.30);
    border-radius: 8px;
    max-width: 400px;
}
.signup-block .signup-left .helptohire h4{
    font-size:20px;
    /* font-family: "Poppins-Medium"; */
    /* font-weight: normal; */
    font-weight: 500;
    text-transform: uppercase;
    margin:0 0 23px;
}
.signup-block .signup-left .helptohire ul{
    display:flex;
    flex-direction: column;
}
.signup-block .signup-left .helptohire ul li{
    font-size:14px;
    /* font-family: "Poppins-Regular"; */
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signup-block .signup-left .helptohire ul .circle-no{
    display:flex;
    justify-content: center;
    align-items: center;
    width:40px;
    height:40px;
    border-radius: 50%;
    margin-bottom: 12px;
    /* font-family: "Poppins-SemiBold"; */
    font-weight: 600;
    font-size: 20px;
}
.signup-block .signup-left .helptohire ul li p, .client-dash .toggle-block .help-to-hire p{
    text-transform: uppercase;
    /* font-family: "Poppins-Medium"; */
    font-weight: 500;
}
.justify-content-end{
    display: flex;
    justify-content: flex-end;
}
.signup-block .signup-right{
    flex:1;
    padding:38px;
    background-color: white;
}
.signup-block form{
    margin:0;
}
.signup-block .signup-right .brand-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size:14px;
    /* font-family: "Poppins-Regular"; */
    font-weight: normal;
    color:#242C33;
}
.signup-block .signup-right .brand-logo img{
    width:60px;
    margin-bottom: 12px;
}
.signup-block .signup-right .brand-logo h5{
    font-size:20px;
    /* font-family: "Poppins-Medium"; */
    /* font-weight: normal; */
    font-weight: 500;
    color:#041213;
}

.clients{
    display:block;
    margin-bottom: 40px;
    padding:0 15px;
}
.clients .brands{
    display:flex;
    flex-wrap: wrap;
}
.clients .brands li{
    flex:auto;
    padding:10px;
    /*display: flex;*/
    justify-content: center;
}
.clients .brands li img{
    max-height:30px;
}

@media(max-width:768px){
    .login-block .login-left h1{
        text-align: center;
        font-size:30px;
    }
    .login-block .login-left h5{
        font-size:30px;
    }
    .login-form-header .login-form-text .link{
        display: block;
    }
    .login-page .login-block{
        padding:20px;
    }
}