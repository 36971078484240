.collapse-button-opens{
    top: -6px;
}
.collapse-button-opens img{
    margin-top: 5px;
}
.result-box{
    background-color: #D4F1F4
}
.result-box h3{
    /* font-family: 'Poppins-SemiBold'; */
    font-weight: 600;
}
.open-skill-details{
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 18px;
}