.file-icon-btn {
    border: 1px solid #3BA0AA;
    border-radius: 2px;
    font-size: 14px;
    padding: 9px 40px;
    color: #3BA0AA;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    min-width: 250px;
    /* text-align: right; */
    /* margin-top: 11px; */
  }

  .bottom-button-result{
    text-align: right;
    margin-right: 25px;
    margin-top: 20px;
  }