.job-banner {
  width: 100%;
  display: block;
  min-height: 180px;
  background-color: #d8f1f3;
}

.job-banner h1 {
  color: #000;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  margin: 0;
  padding: 30px 0px 0px 180px;
  display: block;
}

.job-content-section {
  width: 100%;
  display: block;
  background: #f0f5f6;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
}

.job-content-section h3 {
  color: #3ba0aa;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.job-content-section p {
  font-size: 16px;
  color: #455059;
  max-width: 1000px;
  width: 100%;
  margin: 0px auto;
}

.job-title-section {
  width: 100%;
  display: block;
  background: #f0f5f6;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.job-title-section h3 {
  color: #041213;
  display: block;
  width: 100%;
  padding: 0;
}

.job-title-section p {
  font-size: 16px;
  color: #455059;
  width: 100%;
}

.open {
  text-align: center;
  box-sizing: border-box;
  display: block;
  color: #041213;
  margin: 0;
}

.joblist-wrap {
  max-width: 1000px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 20px;
  box-sizing: border-box;
}

.job-btn input[type="submit"] {
  width: 100%;
  margin: 20px 0px;
  transition: all 0.3s ease-in-out;
}

.job-btn input[type="submit"]:hover {
  background: #1f6870;
  transition: all 0.3s ease-in-out;
}

.job-listing-section {
  width: 100%;
  padding: 30px 20px 10px 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
  border-radius: 4px;
  border: 1px solid #e3eaec;
  transition: all 0.3s ease-in-out;
  background-color: white;
  display: inline-block;
}

.job-listing-section:hover {
  -webkit-box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 1);
  -moz-box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 1);
  box-shadow: 0px 11px 19px -6px rgba(183, 190, 201, 1);
  transition: all 0.3s ease-in-out;
}

.view-arrow {
  font-size: 14px;
  vertical-align: middle;
}

/* .job-listing-section:hover {
  cursor: pointer;
  background-color: #f0f5f6;
  -webkit-box-shadow: 3px 6px 20px -6px rgba(226, 236, 238, 1);
  -moz-box-shadow: 3px 6px 20px -6px rgba(226, 236, 238, 1);
  box-shadow: 3px 6px 20px -6px rgba(226, 236, 238, 1);
  transition: all 0.3s ease-in-out;
} */

.job-listing-section h4 {
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  color: #000;
}

.job-listing-section p {
  /* font-family: "Poppins-Light"; */
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  height: 75px;
  overflow: hidden;
  margin-bottom: 5px;
}

.view {
  color: #3ba0aa;
  text-transform: uppercase;
  letter-spacing: 1px;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
}

.view:hover {
  color: #1f6870;
  transition: all 0.3s ease-in-out;
}

.total-job {
  color: #000;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  font-size: 16px;
}

.skill {
  display: block;
  list-style: none;
  margin-bottom: 10px;
}

.skill li {
  display: inline-block;
  padding: 2px 20px;
  border-radius: 32px;
  margin: 10px 10px 0px 0px;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  font-size: 14px;
  color: #242c33;
  background-color: #edf5fd;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid #e0eaf5;
}

.job-det {
  display: block;
  /* justify-content: space-around; */
  justify-content: space-between;
}

.job-det p,
.jd-content p {
  /* font-family: "Poppins-Regular"; */
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin: 10px 0px;
  color: #3F3F3F;
  height: auto;
  text-transform: capitalize;
}

.jd-content p {
  color: #000;
}

.job-det p span,
.jd-content p span {
  vertical-align: top;
  line-height: 18px;
  color: #3BA0AA;
  font-size: 12px;
  margin-right: 5px;

}

.job-det p span img{
  display: inline-block;
  padding-top: 3px;
}
.job-det p span svg{
  display: inline-block;
  padding-top: 3px;
}

@media (max-width: 768px) {

  /* .job-banner {
    background: #3ba0aa;
    min-height: 100px;
  } */
  .job-banner h1 {
    padding-top: 20px;
  }
}

.job-detail-section {
  width: 100%;
  padding: 20px 25px;
  box-sizing: border-box;
  margin-bottom: 15px;
  border-radius: 16px;
  border: 1px solid #f5f8ff;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 3px 6px 20px -6px rgba(226, 236, 238, 1);
  -moz-box-shadow: 3px 6px 20px -6px rgba(226, 236, 238, 1);
  box-shadow: 3px 6px 20px -6px rgba(226, 236, 238, 1);
  background: #f8faff;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.job-detail-section h4 {
  display: block;
  width: 100%;
  margin: 0px 0px 5px 0px;
  padding: 0;
  text-transform: capitalize;
  /* font-family: "Poppins-Medium"; */
  font-weight: 500;
  color: #041213;
}

.job-detail-section p {
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.job-detail-section ul {
  display: block;
  margin-left: 15px;
  list-style-type: disc;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  margin-bottom: 20px;
}

.job-detail-section li {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  padding-bottom: 10px;
}

.job-detail-section p ul {
  display: block;
  padding-left: 20px;
}

.job-detail-section p ul li {
  margin-bottom: 10px;
  list-style-type: disc;
}

ul.detail-skill {
  display: block;
  list-style: none;
  padding: 0 !important;
}

.detail-skill li {
  display: inline-block;
  padding: 4px 16px;
  border-radius: 32px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  margin: 0px 10px 5px 0px;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
}

.detail-skill li:hover {
  background-color: #3ba0aa;
  border: 1px solid #3ba0aa;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.description-block-job h4,
.job-desc-block h2,
.job-postedby-block h4 {
  /* font-family: "Poppins-Medium"; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
}

.description-block-job p,
.job-postedby-block p {
  /* font-family: "Poppins-Regular"; */
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  text-align: justify;
}

.job-desc-block ul {
  list-style: none;
  margin-bottom: 20px;
}

.job-desc-block ul li {
  font-size: 13px;
  padding-left: 20px;
  line-height: 28px;
}

.job-desc-block p {
  font-size: 14px;
}

.job-desc-block ul li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.job-postedby-block {
  background-color: white;
  padding: 20px;
  margin-top: 15px;
}

.job-postedby-block p {
  color: #5b5b5b;
  font-weight: 300;
}

.job-postedby-block .sub {
  font-weight: 400;
  font-size: 12px;
  color: #777575;
}

.job-postedby-block .non-sub {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.job-postedby-block .non-sub.link-web {
  line-break: anywhere;
  color: #3ba0aa;
}

.job-listing-section .skill {
  margin-left: -15px;
}

.job-listing-section .skill li {
  font-size: 12px;
}

.job-listing-section .icon-tags {
  margin: 0px -23px 3px;
}