.link-button-verify{
    color: #3ba0aa;
}

.arrow-right-class {
    background: url("../../../assets/img/icons/arrow-right-long-solid.svg")
      no-repeat center center;
    width: 40px;
    height: 20px;
}

.to-login{
    text-align: center;
}