
.test-details h4 {
  color: #242c33;
  /* font-family: "Poppins-SemiBold", sans-serif; */
  font-weight: 600;
  margin-bottom: 20px;
}
.order-list {
  margin-left: 20px;
  margin-bottom: 20px;
}
.order-list li {
  margin-bottom: 15px;
}
abbr {
  border: none;
}
.test-details .link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
}
.arrow-right {
  background: url("../../../../assets/img/icons/arrow-right-long-solid.svg")
    no-repeat center center;
  width: 40px;
  height: 20px;
}
.arrow-left {
  background: url("../../../../assets/img/icons/arrow-left-long-solid.svg")
    no-repeat center center;
  width: 40px;
  height: 20px;
  transform: rotate(180deg);
}
.not-attempt {
  color:red
}