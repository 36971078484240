.skill-stack-banner{
  
    /* background: url("../../assets/img/skill-stack-exp.png"); */
    background: url('https://ik.imagekit.io/olibr/assets/tr:w-1300,h-180/skill-stack-exp.png');
    background-repeat: no-repeat;
    text-align: center;
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 190px;
}


.skill-stack-banner h1{
    color: #fff;
    display: flex;
    /* font-family: Poppins-Medium; */
    font-weight: 500;
    margin: 0;
    padding: unset;
    align-items: center;
}