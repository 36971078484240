/* Header css */
.header {
  background-color: #faf9fc;
  display: flex;
  flex: 0 0 calc(100% - 250px);
  justify-content: space-between;
  padding: 20px 15px;
  position: fixed;
  backdrop-filter: blur(6px);
  left: 250px;
  width: calc(100% - 250px);
  z-index: 9;
  top: 0;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  height: max-content;
}

.header:after {
  position: absolute;
  display: block;
  content: "";
  height: 2px;
  width: calc(100% - 40px);
  background-color: #f1f2f8;
  left: 20px;
  bottom: 0px;
}

.header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-right a {
  border: 1px solid rgb(204, 204, 204);
  box-shadow: rgb(211 220 229 / 50%) 0px 4px 5px;
  background-color: #f6f8fa;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

.header-right a img {
  max-width: 16px;
}

.header-right a:hover {
  background-color: rgba(145, 158, 171, 0.08);
  transition: all 0.5s ease-in-out;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

ul.popupList li {
  cursor: pointer;
  padding: 10px 15px;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
}

ul.popupList li:hover {
  background: #d7ecee;
  transition: all 0.5s ease-in-out;
  border-radius: 8px;
}

ul.popupList li img {
  vertical-align: middle;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-active .dropdown-content {
  display: block;
}

.dropdown-active .dropbtn {
  background-color: #3e8e41;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  right: 0;
  font-size: 14px;
}

.header-banner-h4 {
  /* display:block;
    text-align: center;
    font-size: 30px;
    line-height: 46px;
    max-width: 76%;
    margin: 0 auto; */
    font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-weight: 700;
  padding-left: 25px;
}

.header-banner-span {
  color: #18757e;
}

/* Sidebar css */
.sidebar {
  background-color: rgb(246, 247, 252);
  margin-left: 0;
  position: fixed;
  width: 250px;
  z-index: 1030;
  display: flex;
  flex: 0 0 300px;
  flex-direction: column;
  padding: 0;
  color: rgb(66, 75, 84);
  box-shadow: none;
  transition: all 0.5s ease-in-out;
  height: 100vh;
  overflow: auto;
}

.sidebar-collapsed {
  display: none;
}

.sidebar-brand img {
  width: 75%;
}

.sidebar-brand {
  background-color: rgb(241, 242, 248);
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 20px;
  border-bottom: 1px solid rgba(145, 158, 171, 0.24);
}

.user-profile {
  display: flex;
  align-items: flex-start;
  padding: 20px 15px 20px 30px;
}

.user-profile .profile-img {
  flex: 0 0 42px;
  max-width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
}

.user-profile .profile-img img {
  width: 100%;
  object-fit: cover;
  display: block;
}

.user-profile .profile-details {
  padding-left: 10px;
  font-size: 12px;
}

.user-profile .profile-details h4 {
  color: #242c33;
  display: block;
  /* font-family: "Poppins-SemiBold", sans-serif; */
  font-weight: 600;
  font-size: 12px;
  margin: 0;
}

.user-profile .profile-details p {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  overflow: hidden;
}

.user-profile .profile-details .avaiability {
  font-size: 12px;
  color: #3ba0aa;
}

.navbar {
  display: block;
  padding: 0 20px 0 20px;
}

/* .sidebar-nav{

}
.sidebar-nav li{

} */
.sidebar-nav li a {
  border-radius: 5px;
  color: #636984;
  display: flex;
  align-items: center;
  /* font-family: "Poppins-Medium", sans-serif; */
  font-weight: 500;
  padding: 10px;
  transition: all 0.5s ease-in-out;
}

.sidebar-nav li a:hover,
.sidebar-nav li a:focus,
.sidebar-nav li a.active {
  background-color: #fff;
  color: #3ba0aa;
  transition: all 0.5s ease-in-out;
}
.sidebar-nav li a.active svg{
  stroke: #3ba0aa;
}
.sub-sidebar .active svg{
  stroke: #3ba0aa;
}

.sidebar-nav li a img,
.sidebar-nav li a svg {
  height: auto;
  width: 24px;
  margin-right: 10px;
}


.hamburger-internal {
  display: none;
  flex-direction: column;
  height: 17px;
  justify-content: space-between;
  left: 10px;
  position: absolute;
  top: 26px;
  transition: all 0.3s ease-in-out;
  width: 21px;
  z-index: 1060;
}

.hamburger-internal .line {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background: #16918b;
}

.hamburger-internal .line.line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburger-internal .line.line2 {
  transition: transform 0.2s ease-in-out;
}

.hamburger-internal .line.line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.hamburger-internal.has-open .line.line1 {
  transform: rotate(45deg);
}

.hamburger-internal.has-open .line.line3 {
  transform: rotate(-45deg);
}

.hamburger-internal.has-open .line.line2 {
  display: none;
}

.collapse-button {
  position: fixed;
  left: 238px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #3ba0aa;
  cursor: pointer;
  transition: 150ms ease-in;
}

.collapse-button i{
  color: white;
  font-size: 12px;
  position: inherit;
  margin: 4px;
}
.collapse-button-open i {
  color: white;
  font-size: 12px;
  position: inherit;
  margin: 4px 5px;
}

.collapse-button-open {
  position: fixed;
  margin-top: 19px;
  left: 61px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #3ba0aa;
  cursor: pointer;
  z-index: 9999;
  transition: 150ms ease-in;
}

.collapse-button-open:hover,
.collapse-button:hover {
  background-color: #a9a9a9;
}

.sidebar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.sidebar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

@media (max-width: 1200px) {
  .sidebar {
    position: fixed;
    left: -100%;
  }

  .collapse-button,
  .collapse-button-open {
    display: none;
  }

  .hamburger-internal {
    display: flex;
  }

  .hamburger-internal.has-open {
    left: 210px;
  }

  .hamburger-internal.has-open+.sidebar {
    left: 0;
  }
}

/* Sidebar css */
.circle {
  height: 18px;
  width: 18px;
  line-height: 11px;
  font-size: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  padding-top: 4px;
  text-align: center;
  background-color: #3ba0aa;
  color: white;
  margin-left: 7px;
}

.sub-sidebar {
  width: 74px;
}

.sub-sidebar .img-box {
  background-color: #F1F2F8;
  width: 72px;
  height: 59px;
}

.sub-sidebar .img-box img {
  width: 36px;
  height: 35px;
  margin: 10px 19px;
}

.sub-sidebar .white-box {
  width: 65px;
  /* border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 14px 5px rgba(188, 195, 227, 0.31); */
  margin: -7px 6px;
}

.sub-sidebar .white-box svg,.sub-sidebar .white-box img{
  display: block;
    margin: 18px 18px
}
.sub-sidebar .user-profile{
  padding: 12px 15px 20px 16px;
}

.jobPost-noti {
  background-color: #FEFBEF;
  border-left: 2px solid;
  border-left-color: #FE8164;
}

.p0 {
  padding: 0px !important;
}

.seen-noti {
  background-color: #ffffff;
}

.shortlisted-noti {
  background-color: #DCF7FF;
  border-left: 2px solid;
  border-left-color: #7FD6EF;
}

.header-collapsed {
  /* position: static;
  width: 100%; */
  flex: 0 0 calc(100% - 73px);
}

@media (max-width: 1200px) {
  .header {
    width: 100%;
    left: 0;
    flex-basis: 100%;
  }
  .hamburger-internal{
    position: fixed;
    left: 10px;
  }
}
