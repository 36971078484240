
.job-card p {
  line-height: 20px;
  overflow: hidden;
}
.arrow-right {
  background: url("../../../../assets/img/icons/arrow-left-long-solid.svg") no-repeat center center;
  width: 40px;
  height: 20px;
}

.arrow-left {
  background: url("../../../../assets/img/icons/arrow-left-long-solid.svg") no-repeat center center;
  width: 40px;
  height: 20px;
  transform: rotate(180deg);
}
